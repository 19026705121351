<template>
  <div>
    <b-row style="height: 20px; width: 100%">
      <b-button class="ml-19 mt-5" style="background-color: #D51160; border: none; width: 5%" @click="indietro"><b-icon icon="arrow-left" font-scale="1.5"></b-icon></b-button>
    </b-row>
    <b-card class="ml-15 mx-15 mt-10">
      <b-card-header>
        <label class="titoloM2">Integratore<br/></label>
      </b-card-header>
      <div class="mt-5 ml-5 lh">
        <div class="ml-10 mt-10">
          <b-form-group
              id="labelNome"
              label-cols-sm="4"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="7"
              label="Nome Integratore: "
              label-for="nome"
          >
            <b-form-input v-if="integratore.integr" v-model="integratore.integr.name" :state="validateName" id="nome" type="text" style="margin-right: 5px; width: 70%"></b-form-input>
            <b-form-invalid-feedback :state="validateName">
              Il nome deve essere lungo almeno 2 caratteri.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
              id="labelNome"
              label-cols-sm="4"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="7"
              label="Descrizione: "
              label-for="descrizione"
          >
            <b-form-textarea v-model="integratore.integr.description" :state="validateDescrizione" id="descrizione" rows="6" style="margin-right: 5px; width: 70%"></b-form-textarea>
            <b-form-invalid-feedback :state="validateDescrizione">
              La descrizione può contenere al massimo 500 caratteri, ne sono stati inseriti {{integratore.integr.description.length}}.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
              v-if="integratore.associations.length > 0"
              id="labelNome"
              label-cols-sm="4"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="7"
              label="Paziente: "
              label-for="paziente"
          >
            <b-form-input disabled v-model="paziente" rows="6" style="margin-right: 5px; width: 70%"></b-form-input>
          </b-form-group>
          <b-row v-if="integratore.associations.length > 0 && integratore.associations[0].start_date">
            <b-col xs="6" sm="6" md="6" lg="6">
              <b-form-group
                  label-cols-sm="3"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Data Inizio: "
              >
                <b-form-input disabled v-model="integratore.associations[0].start_date" type="date" style="margin-right: 5px"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xs="6" sm="6" md="6" lg="6">
              <b-form-group
                  label-cols-sm="3"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Data Fine: "
                  label-for="end_date"
              >
                <b-form-input disabled v-model="integratore.associations[0].end_date" id="end_date" type="date" style="margin-right: 5px"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
              id="labelNome"
              label-cols-sm="4"
              label-cols-lg="3"
              content-cols-sm
              content-cols-lg="7"
              label="Condizione clinica macroscopica: "
              label-for="nome"
          >
            <b-form-input v-model="integratore.pat.name" disabled id="nome" type="text" style="margin-right: 5px; width: 70%"></b-form-input>
          </b-form-group>
          <h6 class="mt-7">Condizioni:</h6>
          <b-row>
            <b-col v-for="condizione in integratore.cond" xs="6" sm="6" md="6" lg="6" style="margin-top: 1%">
              <b-form-group
                  id="labelNome"
                  label-cols-sm="3"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="8"
                  :label="condizione.name"
                  label-for="nome"
                  label-align="center"
              >
                <b-form-input v-model="condizione.description" disabled id="nome" type="text" style="margin-right: 5px;"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <label style="font-weight: bold">{{integratore.condition_advice[0].measure_type}}: {{integratore.condition_advice[0].quantity}}</label>
          <label style="font-weight: bold; float: right; margin-right: 4.5%">{{integratore.condition_advice[1].measure_type}}: {{integratore.condition_advice[1].quantity}}</label>
          <b-table
              :items="integratore.ingredienti"
              :fields="fields"
              ref="tabel"
              responsive="sm"
              striped
              style="margin-right: 4%; margin-top: 1%"
          >
            <template #cell(dosaggio)=" integratore">
              <b-form-input v-model="integratore.item.dosaggio" type="number" style="width: 20%;" step="1" min="0" @blur="deselected(integratore.item)" @update="updateDosaggio(integratore.item)"></b-form-input>
            </template>
          </b-table>
        </div>
        <b-row style="justify-content: end">
          <label v-if="dosaggioTotale>100" style="font-weight: bold; float: right; margin-right: 10%; color: red">Dosaggio Totale: {{dosaggioTotale}}/100</label>
          <label v-else style="font-weight: bold; float: right; margin-right: 4.5%">Dosaggio Totale: {{dosaggioTotale}}/100</label>
        </b-row>
        <b-row style="justify-content: end; margin-top: 20px; margin-right: 3%">
          <b-col cols="2">
            <b-button style="float: right; margin-right: 2%" block @click="onModifica">Modifica</b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import Integrators from "@/services/Integrators";
import Patients from "@/services/Patients";

export default {
  data() {
    return{
      integratore: null,
      paziente:null,
      dosaggioTotale:100,
      maxDosaggio: 100,
      fields: [
        {
          label: 'Nome',
          key: 'nomeIntegratore',
          thStyle: "width:10rem",
        },
        {
          label: 'Dosaggio (%)',
          key: 'dosaggio',
          thStyle: "width: 10rem",
        }
      ]
    }
  },
  computed: {
    validateName(){
      return this.integratore === null ? null : this.integratore.integr.name.length >= 2
    },
    validateDescrizione(){
      return this.integratore.integr.description === "" ? null : this.integratore.integr.description.length <= 500
    }
  },
  mounted: async function () {
    await this.getIntegratore()
  },
  methods: {
    async getIntegratore() {
      const response = await Integrators.getIntegrator(this.$router.currentRoute.params.id)
      if(response.status === 200){
        this.integratore = response.data
        if(this.integratore.associations.length > 0) {
          const response2 = await Patients.getPatientsList(response.data.associations.id_paziente)
          if (response2.status === 200) {
            this.paziente = response2.data[0].nome_paziente + " " + response2.data[0].cognome_paziente + " - " + response2.data[0].data_nascita + " - " + response2.data[0].codice_fiscale

          } else {
            this.$bvToast.toast("Errore nei dati dei pazienti, contattare l'assistenza", {
              title: `Attenzione`,
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000,
              variant: 'danger',
              solid: true,
              noCloseButton: true
            })
          }
        }

      } else {
        this.$bvToast.toast(response.data, {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    async indietro(){
      await this.$router.push('/integratori')
    },
    deselected(item){
      if((this.dosaggioTotale > 100 || item.dosaggio>100) && item.dosaggio !== undefined){
        this.$bvModal.msgBoxOk("Il dosaggio inserito del "+item.quantita+"% è troppo alto ed è stato sostituito con il " + this.maxDosaggio+"%", {
          title: "Errore",
          size: "md",
          okVariant: "outline-danger",
          headerClass: "vv-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
          okTitle: "Chiudi",
        })
        item.dosaggio = this.maxDosaggio
        this.dosaggioTotale = 100
      }
    },
    updateDosaggio(item){
      this.maxDosaggio = 100
      this.dosaggioTotale = 0
      for (let i=0; i<this.integratore.ingredienti.length; i++){
        if(this.integratore.ingredienti[i].dosaggio !== undefined)
          this.dosaggioTotale += Number(this.integratore.ingredienti[i].dosaggio)
        if(this.integratore.ingredienti[i].id !== item.id && this.integratore.ingredienti[i].dosaggio !== undefined){
          this.maxDosaggio -= Number(this.integratore.ingredienti[i].dosaggio)
        }
      }
    },
    async onModifica(){
      if(this.validateName){
        const body = {
          name:this.integratore.integr.name,
          description:this.integratore.integr.description,
          ingredients:this.integratore.ingredienti
        }
        let tot = 0;
        for(let i=0; i<body.ingredients.length; i++){
          tot += Number(body.ingredients[i].dosaggio)
        }
        if(tot !== 100){
          this.$bvToast.toast('Il dosaggio totale deve essere esattamente 100', {
            title: `Attenzione`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            noCloseButton: true
          })
          return
        }
        const response = await Integrators.updateIntegrator(body, this.$router.currentRoute.params.id)
        if(response.status === 200){
          this.$bvToast.toast(response.data, {
            title: `Successo`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'success',
            solid: true,
            noCloseButton: true
          })
          await this.$router.push('/integratori')
        } else {
          this.$bvToast.toast(response.data, {
            title: `Attenzione`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            noCloseButton: true
          })
        }
      } else {
        this.$bvToast.toast("Errore nel nome", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
