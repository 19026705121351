<template>
  <div style="max-height: 100%; padding: 20px 50px; overflow: hidden; display: grid; grid-template-rows: auto 1fr;">
    <b-row style="width: 100%; margin: 0 0 15px 0; justify-content: space-between">
      <b-button style="background-color: #D51160; border: none; width: 5%" @click="indietro"><b-icon icon="arrow-left" font-scale="1.5"></b-icon></b-button>
      <b-button style="background-color: #D51160; border: none; width: 15%; " @click="goToPatient"><b-icon icon="person" variant="outline-primary"/>
        Visualizza dettaglio paziente
      </b-button>
    </b-row>
    <div style="display: grid; grid-template-columns: 1fr 3fr; column-gap: 10px; overflow: hidden; height: 100%;">
      <div style="display: grid; grid-template-rows: auto 1fr; row-gap: 10px; overflow: hidden; height: 100%;">
        <b-card>
          <label style="margin-bottom: 15px" class="titoliPagina"> Paziente </label>
          <b-row>
            <b-col cols="3">
              <img src="../assets/profilo.png" alt="User Photo" width="100%"/>
            </b-col>
            <b-col cols="9" style="padding-left: 20px; display: flex; flex-direction: column; justify-content: center;">
              <a style="font-size: 16px; font-weight: bold; color: #333;">{{ paziente.nome_paziente }} {{ paziente.cognome_paziente }}</a>
              <a style="font-size: 16px; color: #666;">{{ paziente.gender }} - {{ paziente.eta }} anni</a>
              <a style="font-size: 16px; color: #666;">Etnia {{ paziente.etnia }}</a>
            </b-col>
          </b-row>
        </b-card>
        <!--        Parametri fuori soglia-->
        <b-card style="height: 100%; overflow: hidden;">
          <div style="display: grid; grid-template-rows: auto 1fr; height: 100%;">
            <label class="titoliPagina"> Parametri fuori soglia </label>
            <!--            <a v-if="paziente.parametri.Emocromocitometrico && paziente.parametri.QuadroProteico && formatDate(last.QuadroProteico.create_date) === formatDate(last.Emocromocitometrico.create_date)" style="font-size: 13px"> (misurazione del {{ formatDate(last.Emocromocitometrico.create_date)}})</a>-->
            <div v-if="isBusy && (parametriEmocromoOut.length>0 || parametriQuadroOut.length >0)" style="overflow: scroll !important; overflow-x: hidden; height: 100%">
              <table style="height: 100%; margin: 0 10px;">
                <tr v-if="parametriEmocromoOut.length>0" class="titoliPaginaPiccoli">Dati Ematici (misurazione del {{ formatDate(last.Emocromocitometrico.create_date)}})</tr>
                <tr v-for="parametro in parametriEmocromoOut" style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; margin-top: 8px; margin-bottom: 8px">
                  <th>{{parametro.nome_visualizzato}}</th>
                  <td style="display: flex; justify-content: center">{{parametro.value}}</td>
                  <td style="display: flex; justify-content: center">{{parametro.unita_misura}}</td>
                  <td style="text-align: right">{{'('+ parametro.minimo +' - '+ parametro.massimo +')'}}</td>
                </tr>
                <tr v-if="parametriQuadroOut.length>0" class="titoliPaginaPiccoli">Quadro Proteico (misurazione del {{ formatDate(last.QuadroProteico.create_date)}})</tr>
                <tr v-for="parametro in parametriQuadroOut" style="display: grid; grid-template-columns: 1fr 1fr 1fr; margin-top: 8px">
                  <th>{{parametro.nome_visualizzato}}</th>
                  <td>{{parametro.value}} {{parametro.unita_misura}}</td>
                  <td style="text-align: right">{{'('+ parametro.minimo +' - '+ parametro.massimo +')'}} {{parametro.unita_misura}}</td>
                </tr>
              </table>
            </div>
            <div v-else>
              Non ci sono parametri anormali
            </div>
          </div>
        </b-card>
      </div>
      <div style="display: grid; grid-template-rows: 1fr auto; row-gap: 10px; height: 100%; overflow: hidden;">
        <!--        Sindrome Metabolica-->
        <b-card style="overflow-y: scroll; overflow-x: hidden; height: 100%;">
          <div style="height: 100%; display: grid; grid-template-columns: 1fr auto; column-gap: 20px;">
            <LineChartGenerator
                v-if="isBusy && last.SindromeMetabolica"
                :chart-options="chartOptions"
                :chart-data="chartData"
                :chart-id="chartId"
                :dataset-id-key="datasetIdKey"
                :plugins="plugins"
                :css-classes="cssClasses"
                :styles="styles"
                style="height: 95%; align-self: center;"
            />
            <div style="padding: 20px 0; display: flex; justify-content: space-around; align-items: center" v-else>Non sono ancora stati inseriti parametri relativi alla sindrome metabolica</div>
            <div style="padding: 20px 0; display: flex; flex-direction: column; justify-content: flex-start">
              <label class="titoliPagina">Sindrome Metabolica</label> <a style="font-size: 13px" v-if="paziente.parametri.SindromeMetabolica.length>0"> (misurazione del {{ formatDate(last.SindromeMetabolica.create_date)}})</a>
              <div style="margin-top: 10px; display: flex; flex-direction: column; justify-content: space-between" v-if="isBusy && last.SindromeMetabolica">
                <b>Circonferenza Vita: </b> {{last.SindromeMetabolica.circonferenza_vita.value ? last.SindromeMetabolica.circonferenza_vita.value : last.SindromeMetabolica.circonferenza_vita }}
                <b>Glicemia: </b> {{last.SindromeMetabolica.glicemia.value ? last.SindromeMetabolica.glicemia.value : last.SindromeMetabolica.glicemia }}
                <b>Trigliceridi: </b> {{last.SindromeMetabolica.trigliceridi.value ? last.SindromeMetabolica.trigliceridi.value : last.SindromeMetabolica.trigliceridi }}
                <b>Colesterolo HDL: </b> {{last.SindromeMetabolica.colesterolo_hdl.value ? last.SindromeMetabolica.colesterolo_hdl.value : last.SindromeMetabolica.colesterolo_hdl }}
                <b>Pressione Sistolica: </b> {{last.SindromeMetabolica.pressione_sistolica.value ? last.SindromeMetabolica.pressione_sistolica.value : last.SindromeMetabolica.pressione_sistolica}}
                <b>Pressione Diastolica: </b> {{last.SindromeMetabolica.pressione_diastolica.value ? last.SindromeMetabolica.pressione_diastolica.value : last.SindromeMetabolica.pressione_diastolica.value}}
                <b>Stato sindrome: </b> {{last.SindromeMetabolica.statoSindrome}}
              </div>
            </div>
          </div>
        </b-card>
        <div style="overflow: hidden; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-template-rows: 1fr 1fr; row-gap: 10px; column-gap: 10px">
          <!--        BMI -->
          <b-card style=" grid-row: span 2">
            <div style="height: 100%; display: grid; grid-template-rows: auto 1fr">
              <label class="titoliPagina"> BMI </label>
              <a style="font-size: 13px" v-if="paziente.parametri.ParametriCorporei && paziente.parametri.ParametriCorporei.length >0"> (misurazione del {{ formatDate(last.ParametriCorporei.create_date)}})</a>
              <div style="height:100%; display: flex; justify-content: center; align-items: center; margin-bottom: -15px;">
                <vue-gauge :refid="'type-unique-id'"
                           v-if="this.isBusy && this.bmi !== 1"
                           :options="{
                     'needleValue':calcolaBMI(),
                     'arcDelimiters':[20,40,60,80],
                     'arcColors':['#54B1E1','#B3D686','#FED429','#FBAF42','#F25356'],
                     'rangeLabel':['',''],
                     'arcLabels':['18,5','25','30','35'],
                     'centralLabel': this.bmi.toString(),
                     'labelsFont': 'Helvetica',
                     'chartWidth':'300'
                     }"
                           style="margin-bottom: -40px; margin-top: 20px "
                >
                </vue-gauge>
                <div v-else style="height:100%; padding-top: 10px; display: flex; flex-direction: column; justify-content: space-around; align-items: center">
                  Non sono ancora stati inseriti valori
                </div>
              </div>
            </div>
          </b-card>
          <b-card>
            <b-row style="height: 100%; padding: 0 20px; justify-content: space-between; align-items: center">
              <label class="titoliPiccoliTab" style="font-size: 16px; margin-bottom: 0"> Possibilità di malassorbimento </label><a>{{ paziente.parametri.AspettiFisiologici && paziente.parametri.AspettiFisiologici.length >0 ? (last.AspettiFisiologici.carenzaamminoacidiessenziali >= 3 ? 'Si' : 'No') : '/' }}</a>
              <label class="titoliPiccoliTab" style="margin-bottom: 0"> Amminoacidi sotto la soglia </label><a>{{ paziente.parametri.AspettiFisiologici &&  paziente.parametri.AspettiFisiologici.length >0 ? last.AspettiFisiologici.carenzaamminoacidiessenziali : '/' }}</a>
            </b-row>
          </b-card>
          <!--          Aspetti fisiologici-->
          <b-card style="grid-row: span 2;">
            <label class="titoliPagina" > Aspetti fisiologici </label>
            <div v-if="paziente.parametri.AspettiFisiologici && paziente.parametri.AspettiFisiologici.length > 0" style="display: grid; grid-template-rows: auto 1fr; height: 100%">
              <a style="font-size: 13px"> (misurazione del {{ formatDate(last.AspettiFisiologici.create_date)}})</a>
              <div v-if="paziente.parametri.AspettiFisiologici.length > 0" style="margin-top: 15px; display: flex; flex-direction: column; justify-content: flex-start; row-gap: 10px" >
                <label style="display: flex; justify-content: space-between"><b>Stitichezza:</b> <a> {{last.AspettiFisiologici.stitichezza}}</a> </label>
                <label style="display: flex; justify-content: space-between"><b>Frequenza Evacuazione:</b> <a> {{last.AspettiFisiologici.frequenzaevacuazione}}</a> </label>
              </div>
            </div>
            <div v-else style="height: 100%; display: flex; flex-direction: column; justify-content: space-around; align-items: center">
              Non sono ancora stati inseriti parametri
            </div>
          </b-card>
          <b-card>
            <b-row style="height: 100%; padding: 0 20px; justify-content: space-between; align-items: center">
              <label class="titoliPiccoliTab" style="font-size: 16px; margin-bottom: 0"> Processo Infiammatorio </label><a v-if="last.AspettiFisiologici">{{last.AspettiFisiologici.infiammazioneintestinale >= 1 ? 'Probabile' : (last.AspettiFisiologici.infiammazioneintestinale ? 'Assente' : '/')}}</a><a v-else>/</a>
              <label class="titoliPiccoliTab" style="margin-bottom: 0"> Rapporto Glutammina - Glutammato </label><a v-if="last.AspettiFisiologici">{{ last.AspettiFisiologici.infiammazioneintestinale ? last.AspettiFisiologici.infiammazioneintestinale : '/'}}</a><a v-else>/</a>
            </b-row>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dashboard from "@/services/Dashboard";
import {Line as LineChartGenerator} from 'vue-chartjs/legacy'
import {CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip} from 'chart.js'
import Patients from "@/services/Patients";
import ParametriMedici from "@/services/ParametriMedici";
import VueGauge from 'vue-gauge';
import {calcolaEta, formatDate} from "../utils/formatDate";
import SindromeMetabolica from "@/components/parametriMedici/sindromeMetabolica.vue";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
)

export default {
  name: 'dashboard',
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
  },
  components: {
    SindromeMetabolica,
    LineChartGenerator,
    VueGauge
  },
  mounted: async function () {
    await this.getPaziente()
    await this.getData()
    if(this.last.ParametriCorporei){
      this.bmi = this.last.ParametriCorporei.body_mass_index
    }
    this.isBusy = true
  },
  data() {
    return {
      paziente_id: this.$router.currentRoute.params.id,
      graphData : [],
      dates: [],
      counts: [],
      chartData: {},
      chartOptions:{},
      isBusy:false,
      ultimaSindrome:{},
      bmi:1,
      parametriEmocromoOut:[],
      parametriQuadroOut:[],
      last: {
        ParametriCorporei: {},
        QuadroProteico: {},
        Emocromocitometrico: {},
        SindromeMetabolica: {},
        AspettiFisiologici: {},
      },
      paziente : {
        codice_fiscale: "",
        cognome_paziente: " ",
        create_date: "",
        create_user: "",
        data_nascita: "",
        etnia: "",
        gender: "",
        id: "",
        mail: "",
        nome_paziente: "",
        numero_telefono: "",
        parametri: {
          ParametriCorporei: [],
          QuadroProteico: [],
          Emocromocitometrico: [],
          Dieta: [],
          SindromeMetabolica: [],
          AspettiFisiologici: [],
        }
      }
    }
  },
  methods: {
    async getData() {
      const response = await Dashboard.getGraphData(this.paziente_id)
      if (response.status === 200) {
        this.graphData = response.data
        this.ultimaSindrome = this.graphData[this.graphData.length -1]
        this.dates = this.graphData.map(item => item.create_date);
        this.counts = this.graphData.map(item => item.conteggio_superamenti_soglie);
        const tooltips = this.graphData.map(item => ({
          label: item.create_date,
          content: {
            ...item.parametri
          }
        }));
        this.chartData = {
          labels: this.dates,
          datasets: [
            {
              label: 'Numero fattori di rischio',
              backgroundColor: '#f87979',
              data: this.counts
            }
          ]
        }

        this.chartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              callbacks: {
                afterLabel: function(context) {
                  let tooltipContent = '';
                  if (context.raw) {
                    const item = tooltips[context.dataIndex];
                    tooltipContent += `\n`;
                    Object.keys(item.content).forEach(key => {
                      const titolo = key.replace('_',' ').toLowerCase().charAt(0).toUpperCase() + key.replace('_',' ').toLowerCase().slice(1)
                      tooltipContent += `${titolo}: ${item.content[key]}\n`;
                    });
                  }
                  return tooltipContent;
                }
              },
            }
          },
          scales: {
            y: {
              suggestedMin: 0,
              suggestedMax: 5,
              ticks: {
                stepSize: 1
              }
            }
          }
        }
      }
    },
    capitalizeWords(str) {
      return str.replace(/\b\w/g, function(char) {
        return char.toUpperCase();
      });
    },
    async getPaziente() {
      const response = await Patients.getPatientsList(this.$router.currentRoute.params.id)
      if (response.status === 200) {
        this.paziente = response.data
        if(this.paziente.gender === 'maschio'){
          this.paziente.gender = 'Uomo'
        } else if (this.paziente.gender === 'femmina' ){
          this.paziente.gender = 'Donna'
        }
        this.paziente.eta = calcolaEta(this.paziente.data_nascita)
        this.last = this.getLastEntry(this.paziente.parametri)
        if(this.last.ParametriCorporei){
          this.bmi = this.last.ParametriCorporei.body_mass_index
        }
        let outArray = []
        if (this.last.Emocromocitometrico){
          outArray = this.objectConverter(this.last.Emocromocitometrico)
          this.parametriEmocromoOut = outArray.filter(item => (Number(item.value) < Number(item.minimo) || Number(item.value) > Number(item.massimo)))
        }
        if (this.last.QuadroProteico){
          outArray = this.objectConverter(this.last.QuadroProteico)
          this.parametriQuadroOut = outArray.filter(item => (Number(item.value) < Number(item.minimo) || Number(item.value) > Number(item.massimo)))
        }
      } else {
        this.$bvToast.toast("Errore nei dati del paziente, contattare l'assistenza", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    getLastEntry(data) {
      const lastEntries = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const array = data[key];
          const lastEntry = array.reduce((prev, current) => {
            return new Date(current.create_date) > new Date(prev.create_date) ? current : prev;
          });
          lastEntries[key] = lastEntry;
        }
      }
      return lastEntries;
    },
    calcolaBMI(){
      const array = [
        {
          min:12,
          max:18.5
        },  {
          min:18.5,
          max:25
        },  {
          min:25,
          max:30
        },
        {
          min:30,
          max:35
        },  {
          min:35,
          max:42
        }
      ]
      if(this.bmi <= array[0].min)
        return 0
      if(this.bmi >= array[array.length-1].max)
        return 100
      for(let i = 0; i< array.length; i++){
        const a = array[i]
        if(this.bmi < a.min || this.bmi > a.max)
          continue
        const rel_perc = ((this.bmi - a.min)*100)/(a.max-a.min)
        const int_perc = (20 * rel_perc)/100
        return 20*i+int_perc
      }
      return 0

    },
    async indietro() {
      await this.$router.push('/pazienti')
    },
    async goToPatient() {
      await this.$router.push(`/pazienti/${this.$router.currentRoute.params.id}`)
    },
    objectConverter(data){
      const dataArray = []
      for (const key in data) {
        if (data[key] && typeof data[key] === 'object' && Object.keys(data[key]).length > 0) {
          const { minimo, massimo, unita_misura, value, nome_visualizzato } = data[key];
          dataArray.push({
            nome: key,
            minimo,
            massimo,
            unita_misura,
            value,
            nome_visualizzato
          });
        }
      }
      return dataArray
    },
    formatDate
  }
}

</script>

<style>
.titoliPagina {
  color: #0C56A0;
  font-size:18px;
  letter-spacing:1px;
  font-family: "Montserrat";
  font-weight: 800;
}

.titoliPiccoliTab {
  color: #0C56A0;
  font-size:13px;
  letter-spacing:1px;
  font-family: "Montserrat";
  font-weight: 800;
}

.titoliPaginaPiccoli {
  color: #013569;
  font-size:15px;
  letter-spacing:1px;
}

.vue-gauge-item text:not(:last-child){
  font-size: 12px;
}
</style>
