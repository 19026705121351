<template>
  <div>
    <b-row>
      <b-col style="padding-top: 15px">
        <b-form-group label="Data misurazione:">
          <b-form-input v-model="emocromocitometrico.create_date" type="date" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Globuli bianchi (cellule/mm³):">
          <b-form-input type="number" v-model="emocromocitometrico.globuliBianchi" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Globuli rossi (milione/mm³):">
          <b-form-input type="number" v-model="emocromocitometrico.globuliRossi" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Emoglobina (g/dL):">
          <b-form-input type="number" v-model="emocromocitometrico.emoglobina" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Ematocrito (%):">
          <b-form-input type="number" v-model="emocromocitometrico.ematocrito" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="MCV (fL):">
          <b-form-input type="number" v-model="emocromocitometrico.mcv" ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col style="padding-top: 15px">
        <b-form-group label="MCH (pg):">
          <b-form-input type="number" v-model="emocromocitometrico.mch" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="MCHC (g/dL):">
          <b-form-input type="number" v-model="emocromocitometrico.mchc" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="RDW (%):">
          <b-form-input type="number" v-model="emocromocitometrico.rdw" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="MPV (fL):">
          <b-form-input type="number" v-model="emocromocitometrico.mpv" ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col style="padding-top: 15px" cols="2">
        <b-form-group label="PDW (%):">
          <b-form-input type="number" v-model="emocromocitometrico.pdw" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="PCV (%):">
          <b-form-input type="number" v-model="emocromocitometrico.pcv" ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col style="padding-top: 15px">
        <b-form-group label="Piastrine (milione/mm³):">
          <b-form-input type="number" v-model="emocromocitometrico.piastrine" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Neutrofili (%):">
          <b-form-input type="number" v-model="emocromocitometrico.neutrofili" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Linfociti (%):">
          <b-form-input type="number" v-model="emocromocitometrico.linfociti" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Monociti (%):">
          <b-form-input type="number" v-model="emocromocitometrico.monociti" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Basofili (%):">
          <b-form-input type="number" v-model="emocromocitometrico.basofili" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Eosinofili (%):">
          <b-form-input type="number" v-model="emocromocitometrico.eosinofili" ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
    </b-row>
    <b-row style="display: flex;justify-content: flex-end; margin-top: 30px; margin-bottom: 20px">
      <b-col cols="3">
        <b-button @click="$emit('annulla')" block> Annulla </b-button>
      </b-col>
      <b-col cols="3">
        <b-button block v-b-modal.my-modal3> Salva misurazione </b-button>
      </b-col>
    </b-row>

    <b-modal ok-title="Salva" cancel-title="Annulla" @ok="saveData" ref="my-modal3" id="my-modal3" >
      <label>Sei sicuro di voler salvare i dati?</label>
    </b-modal>

  </div>
</template>

<script>
import ParametriMedici from "@/services/ParametriMedici";
import {roundTo} from "luxon/src/impl/util";
import {formatDateInit} from "@/utils/formatDate";
import {checkDateError} from "@/utils/checkDateError";

export default {
  name: "emocromocitometrico",
  props:["idPaziente", 'dateArray'],
  emits:['annulla', 'save'],
  data() {
    return {
      emocromocitometrico:{
        create_date : formatDateInit()
      }
    }
  },
  methods:{
    async saveData() {
      const body = {
        paziente_id: this.idPaziente,
        ...this.emocromocitometrico
      }
      if(!checkDateError(this.emocromocitometrico.create_date, this.dateArray)) {

        const response = await ParametriMedici.setEmocromo(body)
        if (response.status === 200) {
          this.$bvToast.toast("Dati aggiunti con successo.", {
            title: `Operazione riuscita`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'success',
            solid: true,
            noCloseButton: true
          })
          this.$refs['my-modal3'].hide()
          this.$emit('save')
        } else {
          this.$bvToast.toast("Impossibile salvare i dati, contattare l'assistenza", {
            title: `Attenzione`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            noCloseButton: true
          })
        }
      } else {
        this.$bvToast.toast('Non è possibile inserire un\'altra misurazione per il giorno selezionato', {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    }
  }
}
</script>

<style scoped>
.datepicker-sm{
  padding: 0 10px !important;

}

.datepicker-sm > .label{
  padding: 0 !important;

}


</style>
