<template>
  <div style="height: 100%; padding: 20px 50px 0 50px;">
    <b-row style="height: 20px; width: 100%; margin:0;">
      <b-button style="background-color: #D51160; border: none; width: 5%" @click="indietro"><b-icon icon="arrow-left" font-scale="1.5"></b-icon></b-button>
    </b-row>

    <b-card class="mt-15 lh">
      <b-row style="margin-left: 0; margin-right: 0; margin-bottom: 20px; justify-content: space-between; align-items: center">
        <p class="titoloM" style="margin-bottom: 0">Formulatori</p>
        <b-button @click="aggiungiMedico">Aggiungi Formulatore</b-button>
      </b-row>
      <b-table
          responsive
          stacked="lg"
          striped
          hover
          :items="listaMedici"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          head-variant="light"
          class="sa-b-table"
      >
        <template v-slot:cell(cambio)="row">
          <b-col xs="2" sm="2" md="2" lg="2">
            <b-button size="sm" class="no-text mx-1" variant="outline-primary" @click="openDialogPwd(row)"
                      data-toggle="tooltip" data-placement="bottom" title="Dettagli">
              <b-icon icon="bootstrap-reboot" variant="outline-primary"/>
            </b-button>
          </b-col>
        </template>
        <template v-slot:cell(elimina)="row">
          <b-col xs="2" sm="2" md="2" lg="2">
            <b-button size="sm" class="no-text mx-1" variant="outline-danger" @click="eliminaMedico(row)"
                      data-toggle="tooltip" data-placement="bottom" title="Dettagli">
              <b-icon icon="trash" variant="outline-danger"/>
            </b-button>
          </b-col>
        </template>
      </b-table>
      <template>
        <strong class="text-primary"> Sono presenti {{ rows }} Formulatori</strong>
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right"
                      aria-controls="tbl1" size="sm"></b-pagination>
      </template>
    </b-card>
    <b-modal id="cambioPassword" @ok="cambioPassword" @hide="resetCampi">
      <b-form-group
          id="password1"
          label="Nuova Password: "
          label-for="pwd1"
      >
        <b-form-input v-model="password" :state="validatePassword" id="pwd1" type="password" style="margin-right: 5px; width: 70%"></b-form-input>
        <b-form-invalid-feedback :state="validatePassword">
          La Password deve contenere almeno 8 caratteri, una maiuscola e un numero
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
          id="password2"
          label="Conferma Password: "
          label-for="pwd2"
      >
        <b-form-input v-model="confermaPassword" :state="validateConferma" id="pwd2" type="password" style="margin-right: 5px; width: 70%"></b-form-input>
        <b-form-invalid-feedback :state="validateConferma">
          Le Password non coincidono
        </b-form-invalid-feedback>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import Doctors from "@/services/Doctors";
import Integrators from "@/services/Integrators";
import {DateTime} from "luxon";

export default {
  data() {
    return {
      perPage: 6,
      rows: 0,
      currentPage: 1,
      password: null,
      confermaPassword:null,
      utenteSelezionato: null,
      listaMedici: [],
      fields: [
        {
          label: 'Nome',
          key: "name",
          thStyle: "width: 20rem"
        },
        {
          label: 'Cognome',
          key: "surname",
          thStyle: "width: 20rem"
        },
        {
          label: 'Username',
          key: 'username',
          thStyle: "width: 20rem"
        },
        {
          label: 'Data di Creazione',
          key: `creation_time`,
        },
        {
          label: 'Cambio Password',
          key: 'cambio',
          thStyle: "width: 1rem"
        },
        {
          label: 'Elimina',
          key: 'elimina',
          thStyle: "width: 1rem"
        }
      ]
    }
  },
  computed: {
    validatePassword(){
      const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
      return this.password === null ? null : regex.test(this.password);
    },
    validateConferma(){
      return this.confermaPassword === null ? null : this.confermaPassword === this.password
    }
  },
  mounted: async function() {
    await this.getMedici()
  },
  methods: {
    async getMedici(){
      const response = await Doctors.getDoctors()
      if(response.status === 200){
        this.listaMedici = response.data
        this.rows = response.data.length
        for(let i=0; i<this.listaMedici.length; i++){
          this.listaMedici[i].creation_time = DateTime.fromSeconds(this.listaMedici[i].creation_time).toFormat('dd/MM/yyyy')
        }
      } else {
        this.$bvToast.toast(response.data, {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    async eliminaMedico(row){
      this.$bvModal.msgBoxConfirm("Sei sicuro di voler eliminare il formulatore?", {
        title: "Attenzione",
        centered:true,
        cancelTitle: "Annulla",
        okTitle: "Conferma"
      }).then(async (res) => {
        if(res) {
          const response = await Doctors.deleteDoctors(row.item.username)
          if(response.status === 204){
            this.$bvToast.toast("L'eliminazione è avvenuta con successo.", {
              title: `Successo`,
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000,
              variant: 'success',
              solid: true,
              noCloseButton: true
            })
            await this.getMedici()
          } else {
            this.$bvToast.toast(response.data, {
              title: `Attenzione`,
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000,
              variant: 'danger',
              solid: true,
              noCloseButton: true
            })
          }
        }
      })
    },
    async openDialogPwd(item){
      this.utenteSelezionato = item.item.username
      this.$bvModal.show('cambioPassword')
    },
    async cambioPassword(event){
      event.preventDefault()
      const response = await Doctors.resetPwd(this.utenteSelezionato, this.confermaPassword)
      if(response.status === 204){
        this.$bvToast.toast("La password e stata cambiata correttamente.", {
          title: `Successo`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          noCloseButton: true
        })
        this.$bvModal.hide('cambioPassword')
      } else {
        this.$bvToast.toast(response.data, {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }

    },
    resetCampi(){
      this.password = null
      this.confermaPassword = null
    },
    aggiungiMedico() {
      this.$router.push("/addmedico");
    },
    async indietro(){
      await this.$router.push('/menu')
    },
  }
}
</script>

<style scoped>

</style>
