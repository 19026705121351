<template>
  <b-form @submit.prevent="saveForm">
    <b-row @click="visible_toggle = !visible_toggle" style="background-color: #E9E9E9BE;margin:0 1px; padding: 5px 0; display: grid; grid-template-columns: 1fr auto; align-items: center">
      <div>
        <label class="titoliPagina">Dati Anagrafici</label>
      </div>
      <div>
          <b-icon v-if="visible_toggle" icon="chevron-down" style="margin-right: 15px; color: #D51160" font-scale="1.8"></b-icon>
          <b-icon v-if="!visible_toggle" icon="chevron-up" style="margin-right: 15px; color: #D51160" font-scale="1.8"></b-icon>
      </div>
    </b-row>
    <b-collapse id="collapse-4" class="mt-2" v-model="visible_toggle">
      <b-row style="padding:0 15px">
        <b-col style="padding-top: 15px" cols="3">
          <b-form-group label="Codice Fiscale">
            <b-form-input v-model="paziente.codice_fiscale" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col style="padding-top: 15px" cols="3">
          <b-form-group label="Nome">
            <b-form-input v-model="paziente.nome_paziente" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col style="padding-top: 15px" cols="3">
          <b-form-group label="Cognome">
            <b-form-input v-model="paziente.cognome_paziente" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col style="padding-top: 15px" cols="3">
          <b-form-group label="Data di Nascita" >
            <b-form-input v-model="paziente.data_nascita" type="date" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col style="padding-top: 15px" cols="3">
          <b-form-group label="Genere">
            <b-select :options="genders" v-model="paziente.gender" required></b-select>
          </b-form-group>
        </b-col>
        <b-col style="padding-top: 15px" cols="3">
          <b-form-group label="E-mail">
            <b-form-input v-model="paziente.mail" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col style="padding-top: 15px;" cols="3">
          <b-form-group label="Numero di telefono">
            <b-form-input v-model="paziente.numero_telefono" required></b-form-input>
            <label v-if="paziente.numero_telefono && paziente.numero_telefono.length > 10" style="font-size: 11px; color: red; margin-left:10px">Numero di caratteri elevato</label>
          </b-form-group>
        </b-col>
        <b-col style="padding-top: 15px;" cols="3">
          <b-form-group label="Etnia">
            <b-form-select v-model="paziente.etnia" :options="etnie" value-field="id" text-field="nome" required></b-form-select>
          </b-form-group>
        </b-col>
        <b-row style="width: 100%; margin: 0">
          <b-col cols="12">
            <b-form-group label="Intolleranze">
              <b-form-textarea style="width: 100%" v-model="paziente.intolleranze"></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>

      </b-row>
      <b-row style="padding: 15px 15px; display: flex; justify-content: flex-end ">
        <b-col cols="3">
          <b-button type="submit" block>{{ editing ? 'Modifica Dati Anagrafici' : 'Aggiungi Paziente'}}</b-button>
        </b-col>
      </b-row>
    </b-collapse>

  </b-form>
</template>
<script>
import Patients from "@/services/Patients";
import SindromeMetabolica from "@/components/parametriMedici/sindromeMetabolica.vue";

export default {
  name: "anagraficaPaziente",
  components: {SindromeMetabolica},
  props:["patient","editing"],
  emits:["save"],
  data () {
    return {
      etnie :[],
      paziente: {},
      genders: ["Uomo", "Donna"],
      visible_toggle: true
    }
  },
  watch: {
    patient(newValue, oldValue){
      this.paziente = this.patient
    }
  },
  mounted: async function () {
    if (this.patient){
      this.paziente = this.patient
    }
    await this.getEtnie()
  },
  methods:{
    async getEtnie() {
      console.log("arrivo?")
      const response = await Patients.getEtnieList()
      if (response.status === 200) {
        this.etnie = response.data
      }
    },
    async saveForm() {
      const body = {
        id: this.paziente.id === '' ? '' : this.paziente.id,
        codice_fiscale: this.paziente.codice_fiscale,
        nome_paziente: this.paziente.nome_paziente,
        cognome_paziente: this.paziente.cognome_paziente,
        data_nascita: this.paziente.data_nascita,
        mail: this.paziente.mail,
        numero_telefono: this.paziente.numero_telefono,
        etnia: this.paziente.etnia,
        intolleranze: this.paziente.intolleranze,
      }

      if(this.paziente.gender === 'Uomo'){
        body.gender = 'maschio'
      } else if (this.paziente.gender === 'Donna' ){
        body.gender = 'femmina'
      }

      let response
      if (this.editing) {
        response = await Patients.updatePatient(body)
      } else {
        response = await Patients.createPatient(body)
      }
      if (response.status === 200) {
        if(!this.editing){
          this.paziente.id = response.data.id
        }
        this.$emit("save", this.paziente.id)
      } else {
        this.$bvToast.toast("Errore nel salvataggio", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
  }
}

</script>

<style scoped>
.titoliPagina {
  color: #0C56A0;
  font-size: 23px;
  letter-spacing:1px;
  font-family: "Montserrat";
  font-weight: 700;
  margin-bottom: 0;
  margin-left: 15px;
}
.linea-retta {
  width: 100%;
  position: relative;
  height: 1px;
  background-color: #E9E9E9;
  margin: 20px 0;
}
</style>
