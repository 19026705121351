import {UserManager} from 'oidc-client';

class AuthService {
    constructor() {
        const settings = {
            authority: process.env.VUE_APP_AUTHORITY,
            metadataUrl: `${process.env.VUE_APP_AUTHORITY}.well-known/openid-configuration/`,
            client_id: "formulatoreClient",
            redirect_uri: process.env.VUE_APP_REDIRECT_URI_AUTH,
            response_type: process.env.VUE_APP_RESPONSE_TYPE_AUTH,
            scope: process.env.VUE_APP_SCOPE_AUTH,
            post_logout_redirect_uri:  process.env.VUE_APP_REDIRECT_URI_AUTH,
            userStore: this.sessionStorage,
            loadUserInfo: true,
            automaticSilentRenew: true
        };
        this.userManager = new UserManager(settings)
    }

    getUser() {
        return this.userManager.getUser();
    }

    login() {
        return this.userManager.signinRedirect();
    }

    renewToken() {
        return this.userManager.signinSilent();
    }

    signinRedirectCallback() {
        return this.userManager.signinRedirectCallback();
    }

    logout() {
        return this.userManager.createSignoutRequest({id_token_hint: sessionStorage.getItem('id_token')});
    }

}


const authservice = new AuthService();

export default authservice;
