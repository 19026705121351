<template>
  <div>
    <div class="mt-10 mb-15 mx-15">
      <b-row>
        <b-button class="w-30 ml-4 mb-15" style="float: right; background-color: #D51160; border: none" @click="indietro"><b-icon icon="arrow-left" font-scale="1.5"></b-icon></b-button>
      </b-row>
      <b-row>
        <b-col>
          <h4>Quadro generale: </h4>
          <b-table
              sticky-header
              ref="table"
              id="tbl"
              responsive
              striped
              hover
              bordered
              stacked="lg"
              :items="datiCategoria"
              :fields="fields"
              sort-icon-left
              head-variant="light"
          ></b-table>
        </b-col>
      </b-row>
    </div>
    <div class="mt-7 mx-15">
      <b-row>
        <b-col xs="9" sm="9" md="9" lg="9">
          <h4 class="mb-5" style="float: left">Possibili composizioni:</h4>
        </b-col>
        <b-col v-if="isAdmin" xs="3" sm="3" md="3" lg="3">
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="5">
              <b-checkbox v-if="composizioniList.length >0" style="margin-right: 10px" v-model="completo" @change="setChecked"><h6>Completo</h6></b-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="7">
              <b-button class="mb-5" style="float: right; border: none"  @click="addComposizione"><b-icon icon="plus" variant="outline-secondary"></b-icon> Aggiungi composizione</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-list-group class="mx-5">
            <b-list-group-item v-for="composizione in composizioniList">
                <h6>{{composizione.name}}</h6>
                <br>
                <div class="mx-15">
                  <b-row align-v="center">
                    <b-col xs="12" sm="12" md="10" lg="10">
                      <b-table
                          sticky-header
                          ref="table"
                          id="tbl"
                          responsive
                          striped
                          hover
                          bordered
                          stacked="lg"
                          :items="composizione.combinazione"
                          :fields="fieldsCombinazioni"
                          sort-icon-left
                          head-variant="light"
                      ></b-table>
                    </b-col>
                      <b-col xs="12" sm="12" md="1" lg="1">
                        <b-icon v-if="isAdmin" icon="trash" variant="danger" @click="deleteCombination(composizione)" font-scale="3" />
                      </b-col>
                      <b-col xs="12" sm="12" md="1" lg="1">
                        <b-icon v-if="isAdmin" icon="pencil" variant="primary" @click="modifyCombination(composizione)" font-scale="3"/>
                      </b-col>
                  </b-row>
                </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Categories from "@/services/Categories";
import Combination from "@/services/Combination";

export default {
  computed: {
    isAdmin: function (){
      return sessionStorage.getItem('isAdmin') === 'true'
    }
  },
  data(){
    return {
      datiCategoria: [],
      fields: null,
      completo: false,
      composizioniList: [],
      fieldsCombinazioni: [
        {
          label: 'Componenti',
          key: 'name',
          thStyle: "width: 50rem",

        },
        {
          label: 'Dosaggio (%)',
          key: 'dosaggio',
          thStyle: "width: 10rem",

        }
      ],
    }
  },
  mounted: async function () {
    await this.getCategory()

  },
  methods: {
    async getCategory(){
      const response = await Categories.getCategoryById(this.$router.currentRoute.params.id)
      if(response.status === 401 || response.status === 400){
        this.$bvToast.toast("Si è verificato un errore. Riprovare più tardi.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'error',
          solid: true,
          noCloseButton: true
        })
      } else {
        this.datiCategoria = []
        this.datiCategoria.push(response.data)
        this.completo = this.datiCategoria[0].checked
        if( this.datiCategoria[0].macro === "SO"){
          this.fields =[
            {
              label: 'Condizione clinica macroscopica',
              key: 'condition'
            },
            {
              label: 'Età',
              key: 'age'
            },
            {
              label: 'Basale',
              key: 'basal'
            }
          ]
        } else if(this.datiCategoria[0].macro === "DM") {
          this.fields = [
            {
              label: 'Condizione clinica macroscopica',
              key: 'condition'
            },
            {
              label: 'Età',
              key: 'age'
            },
            {
              label: 'Pressione minima',
              key: 'minPress'
            },
            {
              label: 'Glicemia',
              key: 'glycemia'
            },
            {
              label: 'Colesterolo',
              key: 'cholesterol'
            }
          ]
        } else if(this.datiCategoria[0].macro === "RD") {
          this.fields = [
            {
              label: 'Condizione clinica macroscopica',
              key: 'condition'
            },
            {
              label: 'Età',
              key: 'age'
            },
            {
              label: 'Metabolita',
              key: 'metabolite'
            }
          ]
        }
        await this.getCombination()
      }
    },
    async getCombination(){
      const response = await Combination.getCombinationByCategory(this.datiCategoria[0]._id)
      if(response.status === 401 || response.status === 400 || response.status === 404){
        this.$bvToast.toast("Si è verificato un errore. Riprovare più tardi.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'error',
          solid: true,
          noCloseButton: true
        })
      } else {
        this.composizioniList = response.data
      }
    },
    async deleteCombination(item){
      this.$bvModal
          .msgBoxConfirm("Sei sicuro di voler eliminare la combinazione?", {
            title: "Elimina Combinazione",
            footerClass: "p-2",
            buttonSize: 'md',
            okVariant: 'primary',
            okTitle: 'Si',
            cancelTitle: 'Indietro',
            cancelVariant: 'secondary',
            centered: true,
          })
          .then(async (response) => {
            if (response) {
              const response = await Combination.deleteCombination(item._id)
              if(response.status === 401 || response.status === 400 || response.status === 404){
                this.$bvToast.toast("Si è verificato un errore. Riprovare più tardi.", {
                  title: `Attenzione`,
                  toaster: 'b-toaster-top-center',
                  autoHideDelay: 5000,
                  variant: 'error',
                  solid: true,
                  noCloseButton: true
                })
              } else {
                this.$bvToast.toast("Eliminazione avvenuta con successo", {
                  title: `Successo`,
                  toaster: 'b-toaster-top-center',
                  autoHideDelay: 5000,
                  variant: 'success',
                  solid: true,
                  noCloseButton: true
                })
                await this.getCategory()
                if(this.composizioniList.length === 0){
                  const response = await Categories.updateCategory(this.$router.currentRoute.params.id, false)
                  if(response.status === 401 || response.status === 400 || response.status === 404){
                    this.$bvToast.toast("Si è verificato un errore. Riprovare più tardi.", {
                      title: `Attenzione`,
                      toaster: 'b-toaster-top-center',
                      autoHideDelay: 5000,
                      variant: 'error',
                      solid: true,
                      noCloseButton: true
                    })
                  }
                }
              }
            }
          })
          .catch((error) => {
          });
    },
    async modifyCombination(item){
      await this.$router.push(`addcomposizione/${this.$router.currentRoute.params.id}/${item._id}`)
    },
    async addComposizione(){
      await this.$router.push(`addcomposizione/${this.$router.currentRoute.params.id}`)
    },
    async indietro(){
      await this.$router.push('/home')
    },
    async setChecked(){
      const response = await Categories.updateCategory(this.$router.currentRoute.params.id, this.completo)
      if(response.status === 401 || response.status === 400 || response.status === 404){
        this.$bvToast.toast("Si è verificato un errore. Riprovare più tardi.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'error',
          solid: true,
          noCloseButton: true
        })
      } else {
        this.$bvToast.toast("Lo stato della categoria è stato modificato", {
          title: `Operazione andata a buon fine`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          noCloseButton: true
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
