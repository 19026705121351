<template>
  <div>
    <div class="mt-10 mb-15 mx-15">
      <b-row>
        <b-button class="w-30 ml-4 mb-15" style="float: right; background-color: #D51160; border: none" @click="indietro"><b-icon icon="arrow-left" font-scale="1.5"></b-icon></b-button>
      </b-row>
      <b-row>
        <b-col>
          <h4>Quadro generale: </h4>
          <b-table
              sticky-header
              ref="table"
              id="tbl"
              responsive
              striped
              hover
              bordered
              stacked="lg"
              :items="datiCategoria"
              :fields="fields"
              sort-icon-left
              head-variant="light"
          ></b-table>
        </b-col>
      </b-row>
    </div>
    <div class="mt-7 mx-15">
    <b-row>
      <b-col>
        <b-row>
          <h4 class="mb-5" style="float: left">Nuova composizione:</h4>
        </b-row>
        <b-row>
          <b-input class="mb-6 mt-6" type="text" v-model="combination.name" placeholder="Nome Combinazione" style="width: 15%"></b-input>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-card class="mb-15 " style="width: 100%">
        <b-row>
          <b-col>
            <h5 class="mb-5" style="float: left">Scegli dosaggio</h5>
          </b-col>
          <b-col>
            <h6 style="float: right; color: gray">Percentuale corrente: {{percentualeTotale.toFixed(2)}}%</h6>
          </b-col>
        </b-row>
        <b-row>
            <b-table
                sticky-header
                ref="table"
                id="tbl"
                responsive
                stacked="lg"
                style="background-color: #f8f8f8; width: 80%; margin-left: 10px"
                hover
                bordered
                :items="selectedComponent"
                :fields="fieldsComponent"
                sort-icon-left
                head-variant="light"
                class="sa-b-table"
            >
              <template v-slot:cell(dose)="row">
                <b-form-input type="number" v-model="row.item.dose"  step="0.1" style="width: 50%; position: center" @change="calcoloPercentuale"></b-form-input>
              </template>
            </b-table>
        </b-row>
        <b-button style="float: right; background-color: #D51160; border: none" @click="avanti">Avanti</b-button>
      </b-card>
    </b-row>
  </div>

  </div>
</template>

<script>
import Categories from "@/services/Categories";
import Advices from "@/services/Advices";
import Combination from "@/services/Combination";

export default {
  data(){
    return {
      datiCategoria: [],
      fields: null,
      selectedComponent: [],
      advice: {},
      percentualeTotale: 100,
      combination: {
        dosaggioTotale: 0,
        combinazione: []
      },
      fieldsComponent: [
        {
          label: 'Componente',
          key: 'name'
        },
        {
          label: 'Dose Consigliata (%)',
          key: 'doseConsigliata',
        },
        {
          label: 'Dose (%)',
          key: 'dose',
          thStyle: "width: 15rem",
        }
      ]
    }
  },
  created: async function () {
    await this.getCategory()
    await this.getAdvice()
    await this.calcoloPercentuale()
  },
  methods: {
    async getCategory(){
      const response = await Categories.getCategoryById(this.$router.currentRoute.params.id)
      if(response.status === 401 || response.status === 400){
        this.$bvToast.toast("Si è verificato un errore. Riprovare più tardi.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'error',
          solid: true,
          noCloseButton: true
        })
      } else {
        this.datiCategoria = []
        this.datiCategoria.push(response.data)
        if( this.datiCategoria[0].macro === "SO"){
          this.fields =[
            {
              label: 'Condizione clinica macroscopica',
              key: 'condition'
            },
            {
              label: 'Età',
              key: 'age'
            },
            {
              label: 'Basale',
              key: 'basal'
            }
          ]
        } else if(this.datiCategoria[0].macro === "DM") {
          this.fields = [
            {
              label: 'Condizione clinica macroscopica',
              key: 'condition'
            },
            {
              label: 'Età',
              key: 'age'
            },
            {
              label: 'Pressione minima',
              key: 'minPress'
            },
            {
              label: 'Glicemia',
              key: 'glycemia'
            },
            {
              label: 'Colesterolo',
              key: 'cholesterol'
            }
          ]
        } else if(this.datiCategoria[0].macro === "RD") {
          this.fields = [
            {
              label: 'Condizione clinica macroscopica',
              key: 'condition'
            },
            {
              label: 'Età',
              key: 'age'
            },
            {
              label: 'Metabolita',
              key: 'metabolite'
            }
          ]
        }
      }
    },
    async getAdvice(){
        const response = await Advices.getAdvice(this.$router.currentRoute.params.id)
        if(response.status === 401 || response.status === 400){
          this.$bvToast.toast("Si è verificato un errore. Riprovare più tardi.", {
            title: `Attenzione`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'error',
            solid: true,
            noCloseButton: true
          })
        } else {
          this.advice = response.data
          const tmp = this.$router.currentRoute.params.elementi.split(',')
          for(let i=0; i<response.data.componentiDaVisualizzare.length; i++){
            for(let j=0; j<this.$router.currentRoute.params.elementi.length; j++){
              if(tmp[j] == response.data.componentiDaVisualizzare[i].id){
                this.selectedComponent.push(response.data.componentiDaVisualizzare[i])
              }
            }
          }
          let divisore = 0
          for(let i=0; i<this.selectedComponent.length;i++){
            divisore += this.selectedComponent[i].moltiplicatoreDosaggio
          }
          let index = 100 / divisore
          for(let i=0; i<this.selectedComponent.length;i++){
            this.selectedComponent[i].dose = Math.round(index * this.selectedComponent[i].moltiplicatoreDosaggio*100)/100
            this.selectedComponent[i].doseConsigliata = this.selectedComponent[i].dose
          }
        }
      if(this.$router.currentRoute.params.idCombination){
        const response = await Combination.getCombinationById(this.$router.currentRoute.params.idCombination)
        if(response.status === 401 || response.status === 400){
          this.$bvToast.toast("Si è verificato un errore. Riprovare più tardi.", {
            title: `Attenzione`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'error',
            solid: true,
            noCloseButton: true
          })
        } else {
          for (let i=0; i<this.selectedComponent.length; i++){
            for(let j=0; j<response.data.combinazione.length; j++){
              if(this.selectedComponent[i].name === response.data.combinazione[j].name){
                this.selectedComponent[i].dose = response.data.combinazione[j].dosaggio
              }
            }
          }
        }
      }
      this.$refs.table.refresh()
    },
    async avanti(){
      let percentuale = 0
      let blocco = false
      for(let i=0; i<this.selectedComponent.length; i++){
        if(parseFloat(this.selectedComponent[i].dose) === 0){
          blocco = true
        } else
        percentuale+= parseFloat(this.selectedComponent[i].dose)
      }
      if(blocco){
        this.$bvToast.toast("Nessuna componente può avere dose nulla.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      } else
      if((Math.round((percentuale*100))/100) === 100){
        this.combination.category = this.$router.currentRoute.params.id
        for(let i=0; i<this.selectedComponent.length; i++){
          const tmp = {}
          this.combination.dosaggioTotale += this.selectedComponent[i].dose
          tmp.name = this.selectedComponent[i].name
          tmp.dosaggio = this.selectedComponent[i].dose
          this.combination.combinazione.push(tmp)
        }
        if(this.$router.currentRoute.params.idCombination === undefined){
          const response = await Combination.createCombination(this.combination)
          if(response.status === 401 || response.status === 400){
            this.$bvToast.toast("Si è verificato un errore. Riprovare più tardi.", {
              title: `Attenzione`,
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000,
              variant: 'error',
              solid: true,
              noCloseButton: true
            })
          } else {
            await this.$router.push(`/dettaglio/${this.$router.currentRoute.params.id}`)
          }
        } else {
          const response = await Combination.updateCombination(this.$router.currentRoute.params.idCombination, this.combination)
          if(response.status === 401 || response.status === 400){
            this.$bvToast.toast("Si è verificato un errore. Riprovare più tardi.", {
              title: `Attenzione`,
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000,
              variant: 'error',
              solid: true,
              noCloseButton: true
            })
          } else {
            await this.$router.push(`/dettaglio/${this.$router.currentRoute.params.id}`)
          }
      }
      } else {
        this.$bvToast.toast("La somma delle dosi deve essere esattamente 100.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    async calcoloPercentuale(){
      let tmp = 0
      for(let i=0; i<this.selectedComponent.length; i++){
        tmp += parseFloat(this.selectedComponent[i].dose)
      }
      this.percentualeTotale = tmp
    },
    async indietro(){
      await this.$router.push({path: `/dettaglio/addcomposizione/${this.$router.currentRoute.params.id}/${this.$router.currentRoute.params.idCombination}`})
    }
  }
}
</script>

<style scoped>

</style>
