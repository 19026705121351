import BaseService from "./BaseService";

export default class ParametriMedici extends BaseService {

    static async getPhisicalParams(id){
        return await this.perform({
            url: '/pazienti/parametricorporei/paziente',
            method: 'GET',
            params:{
                id:id
            }
        })
    }

    static async setPhysicParams(body){
        return await this.perform({
            url: '/pazienti/parametricorporei/set',
            method: 'POST',
            body:body
        })
    }

    static async getEmocromocitometrico(id){
        return await this.perform({
            url: '/pazienti/emocromocitometrico/paziente',
            method: 'GET',
            params:{
                id:id
            }
        })
    }

    static async setEmocromo(body){
        return await this.perform({
            url: '/pazienti/emocromocitometrico/set',
            method: 'POST',
            body: body
        })
    }

    static async getQuadroProteico(id){
        return await this.perform({
            url: '/pazienti/quadroproteico/paziente',
            method: 'GET',
            params:{
                id:id
            }
        })
    }

    static async setQuadroProteico(body){
        return await this.perform({
            url: '/pazienti/quadroproteico/set',
            method: 'POST',
            body:body
        })
    }

    static async getSindrome(id){
        return await this.perform({
            url: '/pazienti/sindromeMetabolica/paziente',
            method: 'GET',
            params:{
                id:id
            }
        })
    }

    static async setSindrome(body){
        return await this.perform({
            url: '/pazienti/sindromeMetabolica/set',
            method: 'POST',
            body:body
        })
    }

    static async getStatiSindrome(){
        return await this.perform({
            url: '/pazienti/sindromeMetabolica/stato',
            method: 'GET'
        })
    }

    static async getAspettiFisiologici(id){
        return await this.perform({
            url: '/pazienti/aspettifisiologici/paziente',
            method: 'GET',
            params:{
                id:id
            }
        })
    }

    static async setAspettiFisiologici(body){
        return await this.perform({
            url: '/pazienti/aspettifisiologici/set',
            method: 'POST',
            body:body
        })
    }

    static async getFrequenzaEvacuazione(){
        return await this.perform({
            url: '/pazienti/aspettifisiologici/frequenzaevacuazione',
            method: 'GET'
        })
    }
}
