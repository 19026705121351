import BaseService from "./BaseService";

export default class Login extends BaseService {

    static async getToken(body){
        return await this.perform({
            url: '/getAccessToken',
            method: 'POST',
            body: body
        })
    }
    static async login(body){
        return await this.perform({
            url: '/login',
            method: 'POST',
            body: body
        })
    }

    static async refreshToken(){
        return await this.perform({
            url: '/renewAccessToken',
            method: 'POST',
            body: {
                refresh_token: sessionStorage.getItem('refresh_token'),
                client_id: 'formulatoreClient',
                grant_type: 'refresh_token'
            }
        })
    }

    // static async login(credenziali){
    //     return await this.perform({
    //         url:'/user/login',
    //         method: 'post',
    //         body: credenziali
    //     })
    // }

    static async logout(){
        return await this.perform({
            url: '/user/signout',
            method: 'post'
        })
    }
}
