import axios from "axios";
import Login from "@/services/Login";

// let HttpRequest = {
//   url,
//   method: "get" | "post" | "put" | "delete" | "GET" | "POST" | "PUT" | "DELETE",
//   auth,
//   body,
//   params,
//   token
// };
//
//
// let APIResponse = {
//   error,
//   code,
//   msg,
//   data
// };

export default class BaseService {

    static async perform(request){
        try{
            const config = {
                baseURL: process.env.VUE_APP_URL_BACKEND,
                url: request.url,
                method: request.method,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + sessionStorage.getItem('token')
                },
                data: request.body,
                params: request.params
            };

            // if (request.auth) {
            //     config.headers!["Authorization"] = `Bearer ${request.token ?? localStorage.getItem("access-token")}`;
            // }
            // if( sessionStorage.getItem("access_token") !== null)
            //     config.headers["authorization"] = "Bearer: " + sessionStorage.getItem("access_token");


            // const response = (await axios(config));
            // setTimeout(()=>{
            //     if(response.error && response.code === 401){
            //         sessionStorage.removeItem("auth")
            //         sessionStorage.removeItem("id_token")
            //         this.$router.push('/')
            //     }},2000)
            // return response;
            axios.interceptors.response.use(function (response) {
                return response;
            }, async function (error) {
                if (error.response.status === 401) {
                    // error.config._retry = true;
                    //let tokenApi = await getTokenApi();
                    // let response = await Login.refreshToken()
                    //sessionStorage.setItem('apiToken', tokenApi.data.data.access_token)
                    // sessionStorage.removeItem('auth')
                    // sessionStorage.setItem('auth', response.data.access_token)
                    // window.location.reload();
                    sessionStorage.clear()
                    window.location.assign('/')
                    return await axios(config);
                }
                else {
                    return error.response;
                }
            });
            return await axios(config);
        } catch (err) {
            if(err.response) {
                return err.response;
            } else {
                console.log(err);
                return err
            }
        }
    }

}
