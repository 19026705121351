<template>
  <div style="display: flex; align-items: center; justify-content: center;position: fixed; left: 0; top: 0; width: 100vw; height: 100vh; background-color: rgba(0,0,0,0.5)">
    <div style="background-color: white; width:90%; height: 70%; border-radius: 5px; box-shadow: 0 0 30px -10px  rgba(0,0,0,0.5); padding: 20px; display: grid; grid-template-rows: auto 1fr">
      <h3 style="color: #000000; padding: 0">Modifica pasto</h3>

      <div style="display: grid; grid-template-columns: 4fr auto 2fr; column-gap: 16px; overflow: hidden;">
        <div style="display: grid; grid-template-rows: auto 1fr; height: 100%; overflow: hidden;">
          <b-input @input="handleInputChange" placeholder="Cerca alimento..." v-model="searchFilter"></b-input>
          <b-table
              class="foodTable"
              style="margin-top: 8px; height: 100% !important;"
              striped
              hover
              bordered
              sticky-header="100%"
              :items="copySizes"
              :fields="fields"
              sort-icon-left
              head-variant="light"
              @row-clicked="selectFood"
          ></b-table>

        </div>
        <div style="background-color: lightgrey; width: 1px">

        </div>
        <div style="display: grid; grid-template-rows: 1fr auto; overflow: hidden; row-gap: 10px;">
          <div style="overflow: hidden;">
            <b-table
                class="cartTable"
                style="margin-top: 8px; height: 100% !important;"
                striped
                hover
                bordered
                sticky-header="100%"
                :items="cart"
                :fields="fieldsCart"
                sort-icon-left
                head-variant="light"
            >
              <template v-slot:cell(quantita)="{item}">
                <b-input style="width: 100px" placeholder="Qtà" v-model="item.quantita"></b-input>
              </template>
              <template v-slot:cell(unita_misura)="{item}">
                <b-form-select :options="unitaMisura" v-model="item.unita_misura" value-field="id" text-field="nome" ></b-form-select>
              </template>
              <template v-slot:cell(actions)="{item, index}">
                <b-button variant="danger" @click="deselectFood(item, index)">
                  <b-icon icon="dash-circle-fill"></b-icon>
                </b-button>
              </template>
            </b-table>
          </div>
          <div>
            <b-button size="lg" variant="success" block @click="$emit('salva', pasto, cart)">Salva</b-button>
            <b-button size="lg" block @click="$emit('annulla')">Annulla</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import Diete from "@/services/Diete";
import {translateMeal} from "@/utils/translate";

export default {
  name: "componiPasto",
  props: ["pasto", "tipoDieta", "items", "unitaMisura"],
  emits: ["annulla", "salva"],
  async mounted() {
    await this.getAlimentiPasti(this.pasto)

    this.cart = this.items.map(x => ({
      ...this.sizes.find(y => y.Nome === x.nome),
      quantita: x.quantita,
      unita_misura: x.unita_misura
    }))

    this.updateData()
  },
  data() {
    return {
      searchFilter: "",
      sizes: [],
      copySizes: [],
      cart: [],
      fields: [
        {
          label: 'Nome',
          key: 'Nome'
        },
        {
          label: 'Calorie',
          key: 'Energia_calorie_kcal'
        },
        {
          label: 'Acidi grassi saturi (g)',
          key: 'Acidi_grassi_saturi_g'
        },
        {
          label: 'Acidi grassi monoinsaturi (g)',
          key: 'Acidi_grassi_monoinsaturi_g'
        },
        {
          label: 'Colesterolo (mg)',
          key: 'Colesterolo_mg'
        },
        {
          label: 'Zuccheri (g)',
          key: 'Zuccheri_g'
        }
      ],
      fieldsCart: [
        {
          label: 'Nome',
          key: 'Nome'
        },
        {
          label: 'Quantità',
          key: 'quantita'
        },
        {
          label: 'Unità di misura',
          key: 'unita_misura'
        },
        {
          label: '',
          key: 'actions'
        }
      ],
      timeoutId: 0
    }
  },
  methods:{
    handleInputChange () {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.updateData();
      }, 500);// Tempo di attesa in millisecondi prima di eseguire la ricerca
    },
    updateData(){
      this.copySizes = this.sizes.filter(x => this.cart.filter(y => y.Nome === x.Nome).length === 0)
      this.copySizes = this.copySizes.filter(x => (x.Nome.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      this.copySizes.sort((x, y) => {
        if (x.Nome < y.Nome) return -1;
        if (x.Nome > y.Nome) return 1;
        return 0;
      });
    },
    selectFood (itm, index){
      const item = this.copySizes[index];
      const unita = item.dose.split(' ')[1];
      this.cart.push({...item, quantita: 100, unita_misura: unita});
      this.updateData()
    },
    deselectFood(itm, index) {
      const item = this.cart[index];
      this.cart.splice(index, 1);
      this.updateData()
    },
    async getAlimentiPasti(pasto) {
      this.dialog = true
      this.sizes = []

      const response = await Diete.getAlimentiPasti(this.tipoDieta, pasto)
      if(response.status === 200){
        pasto = translateMeal(pasto)
        this.sizes = response.data[pasto]
        this.sizes.map(x=> x.energia_calorie_kcal_riferimento = x.Energia_calorie_kcal)
        this.updateData()
      }
    },
  }
}

</script>

<style>
.foodTable tr {
  cursor: pointer;
}
</style>
