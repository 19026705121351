import BaseService from "./BaseService";

export default class Advices extends BaseService {

    static async getAdvice(id){
        return await this.perform({
            url:'/getAdvice',
            method: 'GET',
            params: {'_id': id}
        })
    }

    static async getAdviceByConditios(condizioni, id){
        return await this.perform({
            url:'/getAdviceByConditionId',
            method: 'POST',
            body: {condizioni: condizioni, id_patologia: id}
        })
    }

    static async makeSuggestionWithRange(condizioni, id){
        return await this.perform({
            url:'/makeSuggestionWithRange',
            method: 'POST',
            body: {id_condizione: condizioni, id_patologia: id}
        })
    }
}
