<template>
  <div>
    <div class="mr-5 mx-5">
      <b-row>
        <b-col>
          <h1 class="mt-10 mb-15" style="float: left">Formulazione Integratori</h1>
        </b-col>
      </b-row>
    </div>
    <b-tabs class="ml-15 mx-15" fill :value="tabIndex" v-model="tabIndex">
      <b-card style="margin: 0">
        <b-tab active title="Stress Ossidativo" @click="resetPage()" lazy>
          <div>
            <b-table
                sticky-header
                ref="table"
                id="tbl1"
                responsive
                stacked="lg"
                striped
                hover
                :items="stressOssidativoList"
                :fields="fields1"
                :per-page="perPage"
                :current-page="currentPage"
                sort-icon-left
                head-variant="light"
                class="sa-b-table"
            >
              <template v-slot:cell(actions)="row">
                <b-col xs="6" sm="6" md="6" lg="6">
                  <b-button v-if="isAdmin" size="sm" class="no-text mx-1" variant="outline-secondary" @click="onEdit(row.item)"
                            data-toggle="tooltip" data-placement="bottom" title="Modifica">
                    <b-icon icon="pencil" variant="outline-secondary"/>
                  </b-button>
                  <b-button v-else size="sm" class="no-text mx-1" variant="outline-secondary" @click="onEdit(row.item)"
                            data-toggle="tooltip" data-placement="bottom" title="Dettagli">
                    <b-icon icon="search" variant="outline-secondary"/>
                  </b-button>
                </b-col>
              </template>
              <template v-slot:cell(check)="row">
                <b-col xs="6" sm="6" md="6" lg="6">
                  <b-icon v-if="row.item.checked" icon="check" variant="success" font-scale="2"></b-icon>
                </b-col>
              </template>
            </b-table>
            <template>
              <strong class="text-primary"> Sono presenti {{ rows1 }} Disturbi da Stress Ossidativo</strong>
              <b-pagination v-model="currentPage" :total-rows="rows1" :per-page="perPage" align="right"
                            aria-controls="tbl1" size="sm"></b-pagination>
            </template>
          </div>
        </b-tab>
        <b-tab title="Disturbi Metabolici" @click="resetPage()" lazy>
          <div>
            <b-table
                sticky-header
                ref="table"
                id="tbl"
                responsive
                stacked="lg"
                striped
                hover
                :items="disturbiMetaboliciList"
                :fields="fields2"
                :per-page="perPage"
                :current-page="currentPage"
                sort-icon-left
                head-variant="light"
                class="sa-b-table"
            >
              <template v-slot:cell(actions)="row">
                <b-col xs="6" sm="6" md="6" lg="6">
                  <b-button v-if="isAdmin" size="sm" class="no-text mx-1" variant="outline-secondary" @click="onEdit(row.item)"
                            data-toggle="tooltip" data-placement="bottom" title="Modifica">
                    <b-icon icon="pencil" variant="outline-secondary"/>
                  </b-button>
                  <b-button v-else size="sm" class="no-text mx-1" variant="outline-secondary" @click="onEdit(row.item)"
                            data-toggle="tooltip" data-placement="bottom" title="Dettagli">
                    <b-icon icon="search" variant="outline-secondary"/>
                  </b-button>
                </b-col>
              </template>
              <template v-slot:cell(check)="row">
                <b-col xs="6" sm="6" md="6" lg="6">
                  <b-icon v-if="row.item.checked" icon="check" variant="success" font-scale="2"></b-icon>
                </b-col>
              </template>
            </b-table>
            <template>
              <strong class="text-primary"> Sono presenti {{ rows1 }} Disturbi Metabolici</strong>
              <b-pagination v-model="currentPage" :total-rows="rows2" :per-page="perPage" align="right"
                            size="sm"></b-pagination>
            </template>
          </div>
        </b-tab>
        <b-tab title="Disturbi Renali" @click="resetPage()" lazy>
          <div>
            <b-table
                sticky-header
                ref="table"
                id="tbl"
                responsive
                stacked="lg"
                striped
                hover
                :items="disturbiRenaliList"
                :fields="fields3"
                :per-page="perPage"
                :current-page="currentPage"
                sort-icon-left
                head-variant="light"
                class="sa-b-table"
            >
              <template v-slot:cell(actions)="row">
                <b-col xs="6" sm="6" md="6" lg="6">
                  <b-button v-if="isAdmin" size="sm" class="no-text mx-1" variant="outline-secondary" @click="onEdit(row.item)"
                            data-toggle="tooltip" data-placement="bottom" title="Modifica">
                    <b-icon icon="pencil" variant="outline-secondary"/>
                  </b-button>
                  <b-button v-else size="sm" class="no-text mx-1" variant="outline-secondary" @click="onEdit(row.item)"
                            data-toggle="tooltip" data-placement="bottom" title="Dettagli">
                    <b-icon icon="search" variant="outline-secondary"/>
                  </b-button>
                </b-col>
              </template>
              <template v-slot:cell(check)="row">
                <b-col xs="6" sm="6" md="6" lg="6">
                  <b-icon v-if="row.item.checked" icon="check" variant="success" font-scale="2"></b-icon>
                </b-col>
              </template>
            </b-table>
            <template>
              <strong class="text-primary"> Sono presenti {{ rows1 }} Disturbi Renali</strong>
              <b-pagination class="paginationCustom" v-model="currentPage" :total-rows="rows3" :per-page="perPage" align="right"
                            size="sm"></b-pagination>
            </template>
          </div>
        </b-tab>
      </b-card>
    </b-tabs>

  </div>
</template>

<script>
import Categories from "@/services/Categories";
import Login from "@/services/Login";
import router from "@/router/router";
import Patologies from "@/services/Patologies";

export default {
  computed: {
    isAdmin: function (){
      return sessionStorage.getItem('isAdmin') === 'true'
    }
  },
  data() {
    return {
      perPage: 6,
      rows1: 0,
      rows2: 0,
      rows3: 0,
      currentPage: 1,
      tabIndex: 0,
      stressOssidativoList: [],
      disturbiMetaboliciList: [],
      disturbiRenaliList: [],
      fields1: [
        {
          label: 'Condizione clinica macroscopica',
          key: 'condition',
          thStyle: "width: 50rem",
        },
        {
          label: 'Età',
          key: 'age'
        },
        {
          label: 'Basale',
          key: 'basal'
        },
        {
          label: 'Numero composizioni',
          key: 'combinations',
          thStyle: "width: 4rem"
        },
        {
          label: sessionStorage.getItem('isAdmin') === 'true' ? "Modifica": "Dettagli",
          key: "actions",
          thStyle: "width: 3rem",
        },
        {
          label: "Completato",
          key: "check",
          thStyle: "width: 3rem",
        },
      ],
      fields2: [
        {
          label: 'Condizione clinica macroscopica',
          key: 'condition',
          thStyle: "width: 50rem",
        },
        {
          label: 'Età',
          key: 'age'
        },
        {
          label: 'Pressione minima',
          key: 'minPress'
        },
        {
          label: 'Glicemia',
          key: 'glycemia'
        },
        {
          label: 'Colesterolo',
          key: 'cholesterol'
        },
        {
          label: 'Numero composizioni',
          key: 'combinations',
          thStyle: "width: 4rem"
        },
        {
          label: "Modifica",
          key: "actions",
          thStyle: "width: 3rem",
        },
        {
          label: "Completato",
          key: "check",
          thStyle: "width: 3rem",
        },
      ],
      fields3: [
        {
          label: 'Condizione clinica macroscopica',
          key: 'condition',
          thStyle: "width: 50rem",
        },
        {
          label: 'Età',
          key: 'age'
        },
        {
          label: 'Metabolita',
          key: 'metabolite'
        },
        {
          label: 'Numero composizioni',
          key: 'combinations',
          thStyle: "width: 4rem"
        },
        {
          label: "Modifica",
          key: "actions",
          thStyle: "width: 3rem",
        },
        {
          label: "Completato",
          key: "check",
          thStyle: "width: 3rem",
        },
      ]
    }
  },
  mounted: async function () {
    const test = await this.$oidc.getUser()
    const response = await Patologies.getPatologies()
    await this.getCategories()
    if (sessionStorage.getItem('tab') !== null) {
      this.tabIndex = parseInt(sessionStorage.getItem('tab'))
    }
  },
  methods: {
    async getCategories() {
      const response = await Categories.getCategories()
      if (response.status === 401) {
        this.$bvToast.toast("Si è verificato un errore. Riprovare più tardi.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'error',
          solid: true,
          noCloseButton: true
        })
      } else {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].macro === "SO") {
            this.stressOssidativoList.push(response.data[i])
          } else if (response.data[i].macro === "DM") {
            this.disturbiMetaboliciList.push(response.data[i])
          } else {
            this.disturbiRenaliList.push(response.data[i])
          }
        }
        this.rows1 = this.stressOssidativoList.length
        this.rows2 = this.disturbiMetaboliciList.length
        this.rows3 = this.disturbiRenaliList.length
      }
    },
    async onEdit(item) {
      sessionStorage.setItem('tab', this.tabIndex)
      await this.$router.push({path: `dettaglio/${item._id}`})
    },
    resetPage() {
      this.currentPage = 1
    }
  }
}
</script>

<style>

.nav-tabs .nav-link {
  background-color: #283f83;
  color: white;

}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background-color: #D51160;
  color: white;
}

</style>
