<template>
  <div>
    <b-row>
      <b-col style="padding-top: 15px">
        <b-form-group label="Data misurazione:">
          <b-form-input v-model="physicalParameters.create_date" type="date" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Rapporto vita fianchi:">
          <b-form-input type="number" v-model="physicalParameters.rapporto_vita_fianchi"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Frequenza cardiaca (bpm):">
          <b-form-input type="number" v-model="physicalParameters.frequenza_cardiaca"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px" cols="6">
        <b-form-group style="width: 100%" label="Valutazione della qualità della vita (EORTC QLQ-C30):">
          <b-form-input v-model="physicalParameters.qlqC30"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row style="padding-top: 15px">
      <b-col cols="2">
        <b-form-group label="Altezza (cm):">
          <b-form-input type="number" v-model="altezza"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Peso (kg):">
          <b-form-input type="number" v-model="peso"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="2" style="display: flex; justify-content: center; align-items: flex-end; margin-bottom: 15px;">
        <b-button variant="primary" block @click="calcolaBMI()">Calcola BMI</b-button>
      </b-col>
      <b-col cols="2">
        <b-form-group label="BMI (kg/m²):">
          <b-form-input type="number" v-model="physicalParameters.body_mass_index"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row style="display: flex;justify-content: flex-end; margin-top: 20px; margin-bottom: 20px">
      <b-col cols="3">
        <b-button @click="$emit('annulla')" block> Annulla </b-button>
      </b-col>
      <b-col cols="3">
        <b-button block v-b-modal.my-modal2> Salva misurazione </b-button>
      </b-col>
    </b-row>
    <b-modal ok-title="Salva" cancel-title="Annulla" @ok="saveData" ref="my-modal2" id="my-modal2" >
      <label>Sei sicuro di voler salvare i dati?</label>
    </b-modal>
  </div>

</template>

<script>
import ParametriMedici from "@/services/ParametriMedici";
import {formatDateInit } from "@/utils/formatDate";
import {checkDateError} from "@/utils/checkDateError.js";

export default {
  name: "parametriFisici",
  props:["idPaziente", 'dateArray'],
  emits:['annulla', 'save'],
  data() {
    return {
      physicalParameters:{
        create_date : formatDateInit(),
        body_mass_index:null,
      },
      altezza: null,
      peso:null,
    }
  },
  methods:{
    async saveData() {
      const body = {
        ...this.physicalParameters,
        paziente_id: this.idPaziente,
        body_mass_index: this.physicalParameters.body_mass_index ? this.physicalParameters.body_mass_index.toString() : ''
      }
      if(!checkDateError(this.physicalParameters.create_date, this.dateArray)){
        const response = await ParametriMedici.setPhysicParams(body)
        if (response.status === 200) {
          this.$bvToast.toast("Parametri Fisici aggiunti con successo.", {
            title: `Operazione riuscita`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'success',
            solid: true,
            noCloseButton: true
          })
          this.$refs['my-modal2'].hide()
          this.$emit('save')
        } else {
          this.$bvToast.toast("Impossibile salvare i dati, contattare l'assistenza", {
            title: `Attenzione`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            noCloseButton: true
          })
        }
      } else {
        this.$bvToast.toast('Non è possibile inserire un\'altra misurazione per il giorno selezionato', {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    calcolaBMI(){
      const altezza = this.altezza / 100
      this.physicalParameters.body_mass_index = (this.peso / (altezza * altezza)).toFixed(2)
    }
  }
}
// display: inline-block;
// vertical-align: middle;
</script>

<style scoped>

</style>
