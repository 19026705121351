import BaseService from "./BaseService";

export default class Integrators extends BaseService {

    static async getIntegratori(){
        return await this.perform({
            url: '/getIntegrators',
            method: 'get'
        })
    }

    static async getIntegrator(id){
        return await this.perform({
            url: '/getIntegratorDetails',
            method: 'get',
            params: {id: id}
        })
    }

    static async getPatientIntegrators(id){
        return await this.perform({
            url: '/getPatientIntegrators',
            method: 'GET',
            params: {paziente_id: id}
        })
    }

    static async setIntegrator(body){
        return await this.perform({
            url: '/setIntegrator',
            method: 'POST',
            body: body
        })
    }

    static async deleteIntegrator(id) {
        return await this.perform({
            url: '/deleteIntegrator',
            method: 'POST',
            body: {id: id}
        })
    }

    static async updateIntegrator(body, id){
        return await this.perform({
            url:'/updateIntegrator',
            method: 'PUT',
            params: {id: id},
            body: body
        })
    }
}
