<template>
  <div >
    <b-table
        v-if="listaIntegratori && listaIntegratori.length > 0"
        sticky-header
        ref="table"
        id="tbl1"
        responsive
        stacked="lg"
        striped
        hover
        :items="listaIntegratori"
        :fields="fields"
        sort-icon-left
        head-variant="light"
        class="sa-b-table"
    >
      <template v-slot:cell(dettagli)="row">
        <b-col xs="2" sm="2" md="2" lg="2">
          <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="getDettagli(row)"
                    data-toggle="tooltip" data-placement="bottom" title="Dettagli">
            <b-icon icon="search" variant="outline-secondary"/>
          </b-button>
        </b-col>
      </template>
    </b-table>
    <b-row v-else style="display: flex; justify-content: center; margin-top: 100px">
      Non sono ancora stati prescritti integratori
    </b-row>
  </div>
</template>


<script>
import Integrators from "@/services/Integrators";

export default {
  name: "associazioni",
  props:["id_paziente"],
  data(){
    return {
      listaIntegratori:[],
      fields: [
        {
          label: 'Nome',
          key: 'name',
          thStyle: "width: 25%"
        },
        {
          label: 'Data inizio',
          key: 'start_date',
          thStyle: "width: 50%"
        },
        {
          label: 'Data fine',
          key: `end_date`,
          thStyle: "width: 19%"
        },
        {
          label: 'Dettagli',
          key: "dettagli",
          thStyle: "width: 1%"
        }
      ],
    }
  },
  methods:{
    async getDettagli(row) {
      await this.$router.push(`/integratore/${row.item.integratore_id}`)
    },
    async getPatientIntegrators() {
      const response = await Integrators.getPatientIntegrators(this.id_paziente)
      if (response.status === 200) {
        this.listaIntegratori = response.data
      } else {
        this.$bvToast.toast("Errore nei dati del paziente, contattare l'assistenza", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
  },


  async mounted(){
    await this.getPatientIntegrators()
  }
}
</script>



<style scoped>

</style>
