<template>
  <div>
    <div style="display: flex; padding: 20px 0; align-items: stretch">
      <b-col style="padding: 1px">
        <div class="cardDieta">
          <b>Tipo dieta</b><br>
          <a v-if="!editing">{{ dieta.tipodieta }}</a>
          <b-form-select v-else :options="tipiDiete" v-model="nuovaDieta.tipodieta" class="editing" value-field="nome" text-field="nome"></b-form-select>
          <label v-if="editing && !nuovaDieta.tipodieta" style="font-size: 11px; color: red; margin-left:10px">Seleziona il tipo di dieta</label>
        </div>
      </b-col>
      <b-col style="padding: 1px">
        <div class="cardDieta"> <b>Data inizio</b><br>
          <a v-if="!editing">{{dieta.data_inizio}}</a>
          <b-datepicker v-else v-model="nuovaDieta.data_inizio" style="padding: 0 !important; margin-top: 8px"></b-datepicker>
          <label v-if="editing && !nuovaDieta.data_inizio" style="font-size: 11px; color: red; margin-left:10px">Seleziona la data di inizio</label>
        </div>
      </b-col>
      <b-col style="padding: 1px">
        <div class="cardDieta"> <b>Data fine</b><br>
          <a v-if="!editing">{{dieta.data_fine}}</a>
          <b-datepicker v-else v-model="nuovaDieta.data_fine"  style="padding: 0 !important; margin-top: 8px"></b-datepicker>
          <label v-if="editing && !nuovaDieta.data_fine" style="font-size: 11px; color: red; margin-left:10px">Seleziona la data di fine</label>
        </div>
      </b-col>
      <b-col style="padding: 1px" cols="4">
        <div class="cardDieta"> <b>Note</b><br>
          <a v-if="!editing">{{dieta.note}}</a>
          <b-textarea class="editing" v-model="nuovaDieta.note" v-else></b-textarea>
        </div>
      </b-col>
            <b-col v-if="!editing" style="padding: 1px">
              <div class="cardDieta" style="display: flex; ">
                <b-button variant="success" block @click="generatePDF(dieta)">Genera PDF</b-button>
              </div>
            </b-col>
      <b-col v-if="editing" style="padding: 1px">
        <div class="cardDieta" style="display: flex; justify-content: center; align-items: center">
            <b-button variant="danger" style="background-color: #D51160 !important;" @click="genera=true" :disabled="!editing || !nuovaDieta.tipodieta">
              Genera suggerimenti
            </b-button>
        </div>
      </b-col>
    </div>


    <b-tabs v-if="!editing" justified class="tabDieta">

      <b-tab v-for="index in 7" :title="getDayName(index).charAt(0).toUpperCase() + getDayName(index).slice(1)" :disabled="!getDietaGiorno(index)">
        <div v-if="getDayName(index) in dieta.pasti" style="margin-top: 60px; display: grid; grid-template-columns: 1fr 1fr; justify-items: stretch; column-gap: 60px; row-gap: 70px">
          <div v-if="getDietaGiorno(index).colazione && getDietaGiorno(index).colazione.length > 0" class="pasto" style="background-color: #F7E4BA;">
            <img src="../assets/dieta/breakfast.svg" alt="" class="image">
            <b style="text-align: center; margin: 8px 0; color: #AB7740; font-size: 20px ">Colazione</b>
            <table class="dietTable">
              <tr>
                <th style="color: #AB7740;">Alimento</th>
                <th style="color: #AB7740; text-align: right;">Quantità</th>
                <th style="color: #AB7740; text-align: right;">Calorie</th>
              </tr>
              <tr v-for="pasto in getDietaGiorno(index).colazione">
                <th>{{ pasto.nome }}</th>
                <td style="text-align: right;">{{ pasto.quantita }} {{ pasto.unita_misura}}</td>
                <!-- <td>{{ pasto.calorie_per_100gr }}</td> -->
              </tr>
            </table>
          </div>
          <div v-if="getDietaGiorno(index).spuntino_mattina && getDietaGiorno(index).spuntino_mattina.length > 0" class="pasto" style="background-color: #fcbfbf;">
            <img src="../assets/dieta/apple.svg" alt="" class="image">
            <b style="text-align: center; margin: 8px 0; color: #f85d5d; font-size: 20px ">Spuntino mattutino</b>
            <table class="dietTable">
              <tr>
                <th style="color: #f85d5d;">Alimento</th>
                <th style="color: #f85d5d; text-align: right;">Quantità</th>
                <!-- <th style="color: #f85d5d;">kcal</th> -->
              </tr>
              <tr v-for="pasto in getDietaGiorno(index).spuntino_mattina">
                <th>{{ pasto.nome }}</th>
                <td style="text-align: right;">{{ pasto.quantita }} {{ pasto.unita_misura}}</td>
                <!-- <td>{{ pasto.calorie_per_100gr }}</td> -->
              </tr>
            </table>
          </div>
          <div v-if="getDietaGiorno(index).pranzo && getDietaGiorno(index).pranzo.length > 0" class="pasto" style="background-color: #ceea99;">
            <img src="../assets/dieta/lunch-bag.svg" alt="" class="image">
            <b style="text-align: center; margin: 8px 0; color: #7da239; font-size: 20px ">Pranzo</b>
            <table class="dietTable">
              <tr>
                <th style="color: #7da239;">Alimento</th>
                <th style="color: #7da239; text-align: right;">Quantità</th>
                <!-- <th style="color: #7da239;">kcal</th> -->
              </tr>
              <tr v-for="pasto in getDietaGiorno(index).pranzo">
                <th>{{ pasto.nome }}</th>
                <td style="text-align: right;">{{ pasto.quantita }} {{ pasto.unita_misura}}</td>
                <!-- <td>{{ pasto.calorie_per_100gr }}</td> -->
              </tr>
            </table>
          </div>
          <div v-if="getDietaGiorno(index).spuntino_pomeriggio && getDietaGiorno(index).spuntino_pomeriggio.length > 0" class="pasto" style="background-color: #eabca6;">
            <img src="../assets/dieta/energy-food.svg" alt="" class="image">
            <b style="text-align: center; margin: 8px 0; color: #b26039; font-size: 20px ">Spuntino pomeridiano</b>
            <table class="dietTable">
              <tr>
                <th style="color: #b26039;">Alimento</th>
                <th style="color: #b26039; text-align: right;">Quantità</th>
                <!-- <th style="color: #b26039;">kcal</th> -->
              </tr>
              <tr v-for="pasto in getDietaGiorno(index).spuntino_pomeriggio">
                <th>{{ pasto.nome }}</th>
                <td style="text-align: right;">{{ pasto.quantita }} {{ pasto.unita_misura}}</td>
                <!-- <td>{{ pasto.calorie_per_100gr }}</td> -->
              </tr>
            </table>
          </div>
          <div v-if="getDietaGiorno(index).cena && getDietaGiorno(index).cena.length > 0" class="pasto" style="background-color: #b5daea;">
            <img src="../assets/dieta/dinner.svg" alt="" class="image">
            <b style="text-align: center; margin: 8px 0; color: #3a89ab; font-size: 20px ">Cena</b>
            <table class="dietTable">
              <tr>
                <th style="color: #3a89ab;">Alimento</th>
                <th style="color: #3a89ab; text-align: right;">Quantità</th>
                <!-- <th style="color: #3a89ab;">kcal</th> -->
              </tr>
              <tr v-for="pasto in getDietaGiorno(index).cena">
                <th>{{ pasto.nome }}</th>
                <td style="text-align: right;">{{ pasto.quantita }} {{ pasto.unita_misura}}</td>
                <!-- <td>{{ pasto.calorie_per_100gr }}</td> -->
              </tr>
            </table>
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <b-tabs v-else-if="nuovaDieta.tipodieta" justified class="tabDieta">

      <b-tab v-for="(giorno, nomeGiorno) in giorniSettimana" :key="nomeGiorno" :title="nomeGiorno.charAt(0).toUpperCase() + nomeGiorno.slice(1)">
        <div style="margin-top: 60px; display: grid; grid-template-columns: 1fr 1fr; justify-items: stretch; align-items: start; column-gap: 60px; row-gap: 70px">
          <div class="pasto" style="background-color: #F7E4BA;">
            <img src="../assets/dieta/breakfast.svg" alt="" class="image">
            <b style="text-align: center; margin: 8px 0; color: #AB7740; font-size: 20px ">Colazione</b>
            <table v-if="nuovaDieta.pasti[nomeGiorno].colazione.length > 0" class="dietTable">
              <tr>
                <th style="color: #AB7740; width:35%">Alimento</th>
                <th style="color: #AB7740; text-align: start">Quantità</th>
                <th style="color: #AB7740; text-align: start">Unità di misura</th>
                <th style="color: #AB7740; text-align: start">Calorie(kcal)</th>
                <th style="color: #AB7740;"></th>
              </tr>
              <tr v-for="(item, index) in nuovaDieta.pasti[nomeGiorno].colazione" :key="index + 'nomeGiorno' + 'colazione'">
                <th style="width:35%">
                  <b-form-input v-model="item.nome" list="my-list-id-colazione" readonly></b-form-input>
                </th>
                <td><b-input disabled v-model="item.quantita"></b-input></td>
                <td>
                  <b-form-input disabled v-model="item.unita_misura"></b-form-input>
                </td>
                <td><b-input disabled v-model="item.energia_calorie_kcal"></b-input></td>

                <td>
                  <b-button @click="removeItem(nomeGiorno, 'colazione', index)" variant="danger">
                    <b-icon-dash-circle></b-icon-dash-circle>
                  </b-button>
                </td>
              </tr>
              <tr>
                <th colspan="3" style="text-align: right;">Totale Calorie</th>
                <td>{{ calcolaTotaleCalorie(nomeGiorno, 'colazione') }}</td>
              </tr>
            </table>
            <b-button variant="success" style="margin-top: 15px" @click='selectMeal("Colazione", nomeGiorno)'>
              <b-icon-plus-circle></b-icon-plus-circle>
              {{nuovaDieta.pasti[nomeGiorno].colazione.length > 0 ? 'Modifica' : 'Aggiungi'}}

            </b-button>
          </div>
          <div class="pasto" style="background-color: #fcbfbf;">
            <img src="../assets/dieta/apple.svg" alt="" class="image">
            <b style="text-align: center; margin: 8px 0; color: #f85d5d; font-size: 20px ">Spuntino mattutino</b>
            <table v-if="nuovaDieta.pasti[nomeGiorno].spuntino_mattina.length > 0" class="dietTable">
              <tr>
                <th style="color: #f85d5d; width:35%">Alimento</th>
                <th style="color: #f85d5d; text-align: start">Quantità</th>
                <th style="color: #f85d5d; text-align: start">Unità di misura</th>
                <th style="color: #f85d5d; text-align: start">Calorie(kcal)</th>
                <th style="color: #f85d5d;"></th>
              </tr>
              <tr v-for="(item, index) in nuovaDieta.pasti[nomeGiorno].spuntino_mattina" :key="index + 'nomeGiorno' + 'colazione'">
                <th style="width:35%">
                  <b-form-input v-model="item.nome" list="my-list-id-colazione" readonly></b-form-input>
                </th>
                <td><b-input disabled v-model="item.quantita"></b-input></td>
                <td>
                  <b-form-input disabled v-model="item.unita_misura"></b-form-input>
                </td>
                <td><b-input disabled v-model="item.energia_calorie_kcal"></b-input></td>

                <td>
                  <b-button @click="removeItem(nomeGiorno, 'spuntino_mattina', index)" variant="danger">
                    <b-icon-dash-circle></b-icon-dash-circle>
                  </b-button>
                </td>
              </tr>
              <tr>
                <th colspan="3" style="text-align: right;">Totale Calorie</th>
                <td>{{ calcolaTotaleCalorie(nomeGiorno, 'spuntino_mattina') }}</td>
              </tr>
            </table>
            <b-button variant="success" style="margin-top: 15px" @click='selectMeal("Spuntino Mattina", nomeGiorno)'>
              <b-icon-plus-circle></b-icon-plus-circle>
              {{nuovaDieta.pasti[nomeGiorno].spuntino_mattina.length > 0 ? 'Modifica' : 'Aggiungi'}}

            </b-button>
          </div>
          <div class="pasto" style="background-color: #ceea99;">
            <img src="../assets/dieta/lunch-bag.svg" alt="" class="image">
            <b style="text-align: center; margin: 8px 0; color: #7da239; font-size: 20px ">Pranzo</b>
            <table v-if="nuovaDieta.pasti[nomeGiorno].pranzo.length > 0" class="dietTable">
              <tr>
                <th style="color: #7da239; width:35%">Alimento</th>
                <th style="color: #7da239; text-align: start">Quantità</th>
                <th style="color: #7da239; text-align: start">Unità di misura</th>
                <th style="color: #7da239; text-align: start">Calorie(kcal)</th>
                <th style="color: #7da239;"></th>
              </tr>
              <tr v-for="(item, index) in nuovaDieta.pasti[nomeGiorno].pranzo" :key="index + 'nomeGiorno' + 'colazione'">
                <th style="width:35%">
                  <b-form-input v-model="item.nome" list="my-list-id-pranzo" readonly></b-form-input>
                </th>
                <td><b-input disabled v-model="item.quantita"></b-input></td>
                <td>
                  <b-form-input disabled v-model="item.unita_misura"></b-form-input>
                </td>
                <td><b-input disabled v-model="item.energia_calorie_kcal"></b-input></td>

                <td>
                  <b-button @click="removeItem(nomeGiorno, 'pranzo', index)" variant="danger">
                    <b-icon-dash-circle></b-icon-dash-circle>
                  </b-button>
                </td>
              </tr>
              <tr>
                <th colspan="3" style="text-align: right;">Totale Calorie</th>
                <td>{{ calcolaTotaleCalorie(nomeGiorno, 'pranzo') }}</td>
              </tr>
            </table>
            <b-button variant="success" style="margin-top: 15px" @click='selectMeal("Pranzo", nomeGiorno)'>
              <b-icon-plus-circle></b-icon-plus-circle>
              {{nuovaDieta.pasti[nomeGiorno].pranzo.length > 0 ? 'Modifica' : 'Aggiungi'}}

            </b-button>
          </div>
          <div class="pasto" style="background-color: #eabca6;">
            <img src="../assets/dieta/energy-food.svg" alt="" class="image">
            <b style="text-align: center; margin: 8px 0; color: #b26039; font-size: 20px ">Spuntino pomeridiano</b>
            <table v-if="nuovaDieta.pasti[nomeGiorno].spuntino_pomeriggio.length > 0" class="dietTable">
              <tr>
                <th style="color: #b26039; width:35%">Alimento</th>
                <th style="color: #b26039; text-align: start">Quantità</th>
                <th style="color: #b26039; text-align: start">Unità di misura</th>
                <th style="color: #b26039; text-align: start">Calorie(kcal)</th>
                <th style="color: #b26039;"></th>
              </tr>
              <tr v-for="(item, index) in nuovaDieta.pasti[nomeGiorno].spuntino_pomeriggio" :key="index + 'nomeGiorno' + 'colazione'">
                <th style="width:35%">
                  <b-form-input v-model="item.nome" list="my-list-id-spuntino-pomeriggio" readonly></b-form-input>
                </th>
                <td><b-input disabled v-model="item.quantita"></b-input></td>
                <td>
                  <b-form-input disabled v-model="item.unita_misura"></b-form-input>
                </td>
                <td><b-input disabled v-model="item.energia_calorie_kcal"></b-input></td>

                <td>
                  <b-button @click="removeItem(nomeGiorno, 'spuntino_pomeriggio', index)" variant="danger">
                    <b-icon-dash-circle></b-icon-dash-circle>
                  </b-button>
                </td>
              </tr>
              <tr>
                <th colspan="3" style="text-align: right;">Totale Calorie</th>
                <td>{{ calcolaTotaleCalorie(nomeGiorno, 'spuntino_pomeriggio') }}</td>
              </tr>
            </table>
            <b-button variant="success" style="margin-top: 15px" @click='selectMeal("Spuntino Pomeriggio", nomeGiorno)'>
              <b-icon-plus-circle></b-icon-plus-circle>
              {{nuovaDieta.pasti[nomeGiorno].spuntino_pomeriggio.length > 0 ? 'Modifica' : 'Aggiungi'}}

            </b-button>
          </div>
          <div class="pasto" style="background-color: #b5daea;">
            <img src="../assets/dieta/dinner.svg" alt="" class="image">
            <b style="text-align: center; margin: 8px 0; color: #3a89ab; font-size: 20px ">Cena</b>
            <table v-if="nuovaDieta.pasti[nomeGiorno].cena.length > 0" class="dietTable">
              <tr>
                <th style="color: #3a89ab; width:35%">Alimento</th>
                <th style="color: #3a89ab; text-align: start">Quantità</th>
                <th style="color: #3a89ab; text-align: start">Unità di misura</th>
                <th style="color: #3a89ab; text-align: start">Calorie(kcal)</th>
                <th style="color: #3a89ab;"></th>
              </tr>
              <tr v-for="(item, index) in nuovaDieta.pasti[nomeGiorno].cena" :key="index + 'nomeGiorno' + 'colazione'">
                <th style="width:35%">
                  <b-form-input v-model="item.nome" list="my-list-id-cena" readonly></b-form-input>
                </th>
                <td><b-input disabled v-model="item.quantita"></b-input></td>
                <td>
                  <b-form-input disabled v-model="item.unita_misura"></b-form-input>
                </td>
                <td><b-input disabled v-model="item.energia_calorie_kcal"></b-input></td>
                <td>
                  <b-button @click="removeItem(nomeGiorno, 'cena', index)" variant="danger">
                    <b-icon-dash-circle></b-icon-dash-circle>
                  </b-button>
                </td>
              </tr>
              <tr>
                <th colspan="3" style="text-align: right;">Totale Calorie</th>
                <td>{{ calcolaTotaleCalorie(nomeGiorno, 'cena') }}</td>
              </tr>
            </table>
            <b-button variant="success" style="margin-top: 15px" @click='selectMeal("Cena", nomeGiorno)'>
              <b-icon-plus-circle></b-icon-plus-circle>
              {{nuovaDieta.pasti[nomeGiorno].cena.length > 0 ? 'Modifica' : 'Aggiungi'}}
            </b-button>
          </div>
        </div>
      </b-tab>

    </b-tabs>
    <b-row v-if="editing" style="display: flex;justify-content: flex-end; margin-top: 20px">
      <b-col cols="3" v-if="editing && nuovaDieta.tipodieta && nuovaDieta.data_inizio && nuovaDieta.data_fine && checkPasti(nuovaDieta)">
        <b-button block @click="generatePDF(nuovaDieta)"> Genera PDF</b-button>
      </b-col>
      <b-col cols="3">
        <b-button @click="$emit('annulla')" block> Annulla </b-button>
      </b-col>
      <b-col cols="3">
        <b-button variant="success" :disabled="!editing || !nuovaDieta.tipodieta || !nuovaDieta.data_inizio || !nuovaDieta.data_fine || !checkPasti(nuovaDieta)" block v-b-modal.my-modal5> Salva dieta </b-button>
      </b-col>
    </b-row>
    <b-modal ok-title="Salva" cancel-title="Annulla" @ok="saveData" ref="my-modal5" id="my-modal5" >
      <label>Sei sicuro di voler salvare i dati?</label>
    </b-modal>

    <componi-pasto v-if="dialog" :pasto="pasto" :tipo-dieta="nuovaDieta.tipodieta" :items="items" :unita-misura="unita_misura" @annulla="dialog=false" @salva="saveMeal"></componi-pasto>

    <generatore-dieta v-if="genera" :paziente_id="paziente_id" @cancel="genera = false" :tipo_dieta="nuovaDieta.tipodieta" @generate="generate"></generatore-dieta>

  </div>
</template>

<script>
import Diete from "@/services/Diete";
import {formatDate} from "@/utils/formatDate";
import ComponiPasto from "@/components/dieta/componiPasto.vue";
import GeneratoreDieta from "@/components/dieta/generatoreDieta.vue";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
export default {
  name: "dieta",
  components: {GeneratoreDieta, ComponiPasto},
  props: ["editing","dieta"],
  emits: ["annulla","save"],
  data() {
    return {
      tipiDiete: [],
      paziente_id: '',
      unita_misura:[],
      nuovaDieta: {
        data_inizio: '',
        data_fine: '',
        tipodieta: '',
        note: '',
        medico: '',
        pasti : {
          lunedi: {
            colazione: [],
            spuntino_mattina: [],
            pranzo: [],
            spuntino_pomeriggio: [],
            cena: []
          },
          martedi: {
            colazione: [],
            spuntino_mattina: [],
            pranzo: [],
            spuntino_pomeriggio: [],
            cena: []
          },
          mercoledi: {
            colazione: [],
            spuntino_mattina: [],
            pranzo: [],
            spuntino_pomeriggio: [],
            cena: []
          },
          giovedi: {
            colazione: [],
            spuntino_mattina: [],
            pranzo: [],
            spuntino_pomeriggio: [],
            cena: []
          },
          venerdi: {
            colazione: [],
            spuntino_mattina: [],
            pranzo: [],
            spuntino_pomeriggio: [],
            cena: []
          },
          sabato: {
            colazione: [],
            spuntino_mattina: [],
            pranzo: [],
            spuntino_pomeriggio: [],
            cena: []
          },
          domenica: {
            colazione: [],
            spuntino_mattina: [],
            pranzo: [],
            spuntino_pomeriggio: [],
            cena: []
          }
        },
        totale_calorie: 2000
      },
      giorniSettimana: {
        lunedi : {
          colazione: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          spuntino_mattina: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          pranzo: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          spuntino_pomeriggio: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          cena: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }]
        },
        martedi :    {
          colazione: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          spuntino_mattina: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          pranzo: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          spuntino_pomeriggio: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          cena: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }]
        },
        mercoledi :     {
          colazione: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          spuntino_mattina: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          pranzo: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          spuntino_pomeriggio: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          cena: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }]
        },
        giovedi :     {
          colazione: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          spuntino_mattina: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          pranzo: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          spuntino_pomeriggio: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          cena: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }]
        },
        venerdi :     {
          colazione: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          spuntino_mattina: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          pranzo: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          spuntino_pomeriggio: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          cena: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }]
        },
        sabato : {
          colazione: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          spuntino_mattina: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          pranzo: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          spuntino_pomeriggio: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          cena: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }]
        },
        domenica : {
          colazione: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          spuntino_mattina: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          pranzo: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          spuntino_pomeriggio: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }],
          cena: [{
            nome: '',
            quantita: "",
            unita_misura: ""
          }]
        },
      },
      dialog:false,
      genera:false,
      searchFilter: "",
      sizes: [],
      copySizes: [],
      cart: [],
      fields: [
        {
          label: 'Nome',
          key: 'Nome'
        },
        {
          label: 'Calorie',
          key: 'Energia_calorie_kcal'
        },
        {
          label: 'Acidi grassi saturi (g)',
          key: 'Acidi_grassi_saturi_g'
        },
        {
          label: 'Acidi grassi monoinsaturi (g)',
          key: 'Acidi_grassi_monoinsaturi_g'
        },
        {
          label: 'Colesterolo (mg)',
          key: 'Colesterolo_mg'
        },
        {
          label: 'Zuccheri (g)',
          key: 'Zuccheri_g'
        }
      ],
      fieldsCart: [
        {
          label: 'Nome',
          key: 'Nome'
        },
        {
          label: 'Calorie',
          key: 'Energia_calorie_kcal'
        }
      ],
      timeoutId: 0,
      pasto:'',
      giorno:'',
      items: []
    }
  },
  mounted: async function () {
    await this.getTipoDiete()
    await this.getUnitaMisura()
    this.paziente_id = this.$router.currentRoute.params.id

  },
  methods: {
    formatDate,
    getDayName(index) {
      switch(index) {
        case 1:
          return "lunedi";
        case 2:
          return "martedi";
        case 3:
          return "mercoledi";
        case 4:
          return "giovedi";
        case 5:
          return "venerdi";
        case 6:
          return "sabato";
        case 7:
          return "domenica";
      }
    },
    getDietaGiorno(index) {
      return this.dieta.pasti[this.getDayName(index)];
    },
    removeItem(giorno, pasto, index) {
      this.nuovaDieta.pasti[giorno][pasto].splice(index, 1);
    },
    calcolaTotaleCalorie(nomeGiorno, tipoPasto) {
      const pastiItems = this.nuovaDieta.pasti[nomeGiorno][tipoPasto];
      const totale = pastiItems.reduce((somma, item) => {
        return somma + (parseFloat(item.energia_calorie_kcal) || 0);
      }, 0);
      return totale.toFixed(2);
    },
    async saveData() {
      const dieta_2 = {
        tipodieta: this.nuovaDieta.tipodieta,
        data_inizio: this.nuovaDieta.data_inizio,
        data_fine: this.nuovaDieta.data_fine,
        note: this.nuovaDieta.note,
        paziente_id : this.$router.currentRoute.params.id
      }

      const response = await Diete.setDieta(dieta_2)
      const structuredArray = [];

      if (response.status === 200){
        const dieta_id = response.data.id
        for (const giorno in this.nuovaDieta.pasti) {
          const colazioni = this.nuovaDieta.pasti[giorno].colazione.map((pasto) => ({
            dieta_id: dieta_id,
            pasto: 'colazione',
            nome: pasto.nome,
            calorie_per_100g: pasto.calorie_per_100gr,
            quantita: pasto.quantita,
            unita_misura: pasto.unita_misura,
            giorno_settimana: giorno
          }));
          const spuntiniMattina = this.nuovaDieta.pasti[giorno].spuntino_mattina.map((pasto) => ({
            dieta_id: dieta_id,
            pasto: 'spuntino_mattina',
            nome: pasto.nome,
            calorie_per_100g: pasto.calorie_per_100gr,
            quantita: pasto.quantita,
            unita_misura: pasto.unita_misura,
            giorno_settimana: giorno
          }));
          const pranzi = this.nuovaDieta.pasti[giorno].pranzo.map((pasto) => ({
            dieta_id: dieta_id,
            pasto: 'pranzo',
            nome: pasto.nome,
            calorie_per_100g: pasto.calorie_per_100gr,
            quantita: pasto.quantita,
            unita_misura: pasto.unita_misura,
            giorno_settimana: giorno
          }));
          const spuntiniPomeriggio = this.nuovaDieta.pasti[giorno].spuntino_pomeriggio.map((pasto) => ({
            dieta_id: dieta_id,
            pasto: 'spuntino_pomeriggio',
            nome: pasto.nome,
            calorie_per_100g: pasto.calorie_per_100gr,
            quantita: pasto.quantita,
            unita_misura: pasto.unita_misura,
            giorno_settimana: giorno
          }));

          const cene = this.nuovaDieta.pasti[giorno].cena.map((pasto) => ({
            dieta_id: dieta_id,
            pasto: 'cena',
            nome: pasto.nome,
            calorie_per_100g: pasto.calorie_per_100gr,
            quantita: pasto.quantita,
            unita_misura: pasto.unita_misura,
            giorno_settimana: giorno
          }));

          structuredArray.push(...colazioni, ...spuntiniMattina, ...pranzi, ...spuntiniPomeriggio, ...cene);
        }

        const arrayDieta = structuredArray.filter(item => item.nome !== "" && item.quantita !== "" );

        const arrayDietaModificato = arrayDieta.map(item => ({
          ...item,
          unita_misura: item.unita_misura !== "" ? item.unita_misura : "non specificata"
        }));

        const response_2 = await Diete.setPastiDieta(arrayDietaModificato)

        if (response_2.status === 200) {
          this.$bvToast.toast("Parametri aggiunti con successo.", {
            title: `Operazione riuscita`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'success',
            solid: true,
            noCloseButton: true
          })
          this.$refs['my-modal5'].hide()
          this.$emit('save')
        } else{
          //TODO cancella la dieta
          this.$bvToast.toast("Errore nel salvataggio. Ripetere l'operazione", {
            title: `Attenzione`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            noCloseButton: true
          })
        }
      }
    },
    checkPasti(dieta) {
      const giorni = Object.keys(dieta.pasti);

      for (let giorno of giorni) {
        const pastiDelGiorno = dieta.pasti[giorno];
        const tipiDiPasti = Object.keys(pastiDelGiorno);

        for (let tipoPasto of tipiDiPasti) {
          if (pastiDelGiorno[tipoPasto].length > 0) {
            return true;
          }
        }
      }
      return false;
    },
    async selectMeal(pasto, giorno){
      this.pasto = pasto
      this.giorno = giorno

      if(pasto === "Spuntino Mattina"){
        pasto = "spuntino_mattina"
      } else if(pasto === "Spuntino Pomeriggio"){
        pasto = "spuntino_pomeriggio"
      } else {
        pasto = pasto.toLowerCase()
      }
      this.items = this.nuovaDieta.pasti[giorno][pasto]
      this.dialog = true
    },
    async saveMeal(pasto, array){
      if(pasto === "Spuntino Mattina"){
        pasto = "spuntino_mattina"
      } else if(pasto === "Spuntino Pomeriggio"){
        pasto = "spuntino_pomeriggio"
      } else {
        pasto = pasto.toLowerCase()
      }
      this.nuovaDieta.pasti[this.giorno][pasto] = array.map(x => ({
        nome: x.Nome,
        quantita: x.quantita,
        unita_misura: x.unita_misura,
        energia_calorie_kcal: this.calcolaCalorie(x.quantita, x.energia_calorie_kcal_riferimento)
      }))
      this.dialog = false
    },
    async getTipoDiete() {
      const response = await Diete.getTipoDieteList()
      if(response.status === 200){
        this.tipiDiete = response.data
      }
    },
    calcolaCalorie(quantita, calorie_100){
      console.log(quantita, calorie_100)
      return quantita * calorie_100 / 100

    },
    generate(pasti) {
      this.genera = false
      this.nuovaDieta.pasti = pasti
    },
    async getUnitaMisura() {
      const response = await Diete.getUnitaMisura()
      if(response.status === 200){
        this.unita_misura = response.data
      }
    },
    generatePDF(dieta) {
      const doc = new jsPDF();

      // Imposta il titolo del documento
      doc.setFontSize(18);
      doc.text(`Tipo Dieta : ${dieta.tipodieta}`, 14, 22);
      doc.setFontSize(12);
      if (dieta.data_inizio && dieta.data_fine) {
        doc.text(`( Dal : ${dieta.data_inizio} al : ${dieta.data_fine})`, 14, 32);
      }

      const giorni = Object.keys(dieta.pasti);
      let pageSize = 300
      let y = 40;

      giorni.forEach((giorno, index) => {

        if(index > 0 && y !== 30) {
          doc.addPage()
          pageSize = 280
          y = 30
        }

        doc.setFontSize(16);
        doc.text(giorno.charAt(0).toUpperCase() + giorno.slice(1), 14, y);
        y += 10;
        pageSize = pageSize - y

        const pastiGiorno = dieta.pasti[giorno];
        const pastiKeys = Object.keys(pastiGiorno);

        pastiKeys.forEach((pasto) => {
          doc.setFontSize(14);
          const pasto_tmp = pasto.charAt(0).toUpperCase() + pasto.slice(1)
          doc.text(pasto_tmp.replace(/_/g, ' '), 14, y);

          y += 6;
          pageSize -= 6
          if (pageSize < 20){
            doc.addPage()
            pageSize = 280
            y = 30
          }

          const alimenti = pastiGiorno[pasto];
          alimenti.forEach((alimento) => {
            doc.setFontSize(12);
            doc.text(`${alimento.nome} - ${alimento.quantita} ${alimento.unita_misura}`, 20, y);
            y += 6;

            pageSize -= 6
            if (pageSize < 20){
              doc.addPage()
              pageSize = 280
              y = 30
            }
          });
          y += 4; // Spazio extra tra i pasti
          pageSize -= 4
          if (pageSize < 20){
            doc.addPage()
            pageSize = 280
            y = 30
          }
        });
      });

      // Salva il PDF
      doc.save('dieta.pdf');
    }
  }
}
</script>

<style scoped>

.cardDieta{
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.pasto{
  border-radius: 7px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.dietTable th {
  padding: 4px 10px;
}
.dietTable td{
  text-align: center;
  padding: 4px 10px;

}
.image {
  width: 90px;
  display: block;
  align-self: center;
  margin-top: -65px
}
.dietTable > tr:first-child > th:not(:first-child){
  text-align: center;
}
.editing {
  margin-top: 10px !important;
  color: black;
}
.dietTable{
  width: 100%;
}
.form-control{
  padding: 0;
}


</style>
