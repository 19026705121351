<template>
  <div>
    <b-row>
      <b-col style="padding-top: 15px">
        <b-form-group label="Data misurazione:">
          <b-form-input v-model="quadro_proteico.create_date" type="date" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Albumina (g/dL)">
          <b-form-input v-model="quadro_proteico.albumina" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Alfa 1">
          <b-form-input v-model="quadro_proteico.a1" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Alfa 2">
          <b-form-input v-model="quadro_proteico.a2" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Beta Globuline (g/dL)">
          <b-form-input v-model="quadro_proteico.betaglobuline" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Gamma Globuline (g/dL)">
          <b-form-input v-model="quadro_proteico.gammaglobuline" ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col style="padding-top: 15px">
        <b-form-group label="Insulinemia (μU/mL)">
          <b-form-input v-model="quadro_proteico.insulinemia" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Homa Score">
          <b-form-input v-model="quadro_proteico.homa_score" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Colesterolo Totale (mg/dL)">
          <b-form-input v-model="quadro_proteico.colesterolo_totale" ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col style="padding-top: 15px">
        <b-form-group label="Colesterolo HDL (mg/dL)">
          <b-form-input v-model="quadro_proteico.hdl" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Colesterolo LDL (mg/dL)">
          <b-form-input v-model="quadro_proteico.ldl" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Proteina C reattiva (mg/L)">
          <b-form-input v-model="quadro_proteico.hs_pcr" ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col style="padding-top: 15px">
        <b-form-group label="VES (mm/h)">
          <b-form-input v-model="quadro_proteico.ves" ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col style="padding-top: 15px">
        <b-form-group label="TAS (mmHg)">
          <b-form-input v-model="quadro_proteico.tas" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Cortisolo (μg/dL)">
          <b-form-input v-model="quadro_proteico.cortisolo " ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="ACTH (pg/mL)">
          <b-form-input v-model="quadro_proteico.acth" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Acido Urico (mg/dL)">
          <b-form-input v-model="quadro_proteico.acido_urico " ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col style="padding-top: 15px">
        <b-form-group label="Omocisteina (μmol/L)">
          <b-form-input v-model="quadro_proteico.omocisteina" ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col style="padding-top: 15px">
        <b-form-group label="Emoglobina Glicata (%)">
          <b-form-input v-model="quadro_proteico.emoglobina_glicata" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Lipoproteina A (mg/dL)">
          <b-form-input v-model="quadro_proteico.lipoproteina_a" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Troponine (ng/mL)">
          <b-form-input v-model="quadro_proteico.troponine" ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col style="padding-top: 15px">
        <b-form-group label="Telomerasi">
          <b-form-input v-model="quadro_proteico.telomerasi" ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col style="padding-top: 15px">
        <b-form-group label="Interleuchina 1 (pg/mL)">
          <b-form-input v-model="quadro_proteico.il1" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Interleuchina 6 (pg/mL)">
          <b-form-input v-model="quadro_proteico.il6" ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col style="padding-top: 15px">
        <b-form-group label="Interleuchina 8 (pg/mL)">
          <b-form-input v-model="quadro_proteico.il8" ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col style="padding-top: 15px">
        <b-form-group label="Interleuchina 17 (pg/mL)">
          <b-form-input v-model="quadro_proteico.il17" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Fattore 1 - CXCL12">
          <b-form-input v-model="quadro_proteico.cxcl12" ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col style="padding-top: 15px">
        <b-form-group label="Stress Ossidativo">
          <b-form-input v-model="quadro_proteico.stress_ossidativo" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Perossidazione Lipidica">
          <b-form-input v-model="quadro_proteico.perossidazione_lipidica" ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col style="padding-top: 15px">
        <b-form-group label="MDA - Malondialdeide (nmol/mL)">
          <b-form-input v-model="quadro_proteico.mda" ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col style="padding-top: 15px" cols="2">
        <b-form-group label="HNA4">
          <b-form-input v-model="quadro_proteico.hna4" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px" cols="2">
        <b-form-group label="Leucotrieni">
          <b-form-input v-model="quadro_proteico.leucotrieni" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px" cols="2">
        <b-form-group label="Prostaglandine E2 (pg/mL)">
          <b-form-input v-model="quadro_proteico.prostaglandine_e2" ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row style="display: flex;justify-content: flex-end; margin-bottom: 20px">
      <b-col cols="3">
        <b-button @click="$emit('annulla')" block> Annulla </b-button>
      </b-col>
      <b-col cols="3">
        <b-button block v-b-modal.my-modal1> Salva misurazione </b-button>
      </b-col>
    </b-row>

    <b-modal ok-title="Salva" cancel-title="Annulla" @ok="saveData" ref="my-modal1" id="my-modal1" >
      <label>Sei sicuro di voler salvare i dati?</label>
    </b-modal>
  </div>

</template>

<script>
import ParametriMedici from "@/services/ParametriMedici";
import {formatDateInit} from "@/utils/formatDate";
import {checkDateError} from "@/utils/checkDateError";

export default {
  name: "quadroProteico",
  props:["idPaziente", 'dateArray'],
  emits:['annulla', 'save'],
  data() {
    return {
      quadro_proteico:{
        create_date : formatDateInit()
      }
    }
  },
  methods:{
    async saveData() {
      const body = {
        paziente_id: this.idPaziente,
        ...this.quadro_proteico
      }
      if(!checkDateError(this.quadro_proteico.create_date, this.dateArray)) {
        const response = await ParametriMedici.setQuadroProteico(body)
        if (response.status === 200) {
          this.$bvToast.toast("Quadro Proteico aggiunto con successo.", {
            title: `Operazione riuscita`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'success',
            solid: true,
            noCloseButton: true
          })
          this.$refs['my-modal1'].hide()
          this.$emit('save')
        } else {
          this.$bvToast.toast("Impossibile salvare i dati, contattare l'assistenza", {
            title: `Attenzione`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            noCloseButton: true
          })
        }
      } else {
        this.$bvToast.toast('Non è possibile inserire un\'altra misurazione per il giorno selezionato', {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
