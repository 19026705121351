import vue from "vue";
import VueRouter from "vue-router";
import login from "@/components/login.vue";
import home from "@/components/home";
import dettaglio from "@/components/dettaglio";
import addComposizione from "@/components/addComposizione";
import dosaggio from "@/components/dosaggio";
import main from "@/components/main";
import menu from "@/components/menu";
import addMedico from "@/components/addMedico";
import integratoriList from "@/components/integratoriList";
import addIntegratore from "@/components/addIntegratore";
import dettaglioIntegratore from "@/components/dettaglioIntegratore";
import listaMedici from "@/components/listaMedici";
import listaPazienti from "@/components/listaPazienti.vue";
import addPaziente from "@/components/addPaziente.vue";
import dettaglioPaziente from "@/components/dettaglioPaziente.vue";
import dashboard from "@/components/dashboard.vue";

vue.use(VueRouter);


let children= [
    {
        path: '/menu',
        name: 'menu',
        component: menu
    },
    {
        path: '/listaMedici',
        name: 'listamedici',
        component: listaMedici
    },
    {
        path: '/pazienti',
        name: 'listapazienti',
        component: listaPazienti
    },
    {
        path: '/dashboard/:id',
        name: 'dashboard',
        component: dashboard
    },
    {
        path: '/arruolamentoPaziente',
        name: 'arruolamento',
        component: addPaziente
    },{
        path: '/pazienti/:id',
        name: 'dettaglioPaziente',
        component: dettaglioPaziente
    },
    {
        path: '/addmedico',
        name: 'addmedico',
        component: addMedico
    },
    {
        path: '/integratori',
        name: 'integratori',
        component: integratoriList
    },
    {
        path: '/integratore/:id',
        name: 'integratore',
        component: dettaglioIntegratore
    },
    {
      path: '/componiintegratore',
        name: 'componiintegratore',
        component: addIntegratore
    },
    {
        path: '/dettaglio/:id',
        name: 'dettaglio',
        component: dettaglio
    },
    {
        path: '/dettaglio/addcomposizione/:id/:idCombination?',
        name: 'addComposizione',
        component: addComposizione
    },
    {
        path: '/dettaglio/addcomposizione/dosaggio/:id/:elementi/:idCombination?',
        name: 'dosaggio',
        component: dosaggio
    }
]

const routes = [
    {
        path: '/',
        name: 'login',
        component: login,
    },
    {
        path: '/main',
        name: 'main',
        component: main,
        children: children
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})


router.beforeEach((to,from,next) => {
    if(!sessionStorage.getItem("token") && to.name !== 'login'){
        next({
            path: "/"
        });
    }
    else {
        next()
    }
})

export default router
