import { render, staticRenderFns } from "./addComposizione.vue?vue&type=template&id=ecea6484&scoped=true&"
import script from "./addComposizione.vue?vue&type=script&lang=js&"
export * from "./addComposizione.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecea6484",
  null
  
)

export default component.exports