<template>
  <div>
    <b-row>
      <b-col style="padding-top: 15px">
        <b-form-group label="Data misurazione:">
          <b-form-input v-model="sindrome_metabolica.create_date" type="date" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Circonferenza vita (cm):">
          <b-form-input type="number" v-model="sindrome_metabolica.circonferenza_vita"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Colesterolo HDL (mg/dL)">
          <b-form-input type="number" v-model="sindrome_metabolica.colesterolo_hdl" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px">
        <b-form-group label="Glicemia (mg/dL)">
          <b-form-input type="number" v-model="sindrome_metabolica.glicemia" ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col style="padding-top: 15px" cols="3">
        <b-form-group label="Trigliceridi (mg/dL)">
          <b-form-input type="number" v-model="sindrome_metabolica.trigliceridi" ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px" cols="3">
        <b-form-group label="Pressione arteriosa sistolica (mmHg):">
          <b-form-input type="number" v-model="sindrome_metabolica.pressione_sistolica"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col style="padding-top: 15px" cols="3">
        <b-form-group label="Pressione arteriosa diastolica (mmHg):">
          <b-form-input type="number" v-model="sindrome_metabolica.pressione_diastolica"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row style="display: flex;justify-content: flex-end; margin-top: 30px; margin-bottom: 20px">
      <b-col cols="3">
        <b-button @click="$emit('annulla')" block> Annulla </b-button>
      </b-col>
      <b-col cols="3">
        <b-button block v-b-modal.my-modal4> Salva misurazione </b-button>
      </b-col>
    </b-row>


    <b-modal ok-title="Salva" cancel-title="Annulla" @ok="saveData" ref="my-modal4" id="my-modal4" >
      <label>Sei sicuro di voler salvare i dati?</label>
    </b-modal>
  </div>

</template>

<script>
import ParametriMedici from "@/services/ParametriMedici";
import {formatDateInit} from "@/utils/formatDate";
import {checkDateError} from "@/utils/checkDateError";

export default {
  name: "sindromeMetabolica",
  props:["idPaziente", 'dateArray'],
  emits:['annulla', 'save'],
  data() {
    return {
      sindrome_metabolica:{
        create_date : formatDateInit()
      }
    }
  },
  methods:{
    async saveData() {
      const body = {
        paziente_id: this.idPaziente,
        glicemia : Number(this.sindrome_metabolica.glicemia),
        colesterolo_hdl : Number(this.sindrome_metabolica.colesterolo_hdl),
        pressione_sistolica : Number(this.sindrome_metabolica.pressione_sistolica),
        pressione_diastolica : Number(this.sindrome_metabolica.pressione_diastolica),
        trigliceridi : Number(this.sindrome_metabolica.trigliceridi),
        circonferenza_vita : Number(this.sindrome_metabolica.circonferenza_vita),
        create_date : this.sindrome_metabolica.create_date
      }
      if(!checkDateError(this.sindrome_metabolica.create_date, this.dateArray)) {

        const response = await ParametriMedici.setSindrome(body)
      if (response.status === 200) {
        this.$bvToast.toast("Parametri aggiunti con successo.", {
          title: `Operazione riuscita`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          noCloseButton: true
        })
        this.$refs['my-modal4'].hide()
        this.$emit('save')
      } else{
        this.$bvToast.toast("Si è verificato un errore nel salvataggio. Riprovare più tardi.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    }else {
      this.$bvToast.toast('Non è possibile inserire un\'altra misurazione per il giorno selezionato', {
        title: `Attenzione`,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000,
        variant: 'danger',
        solid: true,
        noCloseButton: true
      })
    }
    }
  }
}
</script>

<style scoped>

</style>
