<template>
      <b-row style="height: 80%; margin: 0;">
        <b-col cols="6">
          <div class="card_pic" @click="onPazienti" style="align-self: center">
            <p class="text-center"><img alt="nuovo utente" src="../assets/iconapaziente.png" class="h-150px"/>
            </p>
            <p class="text-center">Gestione Pazienti</p>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="card_pic" @click="onIntegratori">
            <p class="text-center"><img alt="nuovo utente" src="../assets/integratore.png" class="h-150px"/>
            </p>
            <p class="text-center">Gestione integratori</p>
          </div>
        </b-col>
        <b-col v-if="showAll" cols="6">
          <div class="card_pic" @click="onAggiungiMedico" >
            <p class="text-center"><img alt="nuovo utente" src="../assets/medico.png" class="h-150px"/>
            </p>
            <p class="text-center">Gestione formulatori</p>
          </div>
        </b-col>

<!--        <b-col cols="6">-->
<!--          <div class="card_pic">-->
<!--            <p class="text-center"><img alt="nuovo utente" src="../assets/gruppopazienti.png" class="h-150px"/>-->
<!--            </p>-->
<!--            <p class="text-center">Gestione Gruppi Pazienti</p>-->
<!--          </div>-->
<!--        </b-col>-->
      </b-row>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      showAll:false
    }
  },
  computed: {},
  mounted: function () {
    if((sessionStorage.getItem('isAdmin') === "true")){
      this.showAll = true
      // this.$router.push('/integratori')
    }
  },
  methods: {
    onAggiungiMedico() {
      this.$router.push("/listaMedici");
    },
    onIntegratori() {
      this.$router.push("/integratori");
    },
    onPazienti() {
      this.$router.push("/pazienti");
    },
  },
};
</script>

<style>
.col-6{
  display: flex;
  max-width: 50%;
  justify-content: center;
  align-items: center;
}
</style>
