import BaseService from "./BaseService";

export default class Doctors extends BaseService {

    static async getDoctors(){
        return await this.perform({
            url: '/getDoctors',
            method: 'GET'
        })
    }

    static async deleteDoctors(id){
        return await this.perform({
            url: '/deleteDoctor',
            method: 'POST',
            body: {id: id}
        })
    }

    static async resetPwd(id, pwd){
        return await this.perform({
            url: '/resetPassword',
            method: 'POST',
            body: {id: id, password: pwd}
        })
    }
}
