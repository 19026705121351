import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueCookie from "vue-cookie"
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import router from "./router/router.js";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import authService from './oidcAuth.js'


Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueCookie)
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false

Vue.prototype.$oidc = authService;


new Vue({router, render: h => h(App)}).$mount('#app')
