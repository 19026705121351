<template>
  <b-tabs justified v-model="tabIndex">
    <b-tab title="Parametri Fisici" active>
      <b-container fluid style="padding: 20px;">
        <b-row style="justify-content: space-between">
          <b-col v-if="!nuovo" block @click="nuovo = true" cols="2">
            <b-button block>Aggiungi misurazione</b-button>
          </b-col>
          <b-col v-if="parametriFisiciArray.length!==0 && !nuovo" cols="5" style="display: flex; justify-content: flex-end; align-items: center">
            <a style="margin-right: 5px">Seleziona la data:</a>
            <b-select style="width: 50%;" :value="parametriFisici.create_date" :options="arrayDate3" text-field="create_date" value-field="create_date" @change="changePhysicalData"></b-select>
          </b-col>
        </b-row>
        <b-row v-if="parametriFisiciArray.length!==0 && !nuovo" style="padding: 20px 0">
          <b-col cols="3">
            <b-list-group>
              <b-list-group-item>
                <b>Rapporto vita fianchi:</b> {{ parametriFisici.rapporto_vita_fianchi }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="3">
            <b-list-group>
              <b-list-group-item>
                <b>Frequenza cardiaca (bpm):</b> {{ parametriFisici.frequenza_cardiaca }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="3">
            <b-list-group>
              <b-list-group-item>
                <b>Indice di Massa Corporea:</b> {{ parametriFisici.body_mass_index }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="3">
            <b-list-group>
              <b-list-group-item>
                <b>EORTC QLQ-C30:</b> {{ parametriFisici.qlqC30 }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
        <b-row style="padding: 0 15px"  v-else-if="nuovo">
          <parametri-fisici style="width: 100%;justify-content: space-around" :id-paziente="this.idPaziente" :date-array="arrayDate3" @save="savePP" @annulla="nuovo=false"></parametri-fisici>
        </b-row>
        <b-row v-else style="display: flex; justify-content: center; margin-top: 100px">
          Non sono presenti dati riguardanti i parametri fisici
        </b-row>
      </b-container>
    </b-tab>

    <b-tab title="Aspetti Fisiologici">
      <b-container fluid style="padding: 20px;">
        <b-row style="justify-content: space-between">
          <b-col v-if="!nuovo" block @click="nuovo = true" cols="2">
            <b-button block>Aggiungi misurazione</b-button>
          </b-col>
          <b-col v-if="aspettiFisiologiciArray.length!==0 && !nuovo" cols="5" style="display: flex; justify-content: flex-end; align-items: center">
            <a style="margin-right: 5px">Seleziona la data:</a>
            <b-select style="width: 50%;" :value="aspettiFisiologici.create_date" :options="arrayDate5" text-field="create_date" value-field="create_date" @change="changeAspettiFisiologici"></b-select>
          </b-col>
        </b-row>
        <div v-if="aspettiFisiologiciArray.length!==0 && !nuovo">
          <b-row style="padding: 20px 0">
            <b-col cols="3" v-if="aspettiFisiologici.altro.stitichezza">
              <b-list-group>
                <b-list-group-item>
                  <b>Stitichezza:</b> {{ aspettiFisiologici.altro.stitichezza }}
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col cols="3" v-if="aspettiFisiologici.altro.frequenzaevacuazione">
              <b-list-group>
                <b-list-group-item>
                  <b>Frequenza evacuazione:</b> {{ aspettiFisiologici.altro.frequenzaevacuazione }}
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col cols="3" v-if="aspettiFisiologici.altro.carenzaamminoacidiessenziali">
              <b-list-group>
                <b-list-group-item>
                  <b>Carenza amminoacidi essenziali:</b> {{ aspettiFisiologici.altro.carenzaamminoacidiessenziali > 3 ?  'Presente' : 'Assente'}}
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col cols="3" v-if="aspettiFisiologici.altro.infiammazioneintestinale">
              <b-list-group>
                <b-list-group-item>
                  <b>Infiammazione intestinale:</b> {{ aspettiFisiologici.altro.infiammazioneintestinale >= 1 ? 'Presente' : 'Assente'}}
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <b-row v-if="Object.keys(aspettiFisiologici.amminoacidiEssenziali).length !== 0">
            <b-col cols="12" style="padding-bottom: 20px; padding-left: 15px">
             <b>Amminoacidi Essenziali</b>
            </b-col>
            <b-col cols="4" v-for="aspetti of aspettiFisiologici.amminoacidiEssenziali" v-if="typeof aspetti !== 'string'">
              <b-list-group>
                <b-list-group-item style="height: 100%; padding: 12px; display: grid !important; grid-template-columns: 2fr 1fr 1fr 2fr;">
                  <label><b>{{ aspetti.nome_visualizzato }}:</b></label>
                  <label>{{ aspetti.value }} <b-icon-exclamation-triangle-fill v-if="checkSoglia(aspetti)"></b-icon-exclamation-triangle-fill></label>
                  <label style=" display: flex; justify-content: center">{{aspetti.unita_misura}}</label>
                  <label v-if="aspetti.minimo" style="display: flex; justify-content: flex-end">{{ ' (' + aspetti.minimo + ' - ' + aspetti.massimo + ')' }}</label>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <b-row v-if="Object.keys(aspettiFisiologici.amminoacidiSemiEssenziali).length !== 0">
            <b-col cols="12" style="padding: 20px 15px">
              <b>Amminoacidi Semiessenziali</b>
            </b-col>
            <b-col cols="4" v-for="aspetti of aspettiFisiologici.amminoacidiSemiEssenziali" v-if="typeof aspetti !== 'string'">
              <b-list-group>
                <b-list-group-item style="height: 100%; padding: 12px; display: grid !important; grid-template-columns: 2fr 1fr 1fr 2fr;">
                  <label><b>{{ aspetti.nome_visualizzato }}:</b></label>
                  <label>{{ aspetti.value }} <b-icon-exclamation-triangle-fill v-if="checkSoglia(aspetti)"></b-icon-exclamation-triangle-fill></label>
                  <label style=" display: flex; justify-content: center">{{aspetti.unita_misura}}</label>
                  <label v-if="aspetti.minimo" style="display: flex; justify-content: flex-end">{{ ' (' + aspetti.minimo + ' - ' + aspetti.massimo + ')' }}</label>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <b-row v-if="Object.keys(aspettiFisiologici.amminoacidiNonEssenziali).length !== 0">
            <b-col cols="12" style="padding: 20px 15px">
              <b>Amminoacidi Non Essenziali</b>
            </b-col>
            <b-col cols="4" v-for="aspetti of aspettiFisiologici.amminoacidiNonEssenziali" v-if="typeof aspetti !== 'string'">
              <b-list-group>
                <b-list-group-item style="height: 100%; padding: 12px; display: grid !important; grid-template-columns: 2fr 1fr 1fr 2fr;">
                  <label><b>{{ aspetti.nome_visualizzato }}:</b></label>
                  <label>{{ aspetti.value }} <b-icon-exclamation-triangle-fill v-if="checkSoglia(aspetti)"></b-icon-exclamation-triangle-fill></label>
                  <label style=" display: flex; justify-content: center">{{aspetti.unita_misura}}</label>
                  <label v-if="aspetti.minimo" style="display: flex; justify-content: flex-end">{{ ' (' + aspetti.minimo + ' - ' + aspetti.massimo + ')' }}</label>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </div>
        <b-row style="padding: 0 15px"  v-else-if="nuovo">
          <aspetti-fisiologici style="width: 100%;justify-content: space-around" :id-paziente="this.idPaziente" :date-array="arrayDate5" @save="saveAF" @annulla="nuovo=false"></aspetti-fisiologici>
        </b-row>
        <b-row v-else style="display: flex; justify-content: center; margin-top: 100px">
          Non sono presenti dati riguardanti gli aspetti fisiologici
        </b-row>
      </b-container>
    </b-tab>

    <b-tab title="Quadro Proteico">
      <b-container fluid style="padding: 20px;">
        <b-row style="justify-content: space-between">
          <b-col cols="2">
            <b-button v-if="!nuovo" block @click="nuovo = true"> Aggiungi misurazione </b-button>
          </b-col>
          <b-col v-if="quadroProteicoArray.length!==0 && !nuovo" cols="5" style="display: flex; justify-content: flex-end; align-items: center">
            <a style="margin-right: 5px">Seleziona la data:</a>
            <b-select style="width: 50%;" :value="quadroProteico.create_date" :options="arrayDate1" text-field="create_date" value-field="create_date" @change="changeProteicData"></b-select>
          </b-col>
        </b-row>
        <b-row v-if="quadroProteicoArray.length!==0 && !nuovo" style="padding: 20px 0">
          <b-col cols="4" v-for="quadro of quadroProteico" v-if="typeof quadro !== 'string'">
            <b-list-group>
              <b-list-group-item style="height: 100%; padding: 12px; display: grid !important; grid-template-columns: 2fr 1fr 1fr 2fr;">
                <label><b>{{ quadro.nome_visualizzato }}:</b></label>
                <label>{{ quadro.value }} <b-icon-exclamation-triangle-fill v-if="checkSoglia(quadro)"></b-icon-exclamation-triangle-fill></label>
                <label style=" display: flex; justify-content: center">{{quadro.unita_misura}}</label>
                <label v-if="quadro.minimo" style="display: flex; justify-content: flex-end">{{ ' (' + quadro.minimo + ' - ' + quadro.massimo + ')' }}</label>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
        <b-row style="padding: 0 15px"  v-else-if="nuovo">
          <quadro-proteico style="width: 100%;justify-content: space-around" :id-paziente="this.idPaziente" :date-array="arrayDate1" @save="saveQP" @annulla="nuovo=false"></quadro-proteico>
        </b-row>

        <b-row v-else style="display: flex; justify-content: center; margin-top: 100px">
          Non sono presenti dati riguardanti il quadro proteico
        </b-row>
      </b-container>
    </b-tab>

    <b-tab title="Emocromocitometrico">
      <b-container fluid style="padding: 20px;">
        <b-row style="justify-content: space-between">
          <b-col cols="2">
            <b-button block v-if="!nuovo" @click="nuovo = true">Aggiungi misurazione</b-button>
          </b-col>
          <b-col v-if="emocromocitometricoArray.length!==0 && !nuovo" cols="5" style="display: flex; justify-content: flex-end; align-items: center">
            <a style="margin-right: 5px">Seleziona la data:</a>
            <b-select style="width: 50%;" :value="emocromocitometrico.create_date" :options="arrayDate2" text-field="create_date" value-field="create_date" @change="changeEmoData"></b-select>
          </b-col>
        </b-row>
        <b-row v-if="emocromocitometricoArray.length!==0 && !nuovo" style="padding: 20px 0">
          <b-col cols="4" v-for="emo of emocromocitometrico" v-if="typeof emo !== 'string'">
            <b-list-group>
              <b-list-group-item style="height: 100%; padding: 12px; display: grid !important; grid-template-columns: 2fr 1fr 1fr 2fr;">
                <label><b>{{ emo.nome_visualizzato }}:</b></label>
                <label>{{ emo.value }} <b-icon-exclamation-triangle-fill v-if="checkSoglia(emo)"></b-icon-exclamation-triangle-fill></label>
                <label style=" display: flex; justify-content: center">{{emo.unita_misura}}</label>
                <label v-if="emo.minimo" style="display: flex; justify-content: flex-end">{{ ' (' + emo.minimo + ' - ' + emo.massimo + ')' }}</label>
              </b-list-group-item>
            </b-list-group>
          </b-col>

        </b-row>
        <b-row style="padding: 0 15px"  v-else-if="nuovo">
          <emocromocitometrico style="width: 100%;justify-content: space-around" :id-paziente="this.idPaziente" :date-array="arrayDate2" @save="saveEmo" @annulla="nuovo=false"></emocromocitometrico>
        </b-row>
        <b-row v-else style="display: flex; justify-content: center; margin-top: 100px">
          Non sono presenti dati riguardanti l'emocromocitometrico
        </b-row>
      </b-container>
    </b-tab>

    <b-tab title="Integratori prescritti">
      <b-container fluid style="padding: 20px;">
        <associazioni :id_paziente="idPaziente" ></associazioni>

      </b-container>
    </b-tab>

    <b-tab title="Sindrome Metabolica">
      <b-container fluid style="padding: 20px;">
        <b-row style="justify-content: space-between">
          <b-col v-if="!nuovo" block @click="nuovo = true" cols="2">
            <b-button block>Aggiungi misurazione</b-button>
          </b-col>
          <b-col v-if="sindromeArray.length!==0 && !nuovo" cols="5" style="display: flex; justify-content: flex-end; align-items: center">
            <a style="margin-right: 5px">Seleziona la data:</a>
            <b-select style="width: 50%;" :value="sindrome.create_date" :options="arrayDate4" text-field="create_date" value-field="create_date" @change="changeSindrome"></b-select>
          </b-col>
        </b-row>
        <b-row v-if="sindromeArray.length!==0 && !nuovo" style="padding: 20px 0">
          <b-col cols="4">
            <b-list-group>
              <b-list-group-item>
                <b>Circonferenza vita (cm):</b> {{ sindrome.circonferenza_vita }}
              </b-list-group-item>
              <b-list-group-item>
                <b>Colesterolo HDL (mg/dL):</b> {{ sindrome.colesterolo_hdl }}
              </b-list-group-item>
            </b-list-group>
            <b-list-group>
              <b-list-group-item style="margin-top: 30px; display: flex; flex-direction: column">
                <b style="margin-bottom: 10px;">Stato sindrome metabolica </b>
                <a> {{ sindrome.statoSindrome }}</a>
              </b-list-group-item>
            </b-list-group>

          </b-col>
          <b-col cols="4">
            <b-list-group>
              <b-list-group-item>
                <b>Pressione arteriosa sistolica (mmHg):</b> {{ sindrome.pressione_sistolica.value ? sindrome.pressione_sistolica.value : sindrome.pressione_sistolica }}
              </b-list-group-item>
              <b-list-group-item>
                <b>Trigliceridi (mg/dL):</b> {{ sindrome.trigliceridi.value ? sindrome.trigliceridi.value : sindrome.trigliceridi }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col cols="4">
            <b-list-group>
              <b-list-group-item>
                <b>Pressione arteriosa diastolica (mmHg):</b> {{ sindrome.pressione_diastolica.value ? sindrome.pressione_diastolica.value : sindrome.pressione_diastolica }}
              </b-list-group-item>
              <b-list-group-item>
                <b>Glicemia (mg/dL):</b> {{ sindrome.glicemia.value ? sindrome.glicemia.value : sindrome.glicemia }}
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>

        <b-row style="padding: 0 15px"  v-else-if="nuovo">
          <sindrome-metabolica style="width: 100%;justify-content: space-around" :id-paziente="this.idPaziente" :date-array="arrayDate4" @save="saveSM" @annulla="nuovo=false"></sindrome-metabolica>
        </b-row>
        <b-row v-else style="display: flex; justify-content: center; margin-top: 100px">
          Non sono ancora presenti dati riguardanti la sindrome metabolica
        </b-row>
      </b-container>
    </b-tab>

    <b-tab title="Diete">
      <div style="padding: 20px 9px;">
        <div style="display: flex; justify-content: space-between">
          <b-col v-if="!nuovo" block cols="2" style="padding: 0">
            <b-button @click="nuovo = true; isStorico=false" block>Aggiungi Dieta</b-button>
          </b-col>
          <b-col v-if="!nuovo && !isStorico && listaDiete.values.length > 0" cols="2" style="display: flex; justify-content: flex-end; align-items: center; padding: 0">
            <b-button @click="isStorico = true; nuovo = false" block>Storico Diete</b-button>
          </b-col>
        </div>
        <storico v-if="isStorico" style="margin-top: 20px" :lista-diete="listaDiete" @reload="reload" @dieta_id="getPastiDieta"></storico>
        <dieta v-else-if="listaDiete.values.length > 0 || nuovo " :editing="nuovo" :dieta="ultimaDieta" @annulla="nuovo = false" @save="saveDieta"></dieta>

        <b-row v-else style="display: flex; justify-content: center; margin-top: 100px">
          Non sono ancora presenti dati riguardanti la dieta
        </b-row>

      </div>
    </b-tab>

  </b-tabs>
</template>

<script>
import ParametriMedici from "@/services/ParametriMedici";
import Diete from "@/services/Diete.js";
import QuadroProteico from "@/components/parametriMedici/quadroProteico.vue";
import ParametriFisici from "@/components/parametriMedici/parametriFisici.vue";
import Emocromocitometrico from "@/components/parametriMedici/emocromocitometrico.vue";
import SindromeMetabolica from "@/components/parametriMedici/sindromeMetabolica.vue";
import AspettiFisiologici from "@/components/parametriMedici/aspettiFisiologici.vue";
import Dieta from "@/components/dieta.vue";
import Storico from "@/components/storico.vue";
import aspettiFisiologici from "@/components/parametriMedici/aspettiFisiologici.vue";
import Integrators from "@/services/Integrators";
import Associazioni from "@/components/integratori/associazioni.vue";

export default {
  name: "datiMedici",
  components: {
    Associazioni,
    AspettiFisiologici,
    Storico, Emocromocitometrico, ParametriFisici, QuadroProteico, SindromeMetabolica, Dieta },
  mounted: async function () {
    await this.getParametriFisici()
    await this.getEmocromocitometrico()
    await this.getQuadroProteico()
    await this.getSindrome()
    await this.getDieteLists()
    await this.getAspettiFisiologici()
    await this.getPatientIntegrators()
    // await this.getPastiList()
  },
  watch: {
    tabIndex(newValue, oldValue){
      this.nuovo = false
    }
  },
  methods: {
    async getParametriFisici() {
      const response = await ParametriMedici.getPhisicalParams(this.idPaziente)
      if (response.status === 200) {
        if(response.data.length > 0) {
          this.parametriFisiciArray = response.data.map(item => ({
            ...item,
            create_date: this.formatDate(item.create_date)
          }));
          this.arrayDate3 = this.parametriFisiciArray.map(item => ({
            create_date: item.create_date
          }));
          this.arrayDate3.sort((a, b) => {
            const dateA = new Date(a.create_date.split('/').reverse().join('/'));
            const dateB = new Date(b.create_date.split('/').reverse().join('/'));
            return dateB - dateA;
          });
          this.changePhysicalData(this.arrayDate3[0].create_date)
        }
      } else {
        this.$bvToast.toast("Errore nei dati del paziente, contattare l'assistenza", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    async getAspettiFisiologici() {
      const response = await ParametriMedici.getAspettiFisiologici(this.idPaziente)
      if (response.status === 200) {
        if (response.data.length > 0) {
          this.aspettiFisiologiciArray = response.data.map(item => ({
            ...item,
            create_date: this.formatDate(item.create_date)
          }));
          this.arrayDate5 = this.aspettiFisiologiciArray.map(item => ({
            create_date: item.create_date
          }));
          this.arrayDate5.sort((a, b) => {
            const dateA = new Date(a.create_date.split('/').reverse().join('/'));
            const dateB = new Date(b.create_date.split('/').reverse().join('/'));
            return dateB - dateA;
          });
          this.aspettiFisiologiciArray = this.separaAmminoacidi(this.aspettiFisiologiciArray)

          this.changeAspettiFisiologici(this.arrayDate5[0].create_date)

        }
      } else {
        this.$bvToast.toast("Errore nei dati del paziente, contattare l'assistenza", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    async getEmocromocitometrico() {
      const response = await ParametriMedici.getEmocromocitometrico(this.idPaziente)
      if (response.status === 200) {
        if (response.data.length > 0) {
          this.emocromocitometricoArray = response.data.map(item => ({
            ...item,
            create_date: this.formatDate(item.create_date)
          }));

          this.arrayDate2 = this.emocromocitometricoArray.map(item => ({
            create_date: item.create_date
          }));
          this.arrayDate2.sort((a, b) => {
            const dateA = new Date(a.create_date.split('/').reverse().join('/'));
            const dateB = new Date(b.create_date.split('/').reverse().join('/'));
            return dateB - dateA;
          });
          this.changeEmoData(this.arrayDate2[0].create_date)
        }
      } else {
        this.$bvToast.toast("Errore nei dati del paziente, contattare l'assistenza", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    async getQuadroProteico() {
      const response = await ParametriMedici.getQuadroProteico(this.idPaziente)
      if (response.status === 200) {
        if(response.data.length > 0) {
          this.quadroProteicoArray = response.data.map(item => ({
            ...item,
            create_date: this.formatDate(item.create_date)
          }));
          this.arrayDate1 = this.quadroProteicoArray.map(item => ({
            create_date: item.create_date
          }));
          this.arrayDate1.sort((a, b) => {
            const dateA = new Date(a.create_date.split('/').reverse().join('/'));
            const dateB = new Date(b.create_date.split('/').reverse().join('/'));
            return dateB - dateA;
          });
          this.changeProteicData(this.arrayDate1[0].create_date)
        }
      } else {
        this.$bvToast.toast("Errore nei dati del paziente, contattare l'assistenza", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    async getSindrome() {
      const response = await ParametriMedici.getSindrome(this.idPaziente)
      if (response.status === 200) {
        if(response.data.length > 0) {
          this.sindromeArray = response.data.map(item => ({
            ...item,
            create_date: this.formatDate(item.create_date)
          }));

          this.arrayDate4 = this.sindromeArray.map(item => ({
            create_date: item.create_date
          }));
          this.arrayDate4.sort((a, b) => {
            const dateA = new Date(a.create_date.split('/').reverse().join('/'));
            const dateB = new Date(b.create_date.split('/').reverse().join('/'));
            return dateB - dateA;
          });
          this.changeSindrome(this.arrayDate4[0].create_date)
        }
      } else {
        this.$bvToast.toast("Errore nei dati del paziente, contattare l'assistenza", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    async getPatientIntegrators() {
      const response = await Integrators.getPatientIntegrators(this.idPaziente)
      if (response.status === 200) {
        console.log(response.data)
      } else {
        this.$bvToast.toast("Errore nei dati del paziente, contattare l'assistenza", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    async getDieteLists() {
      const response = await Diete.getDieteList(this.idPaziente)

      if (response.status === 200 ) {
        this.listaDiete = response.data
        if (response.data.length >0) {
          this.listaDiete = this.listaDiete.map(item => {
                item.data_fine = this.formatDate(item.data_fine)
                item.data_inizio = this.formatDate(item.data_inizio)
                item.create_date = this.formatDate(item.create_date)
                return item
              }
          ).sort((a, b) => {
            const dateA = new Date(a.create_date.split('/').reverse().join('/'));
            const dateB = new Date(b.create_date.split('/').reverse().join('/'));
            return dateB - dateA;
          });

          const ultimaDietaId = this.listaDiete[0].id
          await this.getPastiDieta(ultimaDietaId)

          this.listaDiete = {
            rows: this.listaDiete.length,
            values: this.listaDiete
          }
        } else {
          this.isStorico = false
        }
      } else {
        this.$bvToast.toast("Errore nei dati del paziente, contattare l'assistenza", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    async getPastiDieta(ultimaDietaId) {
      this.isStorico = false
      const response = await Diete.getPastiDieta(ultimaDietaId)
      if (response.status === 200) {
        if (response.data.length >0) {
          this.ultimaDieta = response.data[0]
          this.ultimaDieta = {
            ...this.ultimaDieta,
            data_fine : this.formatDate(this.ultimaDieta.data_fine),
            data_inizio : this.formatDate(this.ultimaDieta.data_inizio),
          }
        }
      } else {
        this.$bvToast.toast("Errore nei dati del paziente, contattare l'assistenza", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    changePhysicalData(value) {
      this.parametriFisici = this.parametriFisiciArray.find(x => x.create_date === value)
    },
    changeEmoData(value) {
      this.emocromocitometrico = this.emocromocitometricoArray.find(x => x.create_date === value)
      this.removeFields(this.emocromocitometrico)
    },
    changeProteicData(value) {
      this.quadroProteico = this.quadroProteicoArray.find(x => x.create_date === value)
      this.removeFields(this.quadroProteico)
    },
    changeSindrome(value) {
      this.sindrome = this.sindromeArray.find(x => x.create_date === value)
    },
    changeAspettiFisiologici(value) {
      this.aspettiFisiologici = this.aspettiFisiologiciArray.find(x => x.create_date === value)
    },
    async saveQP() {
      this.nuovo = false
      await this.getQuadroProteico()
    },
    async savePP() {
      this.nuovo = false
      await this.getParametriFisici()
    },
    async saveEmo() {
      this.nuovo = false
      await this.getEmocromocitometrico()
    },
    async saveSM() {
      this.nuovo = false
      await this.getSindrome()
    },
    async saveAF() {
      this.nuovo = false
      await this.getAspettiFisiologici()
    },
    async saveDieta() {
      this.nuovo = false
      await this.getDieteLists()
    },
    checkSoglia(value){
      if(value.value && value.minimo && value.massimo){
        if(Number(value.value)< Number(value.minimo) || Number(value.value)> Number(value.massimo))
          return true
      } else {
        return false
      }
    },
    async reload() {
      console.log("arrivoooooooo")
      await this.getDieteLists()
    },
    removeFields(obj) {
      for (const key in obj) {
        if (obj[key] === "") {
          delete obj[key];
        } else if (["create_user", "update_user", "id", "type", "paziente_id", "pazienti", "update_date"  ].includes(key)) {
          delete obj[key];
        }
      }
    },
    separaAmminoacidi(data) {
      return data.map(entry => {
        const amminoacidiEssenziali = {}
        const amminoacidiSemiEssenziali = {}
        const amminoacidiNonEssenziali = {}
        const altro = {}
        let create_date = ""
        Object.keys(entry).forEach(key => {
          if (this.essenziali.includes(key) && entry[key]) {
            amminoacidiEssenziali[key] = entry[key];
          } else if (this.semiEssenziali.includes(key) && entry[key]) {
            amminoacidiSemiEssenziali[key] = entry[key];
          } else if (this.nonEssenziali.includes(key) && entry[key]) {
            amminoacidiNonEssenziali[key] = entry[key];
          } else if (this.altro.includes(key) && entry[key]) {
            altro[key] = entry[key];
          } else if (key === "create_date"){
            create_date = entry[key]
          }
        });

        return {
          amminoacidiEssenziali,
          amminoacidiSemiEssenziali,
          amminoacidiNonEssenziali,
          altro,
          create_date
        };
      });
    }
  },
  data() {
    return {
      idPaziente:this.$router.currentRoute.params.id,
      isStorico: false,
      tabIndex: 0,
      nuovo: false,
      quadroProteico: {},
      sindromeArray: [],
      parametriFisiciArray: [],
      emocromocitometricoArray: [],
      aspettiFisiologiciArray: [],
      quadroProteicoArray: [],
      listaDiete:[],
      arrayDate5: [],
      arrayDate4: [],
      arrayDate3: [],
      arrayDate2: [],
      arrayDate1: [],
      parametriFisici: {},
      aspettiFisiologici: {},
      sindrome:{},
      emocromocitometrico: {},
      ultimaDieta:{
        data_inizio: '',
        data_fine: '',
        tipodieta: '',
        note: '',
        medico: '',
        pasti: {
          lunedi : {
            colazione: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            spuntino_mattina: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            pranzo: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            spuntino_pomeriggio: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            cena: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }]
          },
          martedi :    {
            colazione: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            spuntino_mattina: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            pranzo: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            spuntino_pomeriggio: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            cena: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }]
          },
          mercoledi :     {
            colazione: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            spuntino_mattina: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            pranzo: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            spuntino_pomeriggio: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            cena: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }]
          },
          giovedi :     {
            colazione: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            spuntino_mattina: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            pranzo: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            spuntino_pomeriggio: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            cena: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }]
          },
          venerdi :     {
            colazione: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            spuntino_mattina: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            pranzo: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            spuntino_pomeriggio: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            cena: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }]
          },
          sabato : {
            colazione: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            spuntino_mattina: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            pranzo: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            spuntino_pomeriggio: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            cena: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }]
          },
          domenica : {
            colazione: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            spuntino_mattina: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            pranzo: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            spuntino_pomeriggio: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }],
            cena: [{
              nome: '',
              calorie_per_100gr: "",
              quantita: "",
              unita_misura: ""
            }]
          },
        },
        totale_calorie: 2000
      },
      essenziali : ["isoleucina", "leucina", "lisina", "metionina", "fenilalanina", "treonina", "triptofano", "valina", "istidina"],
      semiEssenziali : ["arginina", "cisteina", "glutammina", "tirosina", "prolina"],
      nonEssenziali : ["acidoAspartico", "acidoGlutammico", "alanina", "asparagina", "glicina", "serina"],
      altro : ["stitichezza", "frequenzaevacuazione", "carenzaamminoacidiessenziali", "infiammazioneintestinale"]
    }
  },


}
</script>

<style>
.nav {
  background-color: transparent !important;
}
.nav-link {
  background-color: #86a7cb !important;
  color: white;
  width: 95%;
  margin: auto;
}
.nav-link.active {
  background-color: #03376C !important;
  color: white;
}
.tabDieta {
  margin-top: 20px;
  background-color: transparent !important;
}
.tabDieta .nav-link.active {
  background-color: #03376C !important;
  border-top-color: #03376C !important;
  border-left-color: #03376C !important;
  border-right-color: #03376C !important;
  color: white !important;
  margin: auto;
}
.tabDieta .nav-link {
  background-color: white !important;
  border-top-color: #03376C !important;
  border-left-color: #03376C !important;
  border-right-color: #03376C !important;
  color: #03376C !important;
  margin: auto;
}
.tabDieta .nav-link.disabled {
  opacity: 0.2;
  background-color: #E8E8E84F !important;
}

/* .tabDieta:has(.nav-link.disabled) {
  cursor: not-allowed;
} */

label{
  margin: 0;
}
.list-group-item{
  display: flex !important;
  justify-content: space-between !important;
}
.bi-exclamation-triangle-fill{
  color: orange;
}
</style>
