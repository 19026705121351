<template>
  <div>
    <b-row style="padding-top: 15px">
      <b-col>
        <b-form-group label="Data misurazione:">
          <b-form-input v-model="aspettiFisiologici.create_date" type="date"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Stitichezza:">
          <b-form-input v-model="aspettiFisiologici.stitichezza"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Frequenza evacuazione:">
          <b-select :options="frequenzaEvacuazioneOptions" v-model="aspettiFisiologici.frequenzaevacuazione"></b-select>
        </b-form-group>
      </b-col>

    </b-row>
    <b-row>
      <b-col cols="12" style="padding: 20px 15px">
        <b>Amminoacidi Essenziali</b>
      </b-col>
      <b-col>
        <b-form-group label="Fenilalanina:">
          <b-form-input type="number" v-model="aspettiFisiologici.fenilalanina"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Isoleucina:">
          <b-form-input type="number" v-model="aspettiFisiologici.isoleucina"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Istidina:">
          <b-form-input type="number" v-model="aspettiFisiologici.istidina"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Leucina:">
          <b-form-input type="number" v-model="aspettiFisiologici.leucina"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Lisina:">
          <b-form-input type="number" v-model="aspettiFisiologici.lisina"></b-form-input>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Metionina:">
          <b-form-input type="number" v-model="aspettiFisiologici.metionina"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Treonina:">
          <b-form-input type="number" v-model="aspettiFisiologici.treonina"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Triptofano:">
          <b-form-input type="number" v-model="aspettiFisiologici.triptofano"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Valina:">
          <b-form-input type="number" v-model="aspettiFisiologici.valina"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" style="padding: 20px 15px">
        <b>Amminoacidi Semiessenziali</b>
      </b-col>
      <b-col>
        <b-form-group label="Arginina:">
          <b-form-input type="number" v-model="aspettiFisiologici.arginina"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Cisteina:">
          <b-form-input type="number" v-model="aspettiFisiologici.cisteina"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Glutammina:">
          <b-form-input type="number" v-model="aspettiFisiologici.glutammina"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Tirosina:">
          <b-form-input type="number" v-model="aspettiFisiologici.tirosina"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Prolina:">
          <b-form-input type="number" v-model="aspettiFisiologici.prolina"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" style="padding: 20px 15px">
        <b>Amminoacidi Non essenziali</b>
      </b-col>
      <b-col>
        <b-form-group label="Alanina:">
          <b-form-input type="number" v-model="aspettiFisiologici.alanina"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Asparagina:">
          <b-form-input type="number" v-model="aspettiFisiologici.asparagina"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Acido Aspartico:">
          <b-form-input type="number" v-model="aspettiFisiologici.acidoAspartico"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Acido Glutammico:">
          <b-form-input type="number" v-model="aspettiFisiologici.acidoGlutammico"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Glicina:">
          <b-form-input type="number" v-model="aspettiFisiologici.glicina"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Serina:">
          <b-form-input type="number" v-model="aspettiFisiologici.serina"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row style="display: flex;justify-content: flex-end; margin-top: 20px; margin-bottom: 20px">
      <b-col cols="3">
        <b-button @click="$emit('annulla')" block> Annulla </b-button>
      </b-col>
      <b-col cols="3">
        <b-button block v-b-modal.my-modal6> Salva misurazione </b-button>
      </b-col>
    </b-row>
    <b-modal ok-title="Salva" cancel-title="Annulla" @ok="saveData" ref="my-modal6" id="my-modal6" >
      <label>Sei sicuro di voler salvare i dati?</label>
    </b-modal>
  </div>

</template>

<script>
import {formatDateInit} from "@/utils/formatDate";
import ParametriMedici from "@/services/ParametriMedici";
import {checkDateError} from "@/utils/checkDateError";

export default {
  name: "aspettiFisiologici",
  props:["idPaziente", 'dateArray'],
  emits:['annulla', 'save'],
  data() {
    return {
      aspettiFisiologici:{
        create_date : formatDateInit(),
      },
      frequenzaEvacuazioneOptions:[]
    }
  },
  mounted: async function () {
    await this.getFrequenzaEvacuazione()
  },
  methods:{
    async saveData() {
      const body = {
        ...this.aspettiFisiologici,
        paziente_id: this.idPaziente,
      }
      if(!checkDateError(this.aspettiFisiologici.create_date, this.dateArray)) {

        const response = await ParametriMedici.setAspettiFisiologici(body)
        if (response.status === 200) {
          this.$bvToast.toast("Aspetti fisiologici aggiunti con successo.", {
            title: `Operazione riuscita`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'success',
            solid: true,
            noCloseButton: true
          })
          this.$refs['my-modal6'].hide()
          this.$emit('save')
        } else {
          this.$bvToast.toast("Impossibile salvare i dati, contattare l'assistenza", {
            title: `Attenzione`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            noCloseButton: true
          })
        }
      }else {
        this.$bvToast.toast('Non è possibile inserire un\'altra misurazione per il giorno selezionato', {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    async getFrequenzaEvacuazione(){
      const response = await ParametriMedici.getFrequenzaEvacuazione()
      if (response.status === 200) {
        this.frequenzaEvacuazioneOptions = response.data
      }
    }
  }
}

</script>


<style scoped>

</style>
