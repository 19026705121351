


export function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}



export function formatDateInit() {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    if (month < 10){
        month = `0${month}`
    }
    if (day < 10){
        day = `0${day}`
    }
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
}


export function calcolaEta(dataNascita) {
    const oggi = new Date();
    const nascita = new Date(dataNascita);
    let eta = oggi.getFullYear() - nascita.getFullYear();
    const mese = oggi.getMonth() - nascita.getMonth();
    if (mese < 0 || (mese === 0 && oggi.getDate() < nascita.getDate())) {
        eta--;
    }
    return eta;
}
