<template>
  <div>
    <b-row style="height: 20px; width: 100%">
      <b-button class="ml-19 mt-5" style="background-color: #D51160; border: none; width: 5%" @click="indietro"><b-icon icon="arrow-left" font-scale="1.5"></b-icon></b-button>
    </b-row>
    <b-card class="ml-15 mx-15 mt-10">
      <b-card-header>
        <label class="titoloM2">Nuovo Integratore<br/></label>
      </b-card-header>
      <div class="ml-10 mt-10">
        <b-form-group
            id="labelNome"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="7"
            label="Nome Integratore: "
            label-for="nome"
        >
          <b-form-input v-model="nomeIntegratore" id="nome" :state="validateName" type="text" style="margin-right: 5px"></b-form-input>
          <b-form-invalid-feedback :state="validateName">
            Il nome deve essere lungo almeno 3 caratteri.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
            id="labelNome"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="7"
            label="Descrizione: "
            label-for="descrizione"
        >
          <b-form-textarea v-model="descrizioneIntegratore" :state="validateDescrizione" id="descrizione" rows="6" style="margin-right: 5px"></b-form-textarea>
          <b-form-invalid-feedback :state="validateDescrizione">
            La descrizione può contenere al massimo 500 caratteri, ne sono stati inseriti {{descrizioneIntegratore.length}}.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
            id="labelNome"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="7"
            label="Paziente: "
            label-for="paziente"
        >
          <b-form-select v-model="paziente" :state="validatePaziente" :options="listaPazienti" value-field="id" text-field="nome_paziente" rows="6" style="margin-right: 5px"></b-form-select>
          <b-form-invalid-feedback :state="validatePaziente">
            Devi inserire un paziente.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-row>
          <b-col xs="6" sm="6" md="6" lg="6">

           <b-form-group
                label-cols-sm="3"
                label-cols-lg="3"
                content-cols-sm
                content-cols-lg="8"
                label="Data Inizio: "
                label-for="start_date"
            >
              <b-form-input v-model="start_date" :state="validateDataInizio" id="start_date" type="date" style="margin-right: 5px"></b-form-input>
              <b-form-invalid-feedback :state="validateDataInizio">
                Devi inserire una data di inizio.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col xs="6" sm="6" md="6" lg="6">
            <b-form-group
                label-cols-sm="3"
                label-cols-lg="3"
                content-cols-sm
                content-cols-lg="8"
                label="Data Fine: "
                label-for="end_date"
            >
              <b-form-input v-model="end_date" :state="validateDataFine" id="end_date" type="date" style="margin-right: 5px"></b-form-input>
              <b-form-invalid-feedback :state="validateDataFine">
                Devi inserire una data di fine.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group
            id="labelNome"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm
            content-cols-lg="7"
            label="Condizione clinica macroscopica:"
            label-for="nome"
        >
          <b-form-select v-model="patologiaSelezionata" :options="patologie" value-field="id" text-field="name" style="margin-right: 5px" @change="getCondizioni"></b-form-select>
        </b-form-group>
        <div v-if="patologiaSelezionata">
          <h6 class="mt-7 mb-7">Condizioni: </h6>
          <b-row>
            <b-col v-for="(condizione, index) in condizioni" xs="6" sm="6" md="6" lg="6">
              <b-form-group
                  id="labelNome"
                  label-cols-sm="3"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="8"
                  :label="condizione.name"
                  label-for="nome"
              >
                <b-form-select v-model="selectedRange[index]" :options="condizione.range" value-field="id" text-field="name" style="margin-right: 5px" @change="getAdvices"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <div v-if="selectedRange.length === condizioni.length && selectedRange.length !== 0 && selectedRange[0]">
            <b-row v-if="fasullo">
              <label style="font-weight: bold" >Il farmaco consigliato per queste condizioni cliniche è il Singoteo:</label>
              <b-table
                  :items="singoteo"
                  :fields="fieldsSingoteo"
                  ref="tabel"
                  responsive="sm"
                  style="margin-left:0;width: 100%;margin-right: 50px"
              >
              </b-table>
            </b-row>
            <div v-else>
              <b-row style="display: flex; justify-content: flex-end; margin-right: 0.5%">

                <b-button @click="consiglio" style="float: right; margin-right: 1%; margin-bottom: 10px; width: 225px">Utilizza AI</b-button>
                <b-icon class="align-bottom" font-scale="2" icon="info-circle" style="float: right; margin-right: 2%" v-b-tooltip.click.bottom="`Utilizza l'intelligenza artificiale per ricevere un suggerimento`"></b-icon>
              </b-row>
              <b-row style="display: flex; justify-content: space-between">
                <label style="font-weight: bold">{{advices.condition_advice[0].measure_type}}: {{advices.condition_advice[0].quantity}}</label>
                <label style="font-weight: bold; float: right; margin-right: 4%">{{advices.condition_advice[1].measure_type}}: {{advices.condition_advice[1].quantity}}</label>
              </b-row>
              <b-row>
                <b-table
                    :items="advices.ingredienti_consigliati"
                    :fields="fields"
                    ref="tabel"
                    select-mode="multi"
                    responsive="sm"
                    selectable
                    @row-selected="onRowSelected"
                    style="margin-left:0;width: 100%;margin-right: 50px"

                >
                  <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                      <span aria-hidden="true">&check;</span>
                      <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                      <span aria-hidden="true">&nbsp;</span>
                      <span class="sr-only">Not selected</span>
                    </template>
                  </template>
                  <template #cell(quantity)="{ rowSelected, index }">
                    <b-form-input v-model="advices.ingredienti_consigliati[index].quantita" v-if="rowSelected" type="number" style="width: 40%;" step="1" min="0" @blur="deselected(advices.ingredienti_consigliati[index],index)" @update="updateDosaggio(advices.ingredienti_consigliati[index],index)"></b-form-input>
                  </template>
                </b-table>
              </b-row>
              <b-row style="justify-content: end">
                <label v-if="dosaggioTotale>100" style="font-weight: bold; float: right; margin-right: 4%; color: red">Dosaggio Totale: {{dosaggioTotale}}/100</label>
                <label v-else style="font-weight: bold; float: right; margin-right: 4%">Dosaggio Totale: {{dosaggioTotale}}/100</label>
              </b-row>

            </div>

              <b-row style="justify-content: end; margin-top: 20px; margin-right: 3%">
                <b-col cols="2">
              <b-button block v-if="back" @click="fasullo = !fasullo" style="float: right; margin-right: 3%; margin-bottom: 10px">
                {{fasullo ? 'Componi manualmente' : 'Visualizza consigliato'}}</b-button>
              <b-button block @click="addIntegratore" style="float: right; margin-right: 3%; margin-bottom: 10px">Salva</b-button>
                </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import Patologies from "@/services/Patologies";
import Conditions from "@/services/Conditions";
import Advices from "@/services/Advices";
import Integrators from "@/services/Integrators";
import Patients from "@/services/Patients";

export default {
  data(){
    return{
      singoteo:[{nome: 'Ganoderma Lucidum ', dosaggio: 200},{nome: 'Moringa Olefeira', dosaggio: 100},{nome: 'Spirulina Platensis ', dosaggio: 100}],
      fasullo: true,
      listaPazienti: [],
      paziente:null,
      start_date:null,
      end_date:null,
      back:false,
      nomeIntegratore: null,
      descrizioneIntegratore: '',
      patologie: [],
      patologiaSelezionata: null,
      condizioni: [],
      selectedRange: [],
      advices: {},
      selectedAdvices: [],
      dosaggioTotale:0,
      maxDosaggio: 100,
      fields: [],
      fieldsSingoteo: [{
        label: 'Nome',
        key: 'nome',
        thStyle: "width:10rem",
      },
        {
          label: `Dosaggio (mg)`,
          key: 'dosaggio',
          thStyle: "width: 10rem",
        },],
    }
  },
  watch: {
    patologiaSelezionata(newValue, oldValue) {
      this.fasullo = newValue > 3 && newValue < 8;
      this.back = newValue > 3 && newValue < 8;
    }
  },
  computed: {
    validateName(){
      return this.nomeIntegratore === null ? null : this.nomeIntegratore.length >= 3
    },
    validatePaziente(){
      return this.paziente === null ? null : true
    },
    validateDataInizio(){
      return this.start_date === null ? null : true
    },
    validateDataFine(){
      return this.end_date === null ? null : true
    },
    validateDescrizione(){
      return this.descrizioneIntegratore === '' ? null : this.descrizioneIntegratore.length <= 500
    }
  },
  mounted: async function() {
    await this.getPatients()
    this.fields= [
      {
        label: 'Selezionato',
        key: 'selected',
        thStyle: "width: 5rem",
      },
      {
        label: 'Nome',
        key: 'nome_ingrediente',
        thStyle: "width:10rem",
      },
      {
        label: `Dosaggio (%)`,
        key: 'quantity',
        thStyle: "width: 10rem",
      },
      {
        label: 'Rapporto Suggerito',
        key: 'quantità_ingrediente',
        thStyle: "width: 10rem",
      }
    ]
    await this.getPatologie()
  },
  methods:{
    onRowSelected(items) {
      this.selectedAdvices = items
    },
    async getPatients(){
      const response = await Patients.getPatientsList()
      if(response.status === 200){
        this.listaPazienti = response.data
        for(let i=0; i<this.listaPazienti.length; i++){
          this.listaPazienti[i].nome_paziente += " " + this.listaPazienti[i].cognome_paziente + " - " + this.listaPazienti[i].data_nascita + " - " +this.listaPazienti[i].codice_fiscale
          // this.pazienti[i].create_date = DateTime.fromSeconds(this.pazienti[i].create_date).toFormat('dd/MM/yyyy')
        }
      } else {
        this.$bvToast.toast("Errore nei dati dei pazienti, contattare l'assistenza", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    async consiglio(){
      const response = await Advices.makeSuggestionWithRange(this.selectedRange[0], this.patologiaSelezionata)
      if(response.status === 200){
        this.$refs.tabel.clearSelected()
        for(let i=0; i<this.advices.ingredienti_consigliati.length; i++){
          for (let j=0; j<response.data.ingredienti.length; j++) {
            if(this.advices.ingredienti_consigliati[i].id_ingrediente === response.data.ingredienti[j].id) {
              this.$refs.tabel.selectRow(i)
            }
          }

        }
      } else {
        this.$bvToast.toast(response.data, {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    async getPatologie(){
      this.patologie = []
      const response = await Patologies.getPatologies()
      if(response.status === 200){
        this.patologie = response.data
      } else {
        this.$bvToast.toast(response.data, {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    async getCondizioni(){
      this.condizioni = []
      this.selectedRange = []
      this.dosaggioTotale = 0
      this.maxDosaggio = 100
      const response = await Conditions.getConditionById(this.patologiaSelezionata)
      if(response.status === 200){
        for(let i=0; i<response.data.length; i++){
          const j = this.condizioni.findIndex(x => x.name === response.data[i].name)
          if(j === -1){
            this.condizioni.push({
              name: response.data[i].name,
              range : [{id: response.data[i].id, name: response.data[i].description}]
            })
          } else {
            this.condizioni[j].range.push({id: response.data[i].id, name: response.data[i].description})
          }
        }
      } else {
        this.$bvToast.toast(response.data, {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    async getAdvices() {
      this.dosaggioTotale = 0
      this.maxDosaggio = 100
      if (this.selectedRange.length === this.condizioni.length && this.selectedRange.length !== 0 && this.selectedRange[0]) {
        const response = await Advices.getAdviceByConditios(this.selectedRange, this.patologiaSelezionata)
        if(response.status === 200){
          this.advices = response.data
        } else {
          this.$bvToast.toast(response.data, {
            title: `Attenzione`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            noCloseButton: true
          })
        }
      }
    },
    async addIntegratore() {
      if(this.validateName) {
        if(!this.fasullo){
          if (this.selectedAdvices.length === 0) {
            this.$bvToast.toast("E' necessario Selezionare almeno 1 componente", {
              title: `Attenzione`,
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000,
              variant: 'danger',
              solid: true,
              noCloseButton: true
            })
            return
          }
          let tot = 0
          for (let i = 0; i < this.advices.ingredienti_consigliati.length; i++) {
            for (let j = 0; j < this.selectedAdvices.length; j++) {
              if (this.selectedAdvices[j].id_ingrediente === this.advices.ingredienti_consigliati[i].id_ingrediente) {
                this.selectedAdvices[j].quantita = this.advices.ingredienti_consigliati[i].quantita
                tot += Number(this.selectedAdvices[j].quantita)
              }
            }
          }
          if (tot !== 100) {
            this.$bvToast.toast('Il dosaggio totale deve essere esattamente 100', {
              title: `Attenzione`,
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000,
              variant: 'danger',
              solid: true,
              noCloseButton: true
            })
            return
          }
        }
        const singoteo = {
          name: this.nomeIntegratore,
          description: this.descrizioneIntegratore,
          conditions: {
            id_patologia: this.patologiaSelezionata,
            condizioni: this.selectedRange
          },
          paziente_id: this.paziente,
          end_date : this.end_date,
          start_date : this.start_date,
          ingredients: [{
            id: 2,
            dosaggio: 50
          },{
            id: 1,
            dosaggio: 25
          },{
            id: 12,
            dosaggio: 25
          }]
        }
        const body = {
          name: this.nomeIntegratore,
          description: this.descrizioneIntegratore,
          conditions: {
            id_patologia: this.patologiaSelezionata,
            condizioni: this.selectedRange
          },
          ingredients: []
        }
        for(let i=0; i<this.selectedAdvices.length; i++){
          body.ingredients.push({
            id: this.selectedAdvices[i].id_ingrediente,
            dosaggio: Number(this.selectedAdvices[i].quantita)
          })
        }
        body.paziente_id= this.paziente
        body.end_date= this.end_date
        body.start_date= this.start_date
        const response = await Integrators.setIntegrator(this.fasullo ? singoteo : body)
        if(response.status === 204){
          await this.$router.push('/integratori')
        } else {
          this.$bvToast.toast(response.data, {
            title: `Attenzione`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            noCloseButton: true
          })
        }
      } else {
        this.$bvToast.toast("E' necessario inserire tutti i campi", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    deselected(item){
      if((this.dosaggioTotale > 100 || item.quantita>100) && item.quantita !== undefined){
        this.$bvModal.msgBoxOk("Il dosaggio inserito del "+item.quantita+"% è troppo alto ed è stato sostituito con il " + this.maxDosaggio+"%", {
          title: "Errore",
          size: "md",
          okVariant: "outline-danger",
          headerClass: "vv-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
          okTitle: "Chiudi",
        })
        item.quantita = this.maxDosaggio
        this.dosaggioTotale = 100
      }
    },
    updateDosaggio(item){
      this.maxDosaggio = 100
      this.dosaggioTotale = 0
      for (let i=0; i<this.selectedAdvices.length; i++){
        if(this.selectedAdvices[i].quantita !== undefined)
          this.dosaggioTotale += Number(this.selectedAdvices[i].quantita)
        if(this.selectedAdvices[i].id_ingrediente !== item.id_ingrediente && this.selectedAdvices[i].quantita !== undefined){
          this.maxDosaggio -= Number(this.selectedAdvices[i].quantita)
        }
      }
    },
    async indietro(){
      await this.$router.push('/integratori')
    }
  }
}
</script>

<style scoped>

</style>
