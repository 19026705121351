import BaseService from "./BaseService";

export default class Patients extends BaseService {

    static async getPatientsList(id){
        return await this.perform({
            url: '/pazienti/paziente/get',
            method: 'GET',
            params:{
                id:id
            }
        })
    }

    static async createPatient(body){
        return await this.perform({
            url: '/pazienti/paziente/set',
            method: 'POST',
            body:body
        })
    }

    static async updatePatient(body){
        return await this.perform({
            url: '/pazienti/paziente/put',
            method: 'PUT',
            body: body
        })
    }

    static async deletePatient(id){
        return await this.perform({
            url: '/pazienti/paziente/delete',
            method: 'DELETE',
            params:{
                id:id
            }
        })
    }

    static async getEtnieList(){
        return await this.perform({
            url: '/pazienti/paziente/etnie',
            method: 'GET'
        })
    }

    // static async createPatient(){
    //     return await this.perform({
    //         url: '/pazienti/gruppopazienti/set',
    //         method: 'POST'
    //     })
    // }
    //
    // static async updatePatient(){
    //     return await this.perform({
    //         url: '/pazienti/gruppopazienti/put',
    //         method: 'PUT'
    //     })
    // }
    //
    // static async deletePatient(id){
    //     return await this.perform({
    //         url: '/pazienti/gruppopazienti/delete',
    //         method: 'DELETE',
    //         body: {id: id}
    //     })
    // }

}
