export function translateMeal(meal) {
    const mealMapping = {
        "Colazione": "colazione",
        "Spuntino Mattina": "spuntino_mattina",
        "Pranzo": "pranzo",
        "Spuntino Pomeriggio": "spuntino_pomeriggio",
        "Cena": "cena"
    };

    // Trova il valore corrispondente nel mapping
    if (meal in mealMapping) {
        return mealMapping[meal];
    }

    // Trova la chiave corrispondente nel mapping inverso
    const reversedMapping = Object.fromEntries(
        Object.entries(mealMapping).map(([key, value]) => [value, key])
    );

    return reversedMapping[meal] || null;
}
