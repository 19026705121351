import BaseService from "./BaseService";

export default class Combination extends BaseService {

    static async getCombinationByCategory(id){
        return await this.perform({
            url: '/getCombinationByCategory',
            method: 'post',
            body: {'category': id}
        })
    }

    static async getCombinationById(id){
        return await this.perform({
            url: '/getCombinationById',
            method: 'post',
            body: {'_id': id}
        })
    }

    static async deleteCombination(id){
        return await this.perform({
            url: '/deleteCombination',
            method: 'delete',
            body: {"Filter": {"_id": id}}
        })
    }

    static async createCombination(body){
        return await this.perform({
            url: '/createCombination',
            method: 'post',
            body: body
        })
    }

    static async updateCombination(id, body){
        return await this.perform({
            url:'/updateCombination',
            method: 'put',
            body: {
                "Filter": {
                    "_id": id
                },
                "DataToUpdate": body
            }
        })
    }
}
