<template>
  <div style="height: 100%; padding: 20px 50px 0 50px; display: grid; grid-template-rows: auto 1fr auto;">
    <b-row style="width: 100%; margin-bottom: 15px; margin-right: 0; margin-left: 0 ">
      <b-button style="background-color: #D51160; border: none; width: 5%" @click="indietro"><b-icon icon="arrow-left" font-scale="1.5"></b-icon></b-button>
    </b-row>
    <b-form style="height: 100%;">
      <anagrafica-paziente :paziente="paziente" :editing="false" @save="save"></anagrafica-paziente>
    </b-form>

  </div>
</template>

<script>
import AddDatiMedici from "@/components/addDatiMedici.vue";
import AnagraficaPaziente from "@/components/anagraficaPaziente.vue";

export default {
  components: {AnagraficaPaziente, AddDatiMedici},
  mounted: async function () {
    await this.getEtnie()
  },
  data(){
    return {
      paziente:{
        id: '',
        nome_paziente : '',
        cognome_paziente : '',
        data_nascita : '',
        codice_fiscale: '',
        mail : '',
        numero_telefono : '',
        gender : '',
        etnia:''
      },
      id_paziente: null,
      selectedComponent: [],
      etnie:[]
    }
  },
  methods: {
    async save(id_paziente) {
        this.id_paziente = id_paziente
        this.$bvToast.toast("Paziente inserito con successo.", {
          title: `Successo`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          noCloseButton: true
        })
      await this.$router.push({path: `/pazienti/`+id_paziente})
  //TODO MANCA ELSE
    },
    async indietro(){
      await this.$router.push({path: `/pazienti`})
    }
  }
}
</script>

<style scoped>
.titoliPagina {
  color: #0C56A0;
  font-size: 23px;
  letter-spacing:1px;
  font-family: "Montserrat";
  font-weight: 700;
  margin-bottom: 0;
}
</style>

