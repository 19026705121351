import BaseService from "./BaseService";

export default class Registration extends BaseService {

    static async addMedico(body){
        return await this.perform({
            url: '/register',
            method: 'post',
            body: body
        })
    }
}
