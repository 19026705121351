<template>
  <div style="height: 100%; padding: 20px 50px 0 50px; display: grid; grid-template-rows: auto auto auto 1fr;">
    <b-row style="width: 100%; margin: 0 0 15px 0; display: flex; justify-content: space-between">
      <b-button style="background-color: #D51160; border: none; width: 5%" @click="indietro"><b-icon icon="arrow-left" font-scale="1.5"></b-icon></b-button>
      <b-button style="background-color: #D51160; border: none; width: 15%; padding: 0;" @click="goToDashboard"><b-icon icon="graph-up" variant="outline-primary"/>
        Visualizza dashboard
      </b-button>
    </b-row>
    <anagrafica-paziente :patient="paziente" :editing="true" @save="save"></anagrafica-paziente>

    <div class="linea-retta"></div>
    <DatiMedici></DatiMedici>


  </div>
</template>

<script>
import Patients from "@/services/Patients";
import DatiMedici from "@/components/dettaglioDatiMedici.vue";
import AnagraficaPaziente from "@/components/anagraficaPaziente.vue";

export default {
  components: {AnagraficaPaziente, DatiMedici},
  data() {
    return {
      etnie:[],
      paziente: {
        id: null,
        nome_paziente: '',
        cognome_paziente: '',
        data_nascita: '',
        codice_fiscale: '',
        mail: '',
        numero_telefono: '',
        gender: '',
        gruppo_pazienti_id:''
      },
      formData: {
        circonferenza_vita: '',
        rapporto_vita_fianchi: '',
        body_mass_index: '',
        pressione_arteriosa_sistolica: '',
        pressione_arteriosa_diastolica: '',
        frequenza_cardiaca: '',
        qlqC30: ''
      },
      quadro_proteico: {
        albumina: '',
        a1: '',
        a2: '',
        betaglobuline: '',
        gammaglobuline: '',
        glicemia: '',
        insulinemia: '',
        homa_score: '',
        colesterolo_totale: '',
        hdl: '',
        ldl: '',
        trigliceridi: '',
        hs_pcr: '',
        ves: '',
        tas: '',
        cortisolo: '',
        acth: '',
        acido_urico: '',
        omocisteina: '',
        emoglobina_glicata: '',
        lipoproteina_a: '',
        troponine: '',
        telomerasi: '',
        il1: '',
        il6: '',
        il8: '',
        il17: '',
        cxcl12: '',
        stress_ossidativo: '',
        perossidazione_lipidica: '',
        mda: '',
        hna4: '',
        leucotrieni: '',
        prostaglandine_e2: ''
      },
      dosaggioTotale: 100,
      maxDosaggio: 100
    }
  },
  mounted: async function () {
    await this.getPaziente()
    await this.getEtnie()
  },
  methods: {
    async getPaziente() {
      const response = await Patients.getPatientsList(this.$router.currentRoute.params.id)
      if (response.status === 200) {
        this.paziente = response.data
        if(this.paziente.gender === 'maschio'){
          this.paziente.gender = 'Uomo'
        } else if (this.paziente.gender === 'femmina' ){
          this.paziente.gender = 'Donna'
        }
      } else {
        this.$bvToast.toast("Errore nei dati del paziente, contattare l'assistenza", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    async getEtnie() {
      const response = await Patients.getEtnieList()
      if (response.status === 200) {
        this.etnie = response.data
      }
    },
    async save() {
      this.$bvToast.toast("La modifica è avvenuta con successo.", {
        title: `Successo`,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000,
        variant: 'success',
        solid: true,
        noCloseButton: true
      })
    },
    async indietro() {
      await this.$router.push('/pazienti')
    },
    async goToDashboard() {
      await this.$router.push(`/dashboard/${this.$router.currentRoute.params.id}`)
    },
  }
}
</script>

<style scoped>
.titoliPagina {
  color: #0C56A0;
  font-size: 23px;
  letter-spacing:1px;
  font-family: "Montserrat";
  font-weight: 700;
  margin-bottom: 0;
}
.linea-retta {
  width: 100%;
  position: relative;
  height: 1px;
  background-color: #E9E9E9;
  margin: 20px 0;
}
</style>
