<template>
  <div style="display: grid; grid-template-columns: auto 1fr auto; column-gap: 18px; overflow: hidden">
    <img @click="backHome" src="../assets/logoIntegro.png" style="height: 40px; margin: 7px 0 7px 15px" alt="logo">
    <div style="display: flex; justify-content: flex-end; background-color: #283F83; transform: skew(25deg);
        margin-right: -200px; border-radius: 0 0 0 25px; box-shadow: inset 10px -10px 25px 0 rgba(0,0,0,0.40)"></div>
    <div style="z-index: 100; display: flex; align-items: center">
      <b-button style="background-color: transparent; border-color: transparent; font-size: medium" @click="logOut">
        <b-icon icon="arrow-bar-right" style="float: right; margin-left: 10px; color: #D51160" font-scale="1.8"></b-icon>Log out</b-button>
    </div>
  </div>
</template>

<script>
import router from "@/router/router";

export default {
  methods: {
    router() {
      return router
    },
    async backHome(){
      await this.$router.push('/menu')
    } ,
    async logOut() {
      sessionStorage.clear()
      await this.$router.push('/')
    },
  }
}
</script>

<style scoped>

</style>
