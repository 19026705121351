<template>
  <div style="height: 100%; padding: 20px 50px 0 50px;">
    <b-row style="height: 20px; width: 100%; margin:0;">
      <b-button style="background-color: #D51160; border: none; width: 5%" @click="indietro"><b-icon icon="arrow-left" font-scale="1.5"></b-icon></b-button>
    </b-row>
    <b-card class="mt-15 lh">
          <b-row style="margin-left: 0; margin-right: 0; margin-bottom: 20px; justify-content: space-between; align-items: center">
            <p class="titoloM" style="margin-bottom: 0">Integratori</p>
            <b-button style="float: right" size="lg" @click="addIntegratore()">Componi Integratore</b-button>
          </b-row>
          <div>
            <b-table
                v-if="admin"
                sticky-header
                ref="table"
                id="tbl1"
                responsive
                stacked="lg"
                striped
                hover
                :items="integratori"
                :fields="fieldsSuper"
                :per-page="perPage"
                :current-page="currentPage"
                sort-icon-left
                head-variant="light"
                class="sa-b-table"
            >
              <template v-slot:cell(dettagli)="row">
                <b-col xs="2" sm="2" md="2" lg="2">
                  <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="getDettagli(row)"
                            data-toggle="tooltip" data-placement="bottom" title="Dettagli">
                    <b-icon icon="search" variant="outline-secondary"/>
                  </b-button>
                </b-col>
              </template>
              <template v-slot:cell(elimina)="row">
                <b-col xs="2" sm="2" md="2" lg="2">
                  <b-button size="sm" class="no-text mx-1" variant="outline-danger" @click="eliminaPaziente(row)"
                            data-toggle="tooltip" data-placement="bottom" title="Dettagli">
                    <b-icon icon="trash" variant="outline-danger"/>
                  </b-button>
                </b-col>
              </template>
            </b-table>
            <b-table
                v-else
                sticky-header
                ref="table"
                id="tbl1"
                responsive
                stacked="lg"
                striped
                hover
                :items="integratori"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                sort-icon-left
                head-variant="light"
                class="sa-b-table"
            >
              <template v-slot:cell(dettagli)="row">
                <b-col xs="2" sm="2" md="2" lg="2">
                  <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="getDettagli(row)"
                            data-toggle="tooltip" data-placement="bottom" title="Dettagli">
                    <b-icon icon="search" variant="outline-secondary"/>
                  </b-button>
                </b-col>
              </template>
              <template v-slot:cell(elimina)="row">
                <b-col xs="2" sm="2" md="2" lg="2">
                  <b-button size="sm" class="no-text mx-1" variant="outline-danger" @click="eliminaPaziente(row)"
                            data-toggle="tooltip" data-placement="bottom" title="Dettagli">
                    <b-icon icon="trash" variant="outline-danger"/>
                  </b-button>
                </b-col>
              </template>
            </b-table>
            <template>
              <strong class="text-primary"> Sono presenti {{ rows }} Integratori</strong>
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right"
                            aria-controls="tbl1" size="sm"></b-pagination>
            </template>
          </div>
      </b-card>
  </div>
</template>

<script>
import Integrators from "@/services/Integrators";
import {DateTime} from "luxon";

export default {
  computed: {
    isAdmin: function (){
      return sessionStorage.getItem('isAdmin') === 'true'
    }
  },
  data() {
    return {
      perPage: 7,
      rows: 0,
      currentPage: 1,
      admin: false,
      integratori: [],
      fields: [
        {
          label: 'Nome',
          key: 'name',
          thStyle: "width: 18%"
        },
        {
          label: 'Descrizione',
          key: 'description',
          thStyle: "width: 70%"
        },
        {
          label: 'Data di Creazione',
          key: `creation_date`,
          thStyle: "width: 10%"
        },
        {
          label: 'Dettagli',
          key: "dettagli",
          thStyle: "width: 1%"
        },
        {
          label: 'Elimina',
          key: "elimina",
          thStyle: "width: 1%"
        }
      ],
      fieldsSuper: [
        {
          label: 'Nome',
          key: 'name',
          thStyle: "width: 18%"
        },
        {
          label: 'Descrizione',
          key: 'description',
          thStyle: "width: 60%"
        },
        {
          label: 'Medico',
          key: `nome_medico`,
          thStyle: "width: 10%"
        },
        {
          label: 'Data di Creazione',
          key: `creation_date`,
          thStyle: "width: 10%"
        },
        {
          label: 'Dettagli',
          key: "dettagli",
          thStyle: "width: 1%"
        },
        {
          label: 'Elimina',
          key: "elimina",
          thStyle: "width: 1%"
        }
      ]
    }
  },
  mounted: async function () {
    await this.getIntegratori()
    this.admin = sessionStorage.getItem('isAdmin')
  },
  methods: {
    async getIntegratori(){
      const response = await Integrators.getIntegratori()
      if(response.status === 200){
        this.integratori = response.data
        this.rows = response.data.length
        for(let i=0; i<this.integratori.length; i++){
          this.integratori[i].nome_medico += " " + this.integratori[i].cognome_medico
          this.integratori[i].creation_date = DateTime.fromSeconds(this.integratori[i].creation_date).toFormat('dd/MM/yyyy')
        }
      } else {
        this.$bvToast.toast(response.data, {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },

    async addIntegratore(){
      await this.$router.push('/componiintegratore')
    },
    async getDettagli(row) {
      await this.$router.push(`/integratore/${row.item.id}`)
    },
    async eliminaPaziente(row) {
      this.$bvModal.msgBoxConfirm("Sei sicuro di voler eliminare l'integratore?", {
        title: "Attenzione",
        centered:true,
        cancelTitle: "Annulla",
        okTitle: "Conferma"
      }).then(async (res) => {
        if(res) {
          const response = await Integrators.deleteIntegrator(row.item.id)
          if(response.status === 204){
            this.$bvToast.toast("L'eliminazione è avvenuta con successo.", {
              title: `Successo`,
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000,
              variant: 'success',
              solid: true,
              noCloseButton: true
            })
            await this.getIntegratori()
          } else {
            this.$bvToast.toast(response.data, {
              title: `Attenzione`,
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000,
              variant: 'danger',
              solid: true,
              noCloseButton: true
            })
          }
        }
      })
    },
    async indietro(){
      await this.$router.push('/menu')
    },
  }
}
</script>

<style scoped>

</style>
