<template>
  <div style="display: flex; align-items: center; justify-content: center;position: fixed; left: 0; top: 0; width: 100vw; height: 100vh; background-color: rgba(0,0,0,0.5)">
    <div style="position: relative; background-color: white; width:50%; height: 70%; border-radius: 5px; box-shadow: 0 0 30px -10px  rgba(0,0,0,0.5); padding: 20px; display: grid; grid-template-rows: auto 1fr">
      <h3 style="color: #000000; padding: 0">Calcola il fabbisogno energetico</h3>
      <div style="padding: 20px; display: grid; grid-template-rows: auto 1fr auto">
        <b-row>
          <b-col cols="12">
            <b-progress :max="steps.length" height="2rem" class="mb-3">
              <b-progress-bar
                  :value="currentStepIndex + 1"
                  :label="'Step ' + (currentStepIndex + 1) + ' di ' + steps.length"
                  variant="success"
              ></b-progress-bar>
            </b-progress>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div v-if="currentStepIndex === 0" style="margin-top: 10px;">
              <div>
                <label style="font-weight: 600; margin-bottom: 15px; font-size: 18px">Tipologia lavoro</label>
                <div style="display: grid; grid-template-columns: 1fr 1fr 1fr; column-gap: 16px; ">
                  <!--                  <b-button :class="['green-button', { greenSelected: selectedButton === 'leggero' }]" @click="selectButton('leggero')">Leggera</b-button>-->
                  <!--                  <b-button :class="['yellow-button', { yellowSelected: selectedButton === 'moderato' }]" @click="selectButton('moderato')">Moderata</b-button>-->
                  <!--                  <b-button :class="['red-button', { redSelected: selectedButton === 'intenso' }]" @click="selectButton('intenso')">Intensa</b-button>-->
                  <div class="card-activity" :class="fabbisogno.attivita === 'leggero' ? 'selected' : ''" @click="selectButton('leggero')" style="background-color: #196e2d">
                    <div>
                      <div v-if="fabbisogno.attivita === 'leggero'" style="position: absolute; right: 4px; top:4px;  font-size: 23px !important;">
                        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
                          <path d="M 0 0 L 40 0 L 40 40 Z" fill="#196e2d" stroke="none" />
                        </svg>
                        <b-icon-check style="position: absolute; color: white; top: 0; right: 0; "></b-icon-check>
                      </div>
                      Impiegati, personale amministrativo e dirigenziale, liberi professionisti, tecnici o simili
                    </div>
                    <h3> Leggera </h3>
                  </div>
                  <div class="card-activity" :class="fabbisogno.attivita === 'moderato' ? 'selected' : ''" @click="selectButton('moderato')"  style="background-color: #f5b804">
                    <div>
                      <div v-if="fabbisogno.attivita === 'moderato'" style="position: absolute; right: 4px; top:4px;  font-size: 23px !important;">
                        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
                          <path d="M 0 0 L 40 0 L 40 40 Z" fill="#f5b804" stroke="none" />
                        </svg>
                        <b-icon-check style="position: absolute; color: white; top: 0; right: 0; "></b-icon-check>
                      </div>
                      Casalinghe, collaboratori domestici, personale di vendita, lavoratori del terziario
                    </div>
                    <h3> Moderata </h3>
                  </div>
                  <div class="card-activity" :class="fabbisogno.attivita === 'intenso' ? 'selected' : ''" @click="selectButton('intenso')" style="background-color: #b41c2b">
                    <div>
                      <div v-if="fabbisogno.attivita === 'intenso'" style="position: absolute; right: 4px; top:4px;  font-size: 23px !important;">
                        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
                          <path d="M 0 0 L 40 0 L 40 40 Z" fill="#b41c2b" stroke="none" />
                        </svg>
                        <b-icon-check style="position: absolute; color: white; top: 0; right: 0; "></b-icon-check>
                      </div>
                      Lavoratori in agricoltura, allevamento, silvicoltura e pesca, manovali, operatori di produzione e di attrezzature di trasporto
                    </div>
                    <h3> Intensa </h3>
                  </div>
                </div>
                <label style="font-weight: 600; margin: 15px 0; font-size: 18px">Attività fisica auspicabile</label>
                <div style="display: grid; grid-template-columns: 1fr 1fr; column-gap: 16px; ">
                  <!--                  <b-button :class="['green-button', { greenSelected: selectedButton === 'leggero' }]" @click="selectButton('leggero')">Leggera</b-button>-->
                  <!--                  <b-button :class="['yellow-button', { yellowSelected: selectedButton === 'moderato' }]" @click="selectButton('moderato')">Moderata</b-button>-->
                  <!--                  <b-button :class="['red-button', { redSelected: selectedButton === 'intenso' }]" @click="selectButton('intenso')">Intensa</b-button>-->
                  <div class="button-activity" :class="fabbisogno.auspicabile ? 'selected' : ''" @click="fabbisogno.auspicabile=true">
                    <svg style="position: absolute; top: calc(50% - 14px); left: 10px" fill="#000000" height="25px" width="25px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>rabbit</title> <path d="M23.961 5.99c-0.333-0.007-0.681 0.013-1.030 0.065-4.414-1.996-5.841-4.914-7.812-4.914-1.705 0-1.62 3.149 4.884 6.521-0.012 0.015-0.023 0.030-0.035 0.046-0.496 0.68-6.986-3.097-8.985-3.093-2.692 0.006 1.257 6.368 7.689 5.369 0.61-0.095-0.302 0.909-0.227 1.48v-0c0.023 0.176 0.065 0.345 0.123 0.506-6.275-0.164-10.188 0.982-12.463 2.774-1.616-0.903-1.672-4.089-3.337-3.265-1.77 0.876-0.679 5.582 0.831 7.142-1.022 4.432 2.247 9.722 4.846 11.331h20.509c1.112-0.789 0.487-1.41 0-1.997-0.602-0.725-2.461-1.199-3.993-0.998-2.23-0.908 5.444-5.973 0.027-11.95 1.021 0.058 2.186-0.023 3.2-0.342 0.023 0.016 0.047 0.033 0.071 0.049l0.045-0.086c0.931-0.313 1.723-0.836 2.137-1.648 0.51-0.998-3.303-6.922-6.479-6.989zM24.957 10.973c-0.551 0-0.998-0.447-0.998-0.998s0.447-0.998 0.998-0.998c0.551 0 0.998 0.447 0.998 0.998s-0.447 0.998-0.998 0.998z"></path> </g></svg>
                    <h3 style=" color: #464646"> Si </h3>
                  </div>
                  <div class="button-activity" :class="fabbisogno.auspicabile == false ? 'selected' : ''" @click="fabbisogno.auspicabile=false">
                    <svg style="position: absolute; top: calc(50% - 11px); left: 10px" fill="#000000" height="30px" width="30px" id="_x31_" viewBox="0 0 256.00 256.00" transform="matrix(-1, 0, 0, 1, 0, 0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">
                      <path d="M185,29.7v0.2l15-12.6c-11.4-6.1-23.9-9.5-37.5-9.5s-26.2,3.4-37.1,9.3l15,12.6L185,29.7L185,29.7z M105.1,93.2 c-24.7,0-45,20.3-45,45v4.5l0,0c0.2,9.5,7.9,17,17.4,17s17.2-7.5,17.4-17l0,0v-4.5c0-5.7,4.5-10.1,10.1-10.1 c9.7,0,17.6-7.9,17.6-17.6S114.8,93.2,105.1,93.2z M221.9,92.8c-9.7,0-17.6,7.9-17.6,17.6v32.4l0,0c0.4,9.3,7.9,16.8,17.4,16.8 s17-7.5,17.4-16.8l0,0v-32.7C239.3,100.5,231.4,92.8,221.9,92.8z M252.7,101.9c0-4.3-1.4-8.5-3.4-12c-2.2,1.6-4.7,2.6-7.7,2.6 c4.1,4.9,6.5,11,6.5,17.6v5.5C251.3,111.8,252.7,107,252.7,101.9z M131.7,110.6c0,5.7-1.8,10.7-4.7,15c11.6,2.6,23.5,4.1,35.7,4.1 c11.4,0,22.1-1.2,32.7-3.4v-16c0-6.5,2.4-12.6,6.3-17h-76.5C129,98,131.7,103.9,131.7,110.6z M125,93.4L125,93.4c0.2,0,0-0.2,0-0.2 V93.4z M245.8,79.6c0-2.4-2-4.7-4.5-4.7h-0.6c-3.4-21.7-15.4-40.6-32.7-52.5l-17.6,14.8c9.7,11.4,16.4,24.1,20.3,37.9h-9.5 c-4.3-13-11.4-25.6-21.5-35.9h-32.9c-10.1,10.5-17,22.7-21.5,35.9h-9.5c3.9-13.4,10.3-26,19.9-36.9l-18.5-15.6 c-17,12.2-29.4,30.8-32.7,52.5h-1.2c-2.4,0-4.5,2-4.5,4.5c0,2.4,2,4.5,4.5,4.5h157.8C243.8,84.1,245.8,82,245.8,79.6z M81.6,89.5 c-4.9-0.6-8.9-4.7-8.9-9.9v-8.9c0-18.7-15-33.9-33.9-33.9c-18.7,0-33.9,15-33.9,33.9v8.9c0,4.9,4.1,8.9,8.9,8.9h24.5 c2.2,11.4,8.5,20.7,17.6,27.4C61.1,104.3,70.2,95.2,81.6,89.5z M24.2,64.4c-3,0-5.7-2.4-5.7-5.7c0-3,2.4-5.7,5.7-5.7 s5.7,2.4,5.7,5.7C29.8,61.9,27.4,64.4,24.2,64.4z"></path> </g></svg>
                    <h3 style=" color: #464646"> No </h3>
                  </div>
                </div>
                <label style="font-weight: 600; margin: 15px 0; font-size: 18px">Peso attuale</label>
                <b-input type="number" v-model="fabbisogno.peso"></b-input>
              </div>
            </div>
            <div v-if="currentStepIndex === 1">
              <div style="display: grid; grid-template-columns: 2fr 1fr 2fr 1fr; margin: 10px 0; ">
                <label style="font-weight: 600; font-size: 18px">Fabbisogno consigliato</label>
                <label>{{calorie}} kcal</label>
                <label style="font-weight: 600; font-size: 18px">Fabbisogno effettivo</label>
                <div style="display: flex; align-items: center; column-gap: 5px;">
                  <b-input style="text-align: right;" type="number" v-model="fabbisognoEffettivo"></b-input>
                  kcal
                </div>
              </div>
              <label style="font-weight: 600; font-size: 18px; margin: 10px 0">Aggiungi spuntini</label>
              <div style="display: grid; grid-template-columns: 1fr 1fr; column-gap: 16px; ">
                <div class="meal-activity selected" style="cursor: default;">
                  <div style="position: absolute; right: -1px; top:-1px; font-size: 23px !important;">
                    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
                      <path d="M 0 0 L 40 0 L 40 40 Z" fill="#28a745" stroke="none" />
                    </svg>
                    <b-icon-check style="position: absolute; color: white; top: 0; right: 0; "></b-icon-check>
                  </div>
                  <h3>Colazione</h3>
                  <h3>Pranzo</h3>
                  <h3>Cena</h3>
                </div>
                <div style="display: grid; grid-template-rows: 1fr 1fr; row-gap: 10px;">
                  <div class="meal-activity" :class="lista_pasti.includes('Spuntino Mattina') ? 'selected' : ''" @click="addMeal('Spuntino Mattina')">
                    <div v-if="lista_pasti.includes('Spuntino Mattina')" style="position: absolute; right: -1px; top:-1px; font-size: 23px !important;">
                      <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
                        <path d="M 0 0 L 40 0 L 40 40 Z" fill="#28a745" stroke="none" />
                      </svg>
                      <b-icon-check style="position: absolute; color: white; top: 0; right: 0; "></b-icon-check>
                    </div>
                    <h3>+ Spuntino Mattina </h3>
                  </div>
                  <div class="meal-activity" :class="lista_pasti.includes('Spuntino Pomeriggio') ? 'selected' : ''" @click="addMeal('Spuntino Pomeriggio')">
                    <div v-if="lista_pasti.includes('Spuntino Pomeriggio')" style="position: absolute; right: -1px; top:-1px; font-size: 23px !important;">
                      <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
                        <path d="M 0 0 L 40 0 L 40 40 Z" fill="#28a745" stroke="none" />
                      </svg>
                      <b-icon-check style="position: absolute; color: white; top: 0; right: 0; "></b-icon-check>
                    </div>
                    <h3>+ Spuntino Pomeriggio </h3>
                  </div>
                </div>
              </div>
<!--              <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr; column-gap: 16px; ">-->
<!--                <div class="meal-activity" :class="lista_pasti.includes('Colazione') ? 'selected' : ''" style="border: 5px solid #F7E4BA">-->
<!--                  <div v-if="lista_pasti.includes('Colazione')" style="position: absolute; right: -1px; top:-1px; font-size: 23px !important;">-->
<!--                    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <path d="M 0 0 L 40 0 L 40 40 Z" fill="#F7E4BA" stroke="none" />-->
<!--                    </svg>-->
<!--                    <b-icon-check style="position: absolute; color: white; top: 0; right: 0; "></b-icon-check>-->
<!--                  </div>-->
<!--                  <h3>Colazione</h3>-->
<!--                </div>-->
<!--                <div class="meal-activity" :class="lista_pasti.includes('Spuntino Mattina') ? 'selected' : ''" @click="addMeal('Spuntino Mattina')"  style="border: 5px solid #fcbfbf">-->
<!--                  <div v-if="lista_pasti.includes('Spuntino Mattina')" style="position: absolute; right: -1px; top:-1px; font-size: 23px !important;">-->
<!--                    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <path d="M 0 0 L 40 0 L 40 40 Z" fill="#fcbfbf" stroke="none" />-->
<!--                    </svg>-->
<!--                    <b-icon-check style="position: absolute; color: white; top: 0; right: 0; "></b-icon-check>-->
<!--                  </div>-->
<!--                  <h3> Spuntino Mattina </h3>-->
<!--                </div>-->
<!--                <div class="meal-activity" :class="lista_pasti.includes('Pranzo') ? 'selected' : ''" style="border: 5px solid #ceea99">-->
<!--                  <div v-if="lista_pasti.includes('Pranzo')" style="position: absolute; right: -1px; top:-1px; font-size: 23px !important;">-->
<!--                    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <path d="M 0 0 L 40 0 L 40 40 Z" fill="#ceea99" stroke="none" />-->
<!--                    </svg>-->
<!--                    <b-icon-check style="position: absolute; color: white; top: 0; right: 0; "></b-icon-check>-->
<!--                  </div>-->
<!--                  <h3> Pranzo </h3>-->
<!--                </div>-->
<!--                <div class="meal-activity" :class="lista_pasti.includes('Spuntino Pomeriggio') ? 'selected' : ''" @click="addMeal('Spuntino Pomeriggio')" style="border: 5px solid #eabca6">-->
<!--                  <div v-if="lista_pasti.includes('Spuntino Pomeriggio')" style="position: absolute; right: -1px; top:-1px; font-size: 23px !important;">-->
<!--                    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <path d="M 0 0 L 40 0 L 40 40 Z" fill="#eabca6" stroke="none" />-->
<!--                    </svg>-->
<!--                    <b-icon-check style="position: absolute; color: white; top: 0; right: 0; "></b-icon-check>-->
<!--                  </div>-->
<!--                  <h3> Spuntino Pomeriggio </h3>-->
<!--                </div>-->
<!--                <div class="meal-activity" :class="lista_pasti.includes('Cena') ? 'selected' : ''" style="border: 5px solid #b5daea">-->
<!--                  <div v-if="lista_pasti.includes('Cena')" style="position: absolute; right: -1px; top:-1px; font-size: 23px !important;">-->
<!--                    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <path d="M 0 0 L 40 0 L 40 40 Z" fill="#b5daea" stroke="none" />-->
<!--                    </svg>-->
<!--                    <b-icon-check style="position: absolute; color: white; top: 0; right: 0; "></b-icon-check>-->
<!--                  </div>-->
<!--                  <h3> Cena </h3>-->
<!--                </div>-->
<!--              </div>-->
<!--              {{lista_pasti}} {{currentStepIndex}}-->

            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div style="display: flex; justify-content: space-between; column-gap: 20px">
              <b-button style="margin-top: 0 !important;" block @click="prevStep">Annulla</b-button>
              <b-button :disabled="checkContinueDisabled()" style="margin-top: 0 !important;" variant="success" block @click="nextStep()">{{ currentStepIndex === 0 ? 'Calcola Fabbisogno' : 'Genera suggerimenti' }}</b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="isBusy" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(0,0,0,0.5); border-radius: 5px; display: flex; align-items: center; justify-content: center;">
        <div style="padding: 25px; background-color: white; border-radius: 100px; display: flex; align-items: center; justify-content: center;">
          <img style="width: 130px;" src="@/assets/loading_diet.gif" alt="">
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Patients from "@/services/Patients";
import Diete from "@/services/Diete";

export default {
  name: "generatoreDieta",
  props: ["paziente_id", "tipo_dieta"],
  emits: ["cancel", "generate"],
  data() {
    return {
      isBusy: false,
      fabbisognoEffettivo: null,
      paziente :{},
      fabbisogno: {
        id_paziente: this.paziente_id,
        peso: null,
        attivita: null,
        auspicabile: null,
        sesso:''
      },
      calorie: 0,
      currentStepIndex: 0,
      steps:[1,2],
      lista_pasti : ["Colazione", "Pranzo", "Cena"],
      richieste:[
        {
          nome:"Energia_calorie_kcal",
          periodo:"giorno",
          minimo:1200,
          massimo:1500
        }
      ]
    }
  },
  async mounted() {
    await this.getPaziente()
  },
  methods: {
    async getPaziente() {
      const response = await Patients.getPatientsList(this.paziente_id)
      if (response.status === 200) {
        this.paziente = response.data
        if(this.paziente.gender === 'maschio'){
          this.paziente.gender = 'M'
        } else if (this.paziente.gender === 'femmina' ){
          this.paziente.gender = 'F'
        }
      } else {
        this.$bvToast.toast("Errore nei dati del paziente, contattare l'assistenza", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },
    selectButton(button) {
      this.fabbisogno.attivita = button;
    },
    addMeal(pasto) {
      if(this.lista_pasti.includes(pasto)){
        const index = this.lista_pasti.indexOf(pasto)
        this.lista_pasti.splice(index, 1)
      } else {
        this.lista_pasti.push(pasto)
      }
    },
    async nextStep() {
      if (this.currentStepIndex <= this.steps.length - 1){
        if (this.currentStepIndex === 0) {
          this.fabbisogno.sesso = this.paziente.gender
          const response = await Diete.getFabbisogno(this.fabbisogno)
          if (response.status === 200) {
            this.calorie = response.data
            this.currentStepIndex++
          } else {
            this.$bvToast.toast("Errore, verificare la correttezza dei dati inseriti",{
              title: `Attenzione`,
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000,
              variant: 'danger',
              solid: true,
              noCloseButton: true
            })
          }
        } else if (this.currentStepIndex === 1) {
          await this.generaDieta()
        }
      }
    },
    prevStep() {
      if (this.currentStepIndex > 0 ) {
        this.currentStepIndex--
      } else {
        this.$emit("cancel")
      }
    },
    async generaDieta() {
      this.isBusy = true
      const fabb = this.fabbisognoEffettivo ? this.fabbisognoEffettivo : this.calorie

      const body = {
        tipo_dieta : this.tipo_dieta,
        lista_pasti: this.lista_pasti,
        sesso : this.paziente.gender,
        kg: Number(this.fabbisogno.peso),
        richieste:[
          {
            nome:"Energia_calorie_kcal",
            periodo:"giorno",
            minimo: +fabb - 100,
            massimo: +fabb + 100
          }
        ]
      }
      const response = await Diete.generaDieta(body)
      if (response.status === 200) {
        for(const giorno in response.data){
          if (!response.data[giorno]["spuntino mattina"]){
            response.data[giorno].spuntino_mattina=[]
          } else {
            response.data[giorno].spuntino_mattina=response.data[giorno]["spuntino mattina"]
            delete response.data[giorno]["spuntino mattina"]
          }
          if (!response.data[giorno]["spuntino pomeriggio"]){
            response.data[giorno].spuntino_pomeriggio=[]
          } else {
            response.data[giorno].spuntino_pomeriggio=response.data[giorno]["spuntino pomeriggio"]
            delete response.data[giorno]["spuntino pomeriggio"]

          }
        }
        this.$emit("generate", response.data)
      } else {
        this.$bvToast.toast("Impossibile generare i suggerimenti per i dati inseriti",{
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
      this.isBusy = false
    },
    checkContinueDisabled() {
      if (this.currentStepIndex === 0) {
        return !this.fabbisogno.attivita || this.fabbisogno.auspicabile === null || !this.fabbisogno.peso
      }
      return false
    }
  }
}
</script>


<style scoped>
.green-button {
  background-color: #28a745;
  color: white;
  border-color: white !important;
}

.green-button:hover {
  color: #fff;
  background-color: rgba(40, 167, 69, 0.60);
}

.green-button:focus {
  color: #fff;
  border-color: #196e2d !important;
  background-color: #196e2d !important;
}

.yellow-button {
  background-color: #f5b804;
  color: white;
  border-color: white !important;
}

.yellow-button:hover {
  color: #fff;
  background-color: rgb(245, 184, 4, 0.6);
}

.yellow-button:focus {
  color: #fff;
  border-color: #d59f01 !important;
  background-color: #d59f01 !important;
}

.red-button {
  background-color: #dc3545;
  color: white;
  border-color: white !important;
}

.red-button:hover {
  color: #fff;
  background-color: rgba(220, 53, 69, 0.60);
}

.red-button:focus {
  color: #fff;
  border-color: #b41c2b !important;
  background-color: #b41c2b !important;
}

.greenSelected {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  background-color: #196e2d !important;
}
.yellowSelected {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  background-color: #d59f01 !important;
}
.redSelected {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  background-color: #b41c2b !important;
}

.card-activity{
  border-radius: 8px;
  display: grid;
  grid-template-rows: 1fr auto;
  opacity: 0.7;
  transform: scale(0.95);
  transition: all 0.4s;
  cursor: pointer;
}
.card-activity:hover{
  opacity: 1;
  transform: scale(0.98);
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.3);
}
.card-activity>div {
  padding: 8px 15px;
  background-color: white;
  margin: 5px;
  border-radius: 4px;
}
.card-activity>h3 {
  padding: 10px;
  margin-bottom: 5px;
}
.card-activity.selected {
  opacity: 1;
  transform: scale(1);
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.3);
}
.button-activity{
  border-radius: 8px;
  opacity: 0.7;
  transform: scale(0.95);
  transition: all 0.4s;
  cursor: pointer;
  border: 4px solid lightgrey;
}
.button-activity>h3 {
  padding: 8px;
  margin-bottom: 0;
}
.button-activity.selected {
  opacity: 1;
  transform: scale(1);
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.3);
  border: 4px solid #28a745;
}
.button-activity:hover{
  opacity: 1;
  transform: scale(1);
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.3);
}
.meal-activity{
  border-radius: 8px;
  display: grid;
  grid-template-rows: 1fr auto;
  transform: scale(0.95);
  transition: all 0.4s;
  cursor: pointer;
  border: 5px solid lightgrey;
}
.meal-activity:hover{
  opacity: 1;
  transform: scale(1);
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.3);
}
.meal-activity>h3 {
  margin: 0;
  color:black !important;
  align-content: center;
  padding: 13px;
}
.meal-activity.selected {
  opacity: 1;
  transform: scale(1);
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.3);
  border: 5px solid #28a745;
}
</style>
