<template>
  <div style="padding: 0; height: 100%; display: grid; grid-template-rows: auto 1fr; margin: 0">
    <navbar></navbar>
    <div style="max-height: 100%; overflow-y: scroll">
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
    </div>

  </div>
</template>
<script>
import navbar from "./navbar.vue";
export default {
  components: { navbar },
};
</script>
