<template>
  <div>
    <b-row style="height: 20px; width: 100%">
      <b-button class="ml-19 mt-5" style="background-color: #D51160; border: none; width: 5%" @click="indietro"><b-icon icon="arrow-left" font-scale="1.5"></b-icon></b-button>
    </b-row>
    <b-card class="ml-15 mx-15 mt-10">
      <div class="mt-20 ml-10 lh">
        <p class="titoloM">AGGIUNGI FORMULATORE<br/></p>
      </div>
      <div class="ml-19 mt-10">
        <b-form-group
            id="labelNome"
            label-cols-sm="1"
            label-cols-lg="1"
            content-cols-lg="3"
            label="Nome: "
            label-for="nome"
        >
          <b-form-input id="nome" v-model="body.nome" :state="validateName" type="text" style="margin-right: 5px"></b-form-input>
          <b-form-invalid-feedback :state="validateName">
            Il nome deve essere composto da almeno due caratteri.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
            id="labelNome"
            label-cols-sm="1"
            label-cols-lg="1"
            content-cols-lg="3"
            label="Cognome: "
            label-for="cognome"
        >
          <b-form-input id="cognome" v-model="body.cognome" :state="validateSurname" type="text" style="margin-right: 5px"></b-form-input>
          <b-form-invalid-feedback :state="validateSurname">
            Il cognome deve essere composto da almeno due caratteri.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
            id="labelNome"
            label-cols-sm="1"
            label-cols-lg="1"
            content-cols-lg="3"
            label="Mail: "
            label-for="mail"
        >
          <b-form-input id="mail" v-model="body.email" :state="validateMail" type="text" style="margin-right: 5px"></b-form-input>
          <b-form-invalid-feedback :state="validateMail">
            La mail inserita non è valida.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
            id="labelNome"
            label-cols-sm="1"
            label-cols-lg="1"
            content-cols-lg="3"
            label="Password: "
            label-for="password"
        >
          <div v-if="!pwdShow" style="display: flex; align-items: center">
            <b-form-input id="password" v-model="body.password" :state="validatePwd" type="password" style="margin-right: 5px"></b-form-input>
            <b-icon  icon="eye-fill" @click="pwdShow=!pwdShow"></b-icon>
          </div>
          <div v-else style="display: flex; align-items: center">
            <b-form-input id="password" v-model="body.password" :state="validatePwd" type="text" style="margin-right: 5px"></b-form-input>
            <b-icon icon="eye-slash-fill" @click="pwdShow=!pwdShow"></b-icon>
          </div>
          <b-form-invalid-feedback :state="validatePwd">
            La password deve essere lunga almeno 8 caratteri e contenere almeno 1 maiuscola e 1 numero
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <b-button class="mt-5 ml-10 lh" @click="addMedico()" size="lg">Crea</b-button>
    </b-card>

  </div>
</template>

<script>
import Registration from "@/services/Registration";

export default {
  components: {},
  data() {
    return {
      pwdShow: false,
      body: {
        nome: null,
        cognome: null,
        email: null,
        password: null
      }
    }
  },
  computed: {
    validateName(){
      return this.body.nome === null ? null : this.body.nome.length >= 2
    },
    validateSurname(){
      return this.body.cognome === null ? null : this.body.cognome.length >= 2
    },
    validateMail(){
      const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
      return this.body.email === null ? null : regex.test(this.body.email);
    },
    validatePwd(){
      const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
      return this.body.password === null ? null : regex.test(this.body.password);
    }
  },
  mounted: function () {
  },
  methods: {
    async addMedico(){
      if(this.validateMail && this.validatePwd && this.validateSurname && this.validateName){
        const response = await Registration.addMedico(this.body)
        if(response.status === 200){
          this.$bvToast.toast(response.data, {
            title: `Operazione andata a buon fine`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'success',
            solid: true,
            noCloseButton: true
          })
          this.$router.push("/listaMedici")
        } else {
          this.$bvToast.toast(response.data, {
            title: `Attenzione`,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            noCloseButton: true
          })
        }
      }
    },
    async indietro(){
      await this.$router.push('/listaMedici')
    },
  },
};
</script>

<style scoped>

</style>
