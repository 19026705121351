<template>
  <div class="container-fluid d-flex justify-content-center align-items-center min-vh-100">
    <b-card class="card-login p-lg-5">
      <b-col sm class="mb-5 justify-content-center align-items-center">
        <img alt="Logo" src="../assets/logoIntegro.png" class="h-100px" />
      </b-col>
      <b-row sm class="mb-5 mt-5">
        <b-form-group class="w-100" label="Email">
          <b-form-input
              v-model="loginData.email"
              type="email"
              placeholder="Inserisci l'email">
          </b-form-input>
        </b-form-group>
      </b-row>
      <b-row sm class="mb-5 mt-5">
        <b-form-group class="w-100" label="Password">
          <b-form-input
              v-model="loginData.password"
              type="password"
              @keydown.enter="onAccedi"
              placeholder="Inserisci la password">
          </b-form-input>
        </b-form-group>
      </b-row>
      <b-row sm class="mb-5 text-center mt-5">
        <b-button size="lg" class="w-100 login-button mb-10" @click="onAccedi" > ACCEDI </b-button>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Login from "@/services/Login";
import axios from "axios";
import jwtDecode from "jwt-decode";
import router from "@/router/router";

export default {
  data(){
    return {
      loginData: {
        email: null,
        password: null,
      }
    }
  },
  created: function (){},
  methods: {
    async onAccedi(){
      // localStorage.clear()
      const response = await Login.login(this.loginData);
      if (response.status !== 200) {
        this.$bvToast.toast(response.data, {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      } else {
        sessionStorage.setItem('token', response.data.access_token);
        const test = jwtDecode(response.data.access_token)
        if (test.ruolo === 'admin') {
          sessionStorage.setItem('isAdmin', 'true')
          // await this.$router.push('/menu')
        } else {
          sessionStorage.removeItem('isAdmin')
          // await router.push("/integratori")
        }
        await this.$router.push('/menu')

      }
    }
  }
}
</script>

<style>
@import "../assets/custom/style.css";
</style>
