<template>
  <div>
    <template>
      <b-pagination v-model="currentPage" :total-rows="listaDiete.rows" :per-page="perPage" align="right"
                    aria-controls="tbl1" size="sm" style="margin-top: -55px; margin-bottom: 25px"></b-pagination>
    </template>
    <b-table
        sticky-header
        ref="table"
        id="tbl1"
        responsive
        stacked="lg"
        striped
        hover
        :items="listaDiete.values"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        sort-icon-left
        head-variant="light"
        class="sa-b-table"
    >
      <template v-slot:cell(dettagli)="row">
        <b-col xs="2" sm="2" md="2" lg="2">
          <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="getDettagli(row.item.id)"
                    data-toggle="tooltip" data-placement="bottom" title="Dettagli">
            <b-icon icon="search" variant="outline-secondary"/>
          </b-button>
        </b-col>
      </template>
      <template v-slot:cell(elimina)="row">
        <b-col xs="2" sm="2" md="2" lg="2">
          <b-button v-b-modal.my-modal size="sm" class="no-text mx-1" variant="outline-danger" @click="eliminaDieta(row.item.id)"
                    data-toggle="tooltip" data-placement="bottom" title="Dettagli">
            <b-icon icon="trash" variant="outline-danger"/>
          </b-button>
        </b-col>
      </template>
    </b-table>
  </div>
</template>

<script>
import Diete from "@/services/Diete";

export default {
  name: "storico",
  props:["listaDiete"],
  emits:["dieta_id", "reload"],
  data(){
    return {
      perPage: 4,
      currentPage: 1,
      fields: [
        {
          label: 'Tipo di Dieta',
          key: 'tipodieta',
          thStyle: "width: 25%"
        },
        {
          label: 'Data Inizio',
          key: 'data_inizio',
          thStyle: "width: 25%"
        },
        {
          label: 'Data Fine',
          key: `data_fine`,
          thStyle: "width: 25%"
        },
        {
          label: 'Data Inserimento',
          key: `create_date`,
          thStyle: "width: 25%"
        },
        {
          label: 'Dettagli',
          key: "dettagli",
          thStyle: "width: 1%"
        },
        {
          label: 'Elimina',
          key: "elimina",
          thStyle: "width: 1%"
        }
      ],

    }
  },
  methods: {
    getDettagli(id){
      this.$emit("dieta_id", id)
    },
    async eliminaDieta(id){
      this.$bvModal.msgBoxConfirm("Sei sicuro di voler eliminare la dieta?",
          {  okTitle:"Elimina", cancelTitle: "Annulla"})
          .then(async ()=>{
            const response = await Diete.eliminaDieta(id)
            if(response.status===200) {
              this.$bvToast.toast("Eliminazione effettuata.", {
                title: `Operazione riuscita`,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                variant: 'success',
                solid: true,
                noCloseButton: true
              })
              this.$emit("reload")
            } else {
              this.$bvToast.toast("Impossibile eliminare la dieta", {
                title: `Attenzione`,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                variant: 'danger',
                solid: true,
                noCloseButton: true
              })
            }
          })
    }

  }
}
</script>


<style scoped>

</style>
