<template>
  <div>
    <div class="mt-10 mb-15 mx-15">
      <b-row>
        <b-button class="w-30 ml-4 mb-15" style="float: right; background-color: #D51160; border: none" @click="indietro"><b-icon icon="arrow-left" font-scale="1.5"></b-icon></b-button>
      </b-row>
      <b-row>
        <b-col>
          <h4>Quadro generale: </h4>
          <b-table
              sticky-header
              ref="table"
              id="tbl"
              responsive
              striped
              hover
              bordered
              stacked="lg"
              :items="datiCategoria"
              :fields="fields"
              sort-icon-left
              head-variant="light"
          ></b-table>
        </b-col>
      </b-row>
    </div>
    <div class="mt-7 mx-15">
      <b-row>
        <b-col>
          <h4 class="mb-5" style="float: left">Nuova composizione:</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-card class="mb-15 mx-5" style="width: 100%">
          <h6>Seleziona le componenti da utilizzare (Suggerite: {{advices.numeroComponentiMinime}})</h6>
          <br>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="selectedComponent"
                :options="advices.componentiDaVisualizzare"
                stacked
                text-field="nomeLabel"
                value-field="id"
            >
            </b-form-checkbox-group>
          </b-form-group>
          <b-button style="float: right; background-color: #D51160; border: none" @click="avanti">Avanti</b-button>
        </b-card>
      </b-row>
    </div>
  </div>
</template>

<script>
import Categories from "@/services/Categories";
import Advices from "@/services/Advices";
import Combination from "@/services/Combination";

export default {
  data(){
    return{
      datiCategoria: [],
      fields: null,
      advices:{},
      selectedComponent: [],
    }
  },
  created: async function () {
    await this.getCategory()
    await this.getAdvice()
    if(this.$router.currentRoute.params.idCombination)
      await this.getCombinationById()
  },
  methods: {
    async getCategory(){
      const response = await Categories.getCategoryById(this.$router.currentRoute.params.id)
      if(response.status === 401 || response.status === 400){
        this.$bvToast.toast("Si è verificato un errore. Riprovare più tardi.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'error',
          solid: true,
          noCloseButton: true
        })
      } else {
        this.datiCategoria = []
        this.datiCategoria.push(response.data)
        if( this.datiCategoria[0].macro === "SO"){
          this.fields =[
            {
              label: 'Condizione clinica macroscopica',
              key: 'condition'
            },
            {
              label: 'Età',
              key: 'age'
            },
            {
              label: 'Basale',
              key: 'basal'
            }
          ]
        } else if(this.datiCategoria[0].macro === "DM") {
          this.fields = [
            {
              label: 'Condizione clinica macroscopica',
              key: 'condition'
            },
            {
              label: 'Età',
              key: 'age'
            },
            {
              label: 'Pressione minima',
              key: 'minPress'
            },
            {
              label: 'Glicemia',
              key: 'glycemia'
            },
            {
              label: 'Colesterolo',
              key: 'cholesterol'
            }
          ]
        } else if(this.datiCategoria[0].macro === "RD") {
          this.fields = [
            {
              label: 'Condizione clinica macroscopica',
              key: 'condition'
            },
            {
              label: 'Età',
              key: 'age'
            },
            {
              label: 'Metabolita',
              key: 'metabolite'
            }
          ]
        }
      }
    },
    async getAdvice(){
      const response = await Advices.getAdvice(this.$router.currentRoute.params.id)
      if(response.status === 401 || response.status === 400){
        this.$bvToast.toast("Si è verificato un errore. Riprovare più tardi.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'error',
          solid: true,
          noCloseButton: true
        })
      } else {
        this.advices = response.data
        for(let i=0; i<this.advices.componentiDaVisualizzare.length; i++){
          if(this.advices.componentiDaVisualizzare[i].consigliato){
            this.advices.componentiDaVisualizzare[i].nomeLabel ="*"+ this.advices.componentiDaVisualizzare[i].name +' - (Componente consigliato)'
          } else {
            this.advices.componentiDaVisualizzare[i].nomeLabel = this.advices.componentiDaVisualizzare[i].name
          }
        }
      }
    },
    async getCombinationById(){
      const response = await Combination.getCombinationById(this.$router.currentRoute.params.idCombination)
      if(response.status === 401 || response.status === 400){
        this.$bvToast.toast("Si è verificato un errore. Riprovare più tardi.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'error',
          solid: true,
          noCloseButton: true
        })
      } else {
        for(let i=0; i<this.advices.componentiDaVisualizzare.length; i++){
          for(let j=0; j<response.data.combinazione.length; j++){
            if(this.advices.componentiDaVisualizzare[i].name === response.data.combinazione[j].name){
              this.selectedComponent.push(this.advices.componentiDaVisualizzare[i].id)
            }
          }
        }
      }
    },
    async avanti(){
      if(this.selectedComponent.length > 0){
        if(this.$router.currentRoute.params.idCombination)
          await this.$router.push(`/dettaglio/addcomposizione/dosaggio/${this.$router.currentRoute.params.id}/${this.selectedComponent}/${this.$router.currentRoute.params.idCombination}`)
        else
          await this.$router.push(`/dettaglio/addcomposizione/dosaggio/${this.$router.currentRoute.params.id}/${this.selectedComponent}`)
      } else{
        this.$bvToast.toast("Numero di componenti non sufficiente.", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'error',
          solid: true,
          noCloseButton: true
        })
      }
    },
    async indietro(){
      await this.$router.push({path: `/dettaglio/${this.$router.currentRoute.params.id}`})
    }
  }

}
</script>

<style scoped>

</style>

