import BaseService from "./BaseService";

export default class Conditions extends BaseService {

    static async getConditionById(idPatologia){
        return await this.perform({
            url: '/getConditionsbyId',
            method: 'GET',
            params: {id_patologia: idPatologia}
        })
    }
}
