<template>
  <b-form>

    <b-row style="background-color: #E9E9E9BE; padding: 5px 0; margin-bottom: 10px">
      <b-col cols="9">
        <label class="titoliPagina">Valutazione sindrome metabolica</label>
        <label style="color: #0C56A0;
          font-size: 13px;
          letter-spacing:1px;
          margin-bottom: 0;"> (Opzionale)
        </label>
      </b-col>
      <b-col cols="3" v-if="!visible_toggle4 && !hasSM" style="display: flex; justify-content: flex-end; align-items: center;">
        <b-btn style="width: 40%; margin-top:0; margin-right: 15px" block @click="visible_toggle4 = !visible_toggle4">Inserisci</b-btn>
      </b-col>
    </b-row>
    <b-collapse id="collapse-4" class="mt-2" v-model="visible_toggle4">
      <sindrome-metabolica :id-paziente="this.idPaziente" @save="saveSM" @annulla="visible_toggle4=false"></sindrome-metabolica>
    </b-collapse>

    <b-row style="background-color: #E9E9E9BE; padding: 5px 0; margin-bottom: 10px" >
      <b-col cols="9">
        <label class="titoliPagina">Parametri fisici </label>
        <label style="color: #0C56A0;
          font-size: 13px;
          letter-spacing:1px;
          margin-bottom: 0;"> (Opzionale)
        </label>
      </b-col>
      <b-col cols="3" v-if="!visible_toggle1 && !hasPP" style="display: flex; justify-content: flex-end; align-items: center;">
        <b-btn style="width: 40%; margin-top:0; margin-right: 15px" block @click="visible_toggle1 = !visible_toggle1">Inserisci</b-btn>
      </b-col>
    </b-row>
    <b-collapse id="collapse-1" class="mt-2" v-model="visible_toggle1">
      <parametri-fisici :id-paziente="this.idPaziente" @save="savePP" @annulla="visible_toggle1=false"></parametri-fisici>
    </b-collapse>

    <b-row style="background-color: #E9E9E9BE; padding: 5px 0; margin-bottom: 10px">
      <b-col cols="9">
        <label class="titoliPagina">Quadro Proteico</label>
        <label style="color: #0C56A0;
          font-size: 13px;
          letter-spacing:1px;
          margin-bottom: 0;"> (Opzionale)
        </label>
      </b-col>
      <b-col cols="3" v-if="!visible_toggle2 && !hasQP" style="display: flex; justify-content: flex-end; align-items: center;">
        <b-btn style="width: 40%; margin-top:0; margin-right: 15px" block @click="visible_toggle2 = !visible_toggle2">Inserisci</b-btn>
      </b-col>
    </b-row>
    <b-collapse id="collapse-2" class="mt-2" v-model="visible_toggle2">
      <quadro-proteico :id-paziente="this.idPaziente" @save="saveQP" @annulla="visible_toggle2=false"></quadro-proteico>
    </b-collapse>

    <b-row style="background-color: #E9E9E9BE; padding: 5px 0; margin-bottom: 10px">
      <b-col cols="9">
        <label class="titoliPagina">Dati Ematici</label>
        <label style="color: #0C56A0;
          font-size: 13px;
          letter-spacing:1px;
          margin-bottom: 0;"> (Opzionale)
        </label>
      </b-col>
      <b-col cols="3" v-if="!visible_toggle3 && !hasEmo" style="display: flex; justify-content: flex-end; align-items: center;">
        <b-btn style="width: 40%; margin-top:0; margin-right: 15px" block @click="visible_toggle3 = !visible_toggle3">Inserisci</b-btn>
      </b-col>
    </b-row>
    <b-collapse id="collapse-1" class="mt-2" v-model="visible_toggle3">
      <emocromocitometrico :id-paziente="this.idPaziente" @save="saveEmo" @annulla="visible_toggle3=false"></emocromocitometrico>
    </b-collapse>

    <b-row style="background-color: #E9E9E9BE; padding: 5px 0; margin-bottom: 25px">
      <b-col cols="9">
        <label class="titoliPagina">Aspetti Fisiologici</label>
        <label style="color: #0C56A0;
          font-size: 13px;
          letter-spacing:1px;
          margin-bottom: 0;"> (Opzionale)
        </label>
      </b-col>
      <b-col cols="3" v-if="!visible_toggle5 && !hasAF" style="display: flex; justify-content: flex-end; align-items: center;">
        <b-btn style="width: 40%; margin-top:0; margin-right: 15px" block @click="visible_toggle5 = !visible_toggle5">Inserisci</b-btn>
      </b-col>
    </b-row>
    <b-collapse id="collapse-1" class="mt-2" v-model="visible_toggle5">
      <aspetti-fisiologici :id-paziente="this.idPaziente" @save="saveAF" @annulla="visible_toggle5=false"></aspetti-fisiologici>
    </b-collapse>

  </b-form>


</template>

<script>
import ParametriFisici from "@/components/parametriMedici/parametriFisici.vue";
import QuadroProteico from "@/components/parametriMedici/quadroProteico.vue";
import Emocromocitometrico from "@/components/parametriMedici/emocromocitometrico.vue";
import SindromeMetabolica from "@/components/parametriMedici/sindromeMetabolica.vue";
import AspettiFisiologici from "@/components/parametriMedici/aspettiFisiologici.vue";

export default {
  components: {AspettiFisiologici, SindromeMetabolica, Emocromocitometrico, QuadroProteico, ParametriFisici},
  props:['idPaziente'],
  mounted() {
    console.log(this.idPaziente)
  },
  data(){
    return {
      visible_toggle1:false,
      visible_toggle2:false,
      visible_toggle3:false,
      visible_toggle4:false,
      visible_toggle5:false,
      physicalParameters: {},
      emocromocitometrico: {},
      quadro_proteico: {},
      hasPP: false,
      hasSM:false,
      hasEmo:false,
      hasQP:false,
      hasAF: false
    }
  },
  methods:{
    async saveSM() {
        this.hasSM = true
        this.visible_toggle4 = false
    },
    async saveQP() {
        this.hasQP = true
        this.visible_toggle2 = false
    },
    async savePP() {
      this.hasPP = true
      this.visible_toggle1 = false
    },
    async saveEmo() {
      this.hasEmo = true
      this.visible_toggle3 = false
    },
    async saveAF() {
      this.hasAF = true
      this.visible_toggle5 = false
    },
  }
}
</script>


<style scoped>
.titoliPagina {
  color: #0C56A0;
  font-size: 23px;
  letter-spacing:1px;
  font-family: "Montserrat";
  font-weight: 700;
  margin-bottom: 0;
}
</style>
