<template>
  <div style="height: 100%; padding: 20px 50px 0 50px;">
    <b-row style="height: 20px; width: 100%; margin:0;">
      <b-button style="background-color: #D51160; border: none; width: 5%" @click="indietro"><b-icon icon="arrow-left" font-scale="1.5"></b-icon></b-button>
    </b-row>
    <b-card class="mt-15 lh">
      <b-row style="margin-left: 0; margin-right: 0; margin-bottom: 20px; justify-content: space-between; align-items: center">
        <p class="titoloM" style="margin-bottom: 0">Pazienti</p>
        <b-button style="float: right" size="lg" @click="addPaziente()">Arruola Paziente</b-button>
      </b-row>

      <div>
        <b-table
            sticky-header
            ref="table"
            id="tbl1"
            responsive
            stacked="lg"
            striped
            hover
            :items="pazienti"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            sort-icon-left
            head-variant="light"
            class="sa-b-table"
        >
          <template v-slot:cell(dashboard)="row">
            <b-col xs="2" sm="2" md="2" lg="2">
              <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="getDashboard(row)"
                        data-toggle="tooltip" data-placement="bottom" title="Dashboard">
                <b-icon icon="graph-up" variant="outline-primary"/>
              </b-button>
            </b-col>
          </template>
          <template v-slot:cell(dettagli)="row">
            <b-col xs="2" sm="2" md="2" lg="2">
              <b-button size="sm" class="no-text mx-1" variant="outline-secondary" @click="getDettagli(row)"
                        data-toggle="tooltip" data-placement="bottom" title="Dettagli">
                <b-icon icon="search" variant="outline-secondary"/>
              </b-button>
            </b-col>
          </template>
          <template v-slot:cell(elimina)="row">
            <b-col xs="2" sm="2" md="2" lg="2">
              <b-button size="sm" class="no-text mx-1" variant="outline-danger" @click="eliminaPaziente(row)"
                        data-toggle="tooltip" data-placement="bottom" title="Dettagli">
                <b-icon icon="trash" variant="outline-danger"/>
              </b-button>
            </b-col>
          </template>
        </b-table>
        <template>
          <strong class="text-primary"> Sono presenti {{ rows }} Pazienti</strong>
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right"
                        aria-controls="tbl1" size="sm"></b-pagination>
        </template>
      </div>
    </b-card>
  </div>
</template>

<script>
import Integrators from "@/services/Integrators";
import {DateTime} from "luxon";
import Patients from "@/services/Patients";

export default {
  computed: {
    isAdmin: function (){
      return sessionStorage.getItem('isAdmin') === 'true'
    }
  },
  data() {
    return {
      perPage: 7,
      rows: 0,
      currentPage: 1,
      admin: false,
      pazienti: [],
      fields: [
        {
          label: 'Codice Fiscale',
          key: 'codice_fiscale',
          thStyle: "width: 25%"
        },
        {
          label: 'Paziente',
          key: 'nome_paziente',
          thStyle: "width: 50%"
        },
        {
          label: 'Data di Creazione',
          key: `create_date`,
          thStyle: "width: 19%"
        },
        {
          label: 'Dashboard',
          key: "dashboard",
          thStyle: "width: 1%"
        },
        {
          label: 'Dettagli',
          key: "dettagli",
          thStyle: "width: 1%"
        },
        {
          label: 'Elimina',
          key: "elimina",
          thStyle: "width: 1%"
        }
      ],
      //   fieldsSuper: [
      //     {
      //       label: 'Nome',
      //       key: 'nome_paziente',
      //       thStyle: "width: 18%"
      //     },
      //     {
      //       label: 'Cognome',
      //       key: 'cognome_paziente',
      //       thStyle: "width: 18%"
      //     },
      //     {
      //       label: 'Descrizione',
      //       key: 'description',
      //       thStyle: "width: 60%"
      //     },
      //     {
      //       label: 'Data di Creazione',
      //       key: `create_date`,
      //       thStyle: "width: 10%"
      //     },
      //     {
      //       label: 'Dettagli',
      //       key: "dettagli",
      //       thStyle: "width: 1%"
      //     },
      //     {
      //       label: 'Elimina',
      //       key: "elimina",
      //       thStyle: "width: 1%"
      //     }
      //   ]
    }
  },
  mounted: async function () {
    await this.getPatients()
    this.admin = sessionStorage.getItem('isAdmin')
  },
  methods: {
    async getPatients(){
      const response = await Patients.getPatientsList()
      if(response.status === 200){
        this.pazienti = response.data
        this.rows = response.data.length
        for(let i=0; i<this.pazienti.length; i++){
          this.pazienti[i].nome_paziente += " " + this.pazienti[i].cognome_paziente
          // this.pazienti[i].create_date = DateTime.fromSeconds(this.pazienti[i].create_date).toFormat('dd/MM/yyyy')
        }
      } else {
        this.$bvToast.toast("Errore nei dati dei pazienti, contattare l'assistenza", {
          title: `Attenzione`,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          noCloseButton: true
        })
      }
    },

    async addPaziente(){
      await this.$router.push('/arruolamentoPaziente')
    },
    async getDettagli(row) {
      await this.$router.push(`/pazienti/${row.item.id}`)
    },
    async getDashboard(row) {
      await this.$router.push(`/dashboard/${row.item.id}`)
    },
    async eliminaPaziente(row) {
      this.$bvModal.msgBoxConfirm("Sei sicuro di voler eliminare il paziente?", {
        title: "Attenzione",
        cancelTitle: "Annulla",
        okTitle: "Conferma"
      }).then(async (res) => {
        if(res) {
          const response = await Patients.deletePatient(row.item.id)
          if(response.status === 200){
            this.$bvToast.toast("L'eliminazione è avvenuta con successo.", {
              title: `Successo`,
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000,
              variant: 'success',
              solid: true,
              noCloseButton: true
            })
            await this.getPatients()
          } else {
            this.$bvToast.toast("Impossibile eliminare il paziente, contattare l'assistenza", {
              title: `Attenzione`,
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000,
              variant: 'danger',
              solid: true,
              noCloseButton: true
            })
          }
        }
      })
    },
    async indietro(){
      await this.$router.push('/menu')
    },
  }
}
</script>

<style scoped>

</style>
