import BaseService from "./BaseService";

export default class Dashboard extends BaseService {

    static async getGraphData(id){
        return await this.perform({
            url: '/pazienti/getGraphData',
            method: 'get',
            params:{
                id : id
            }
        })
    }

    static async getCategoryById(id){
        return await this.perform({
            url: 'getCategory',
            method: 'post',
            body: {'_id': id}
        })
    }

    static async updateCategory(id, bool){
        return await this.perform({
            url:'/updateCategory',
            method: 'put',
            body: {
                "Filter": {
                    "_id": id
                },
                "DataToUpdate": {
                    "checked": bool
                }
            }
        })
    }
}
