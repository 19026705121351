import BaseService from "./BaseService";

export default class Diete extends BaseService {

    static async getDieteList(id) {
        return await this.perform({
            url: '/pazienti/dieta/paziente',
            method: 'GET',
            params: {
                id: id
            }
        })
    }

    static async setDieta(body) {
        return await this.perform({
            url: '/pazienti/dieta/set',
            method: 'POST',
            body: body
        })
    }

    static async setPastiDieta(body) {
        return await this.perform({
            url: 'pazienti/dieta/pasti',
            method: 'POST',
            body: body
        })
    }

    static async getTipoDieteList(){
        return await this.perform({
            url: '/pazienti/dieta/tipodieta',
            method: 'GET'
        })
    }

    static async getUnitaMisura(){
        return await this.perform({
            url: '/pazienti/dieta/dosealimento/unita_misura',
            method: 'GET'
        })
    }

    static async getPastiList(id){
        return await this.perform({
            url: '/pazienti/dieta/pasti/paziente',
            method: 'GET',
            params: {
                id: id
            }
        })
    }

    static async getPastiDieta(dieta_id){
        return await this.perform({
            url: '/pazienti/dieta/pasti',
            method: 'GET',
            params:{
                id: dieta_id
            }
        })
    }

    static async getFabbisogno(body){
        return await this.perform({
            url: '/pazienti/dieta/calorie',
            method: 'POST',
            body: body
        })
    }

    static async getAlimentiPasti(tipoDieta, pasto){
        return await this.perform({
            url: '/pazienti/dieta/alimenti-associati',
            method: 'POST',
            body: {
                tipo_dieta: tipoDieta,
                lista_pasti : [pasto]
            }
        })
    }

    static async generaDieta(body){
        return await this.perform({
            url: '/pazienti/dieta/genera',
            method: 'POST',
            body: body
        })
    }

    static async eliminaDieta(id){
        return await this.perform({
            url: '/pazienti/dieta/delete',
            method: 'DELETE',
            params: {
                id : id
            }
        })
    }

}
