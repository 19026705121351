import BaseService from "./BaseService";

export default class Categories extends BaseService {

    static async getCategories(){
        return await this.perform({
            url: '/getAllCategories/',
            method: 'post'
        })
    }

    static async getCategoryById(id){
        return await this.perform({
            url: 'getCategory',
            method: 'post',
            body: {'_id': id}
        })
    }

    static async updateCategory(id, bool){
        return await this.perform({
            url:'/updateCategory',
            method: 'put',
            body: {
                "Filter": {
                    "_id": id
                },
                "DataToUpdate": {
                    "checked": bool
                }
            }
        })
    }
}
