import BaseService from "./BaseService";

export default class Patologies extends BaseService {

    static async getPatologies(){
        return this.perform({
            url: '/getPatologies',
            method: 'GET'
        })
    }
}
